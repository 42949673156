import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useResponsive from '../hooks/useResponsive';
import { LoginForm } from '../sections/auth/login';
import GetCookie from '../utils/getCookie';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  // padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const navigate = useNavigate();
  const { currentCredentials, spaceLogo, currentSpaceLogo } = useSelector((item) => item.spaceReducer);

  useEffect(() => {
    const sessionToken = GetCookie('session-token');

    if (currentCredentials || sessionToken) {
      navigate('/');
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Login </title>
        <link rel="icon" href={spaceLogo || currentSpaceLogo || './assets/placeholder.svg'} />
      </Helmet>

      <StyledRoot>
        <Container maxWidth="sm">
          <StyledContent>
            <Typography variant="h3" gutterBottom sx={{ mb: 5 }}>
              Sign in
            </Typography>

            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
