import React, { useEffect, useState } from 'react';
import {
  Container,
  Card,
  Grid,
  Typography,
  Stack,
  Box,
  Avatar,
  Chip,
  Pagination,
  TextField,
  Autocomplete,
  Button,
  IconButton,
  Popover,
  MenuItem,
  CardContent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import Iconify from '../components/iconify';
import Loader from '../components/Loader/Loader';
import {
  useGetAllProfilesQuery,
  useGetInterestsQuery,
  useGetPackagesQuery,
  useGetSkillsQuery,
} from '../Slices/spaceApi';
import { ConfirmComponent } from '../utils/Alert';

function CommunitiesPage() {
  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);
  const [activePage, setActivePage] = useState(1);
  const [searchName, setsearchName] = useState('');
  const { data: interestData, isLoading: interestsLoading } = useGetInterestsQuery();
  const { data: skillsData, isLoading: skillsLoading } = useGetSkillsQuery();
  const navigate = useNavigate();

  const [popoverOpen, setPopoverOpen] = useState(null); // Initialize with null
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopoverOpen = (event, idx) => {
    setPopoverAnchorEl(event.currentTarget);
    setPopoverOpen(idx);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setPopoverOpen(null);
  };

  const [skills, setSkills] = useState(null);

  const [interests, setInterests] = useState(null);
  const [companyIdForEmployee, setcompanyIdForEmployee] = useState();

  const { data, isLoading, refetch } = useGetAllProfilesQuery({
    spaceId: spaceId || currentSpaceId,
    page: activePage,
    page_size: 30,
    name: searchName,
    skills,
    interests,
    companyIdForEmployee: companyIdForEmployee?.id,
  });

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const handleSearchName = (event) => {
    setsearchName(event.target.value);
  };

  useEffect(() => {
    refetch();
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
    refetch();
  }, [searchName, companyIdForEmployee, skills, interests]);

  const handleProfilePage = (item) => {
    navigate(`/profile/${item.id}`);
  };

  const [dailog, setDailog] = useState(false);

  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = packages?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  useEffect(() => {
    if (!checkOldUser && !pacakgeLoading) {
      setDailog(true);
      navigate('/user/account');
    } else {
      setDailog(false);
    }
  }, [checkOldUser, pacakgeLoading]);

  const handleTypographyClick = (item) => {
    // Ensure item.email is defined before constructing the link
    if (item?.email) {
      // Construct the Gmail compose link
      const gmailComposeLink = `https://mail.google.com/mail/u/0/?view=cm&fs=1&tf=1&source=mailto&to=${encodeURIComponent(
        item.email
      )}`;

      // Open the link in a new tab
      window.open(gmailComposeLink, '_blank');
    }
  };

  const handleTypographyPhoneClick = (item) => {
    if (item?.phoneNo) {
      const whatsappLink = `https://wa.me/${item.phoneNo}`;
      window.open(whatsappLink, '_blank');
    }
  };

  if (isLoading || pacakgeLoading || interestsLoading || skillsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Community Search | {name || currentName}</title>
      </Helmet>

      {/* <ConfirmComponent openCheck={dailog} /> */}

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Community Search
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <CardContent sx={{ gap: 3, display: 'flex', flexDirection: 'column' }}>
                <Typography variant="h5">Filters</Typography>
                <TextField
                  id="outlined-basic"
                  label="Name"
                  variant="outlined"
                  value={searchName}
                  onChange={handleSearchName}
                />

                {data?.users?.filter((item) => item.companyDetail !== null)?.length >= 1 && (
                  <>
                    <Autocomplete
                      value={companyIdForEmployee}
                      onChange={(event, newValue) => {
                        setcompanyIdForEmployee(newValue);
                      }}
                      id="autocomplete"
                      options={data?.users?.filter((item) => item.companyDetail !== null)}
                      getOptionLabel={(option) => option?.companyDetail?.name}
                      renderInput={(params) => <TextField {...params} variant="outlined" label="Company" />}
                    />
                  </>
                )}

                {interestData?.length >= 1 && (
                  <>
                    <Autocomplete
                      value={interests}
                      onChange={(event, newValue) => {
                        setInterests(newValue);
                      }}
                      id="autocomplete"
                      options={interestData}
                      getOptionLabel={(option) => option}
                      filterOptions={(options, { inputValue }) => {
                        const filteredOptions = options.filter((option) => option !== interests);

                        return filteredOptions.filter((option) =>
                          option.toLowerCase().includes(inputValue.toLowerCase())
                        );
                      }}
                      renderInput={(params) => <TextField {...params} variant="outlined" label="Interests" />}
                    />
                  </>
                )}

                {skillsData?.length >= 1 && (
                  <>
                    <Autocomplete
                      value={skills}
                      onChange={(event, newValue) => {
                        setSkills(newValue);
                      }}
                      id="autocomplete"
                      options={skillsData}
                      filterOptions={(options, { inputValue }) => {
                        const filteredOptions = options.filter((option) => option !== skills);

                        return filteredOptions.filter((option) =>
                          option.toLowerCase().includes(inputValue.toLowerCase())
                        );
                      }}
                      getOptionLabel={(option) => option}
                      renderInput={(params) => <TextField {...params} variant="outlined" label="Skills" />}
                    />
                  </>
                )}
              </CardContent>
            </Card>
          </Grid>

          {data?.users.length > 0 ? (
            data?.users.map((item, index) => (
              <Grid item xs={12} md={4} key={index}>
                <Card sx={{ padding: 3, height: '100%', display: 'flex', flexDirection: 'column' }}>
                  <Box display={'flex'} alignItems={'center'}>
                    <IconButton
                      color="secondary"
                      aria-label="more"
                      aria-controls={`popover-${index}`}
                      aria-haspopup="true"
                      onClick={(e) => handlePopoverOpen(e, index)}
                      sx={{
                        position: 'absolute',
                        top: '8px',
                        right: '8px',
                      }}
                    >
                      <Iconify icon={'eva:more-vertical-fill'} />
                    </IconButton>
                  </Box>
                  <Popover
                    id={`popover-${index}`}
                    open={popoverOpen === index}
                    anchorEl={popoverAnchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    PaperProps={{
                      sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                          px: 1,
                          typography: 'body2',
                          borderRadius: 0.75,
                        },
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleProfilePage(item)}>
                      <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                      View
                    </MenuItem>
                  </Popover>
                  <Box display={'flex'}>
                    <Box
                      sx={{
                        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        padding: '3px',
                        border: '1px dashed rgba(145, 158, 171, 0.2)',
                        borderRadius: '50%',
                        height: '48px',
                      }}
                    >
                      <Avatar alt={item.name} src={item.profileImage} />
                    </Box>
                    <Stack ml={2}>
                      <Typography fontWeight={'600'}>{item?.name}</Typography>
                      <Typography variant="body">{item?.title}</Typography>
                      {item?.companyDetail !== null && (
                        <Typography variant="body2">
                          <Chip label={item?.companyDetail?.name} />
                        </Typography>
                      )}
                      <Stack flexDirection={'row'} gap={1} mt={1}>
                        <Button
                          sx={{
                            minWidth: 'auto',
                            color: 'rgb(255, 86, 48)',
                            padding: '5px',
                            fontSize: '1.125rem',
                            borderRadius: '8px',
                            backgroundColor: 'rgba(255, 86, 48, 0.08)',
                            '&: hover': {
                              backgroundColor: 'rgba(255, 86, 48, 0.16)',
                            },
                          }}
                          onClick={() => handleTypographyPhoneClick(item)}
                        >
                          <Iconify icon="ic:baseline-whatsapp" sx={{ width: '18px', height: '18px' }} />
                        </Button>

                        <Button
                          sx={{
                            minWidth: 'auto',
                            color: 'rgb(253, 169, 45)',
                            padding: '5px',
                            fontSize: '1.125rem',
                            borderRadius: '8px',
                            backgroundColor: 'rgba(253, 169, 45, 0.08)',
                            '&: hover': {
                              backgroundColor: 'rgba(253, 169, 45, 0.16)',
                            },
                          }}
                          onClick={() => handleTypographyClick(item)}
                        >
                          <Iconify icon="fluent:mail-24-filled" sx={{ width: '18px', height: '18px' }} />
                        </Button>
                      </Stack>
                    </Stack>
                  </Box>
                </Card>
              </Grid>
            ))
          ) : (
            <Grid item xs={12} textAlign={'center'}>
              <Typography variant="h6" paragraph>
                There's no Communities Search
              </Typography>
            </Grid>
          )}

          {data?.users.length > 0 && (
            <Grid item xs={12}>
              <Pagination
                count={data?.totalPages}
                color="primary"
                page={activePage}
                onChange={handlePageChange}
                sx={{ '.MuiPagination-ul': { justifyContent: 'center' } }}
              />
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default CommunitiesPage;
