import { Box, Stack, Button, Drawer, Divider, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import { CustomAlert, DeleteComponent } from '../../utils/Alert';
import EnquiryForm from './EnquiryForm';
import { useDeleteBookingMutation, useUpdateBookingMutation } from '../../Slices/spaceApi';
import BookingSummary from './BookingSummary';
import { removeFieldsWithId } from '../../utils/removeIdFromFieldNames';
import { convertToTimestampUTC } from '../../utils/convertDateTimeToTimeStamp';
import { updateSlot } from '../../utils/parseDateTime';

export default function ShopFilterSidebar({
  openFilter,
  onCloseFilter,
  name,
  startDateTime,
  type: bookingType,
  meetingRoomCapacity,
  price,
  id,
  inclusiveTax,
  editStates,
  setEditStates,
  meetingRoomId,
  endDateTime,
  idx,
  spaceType,
}) {

  
  const {
    spaceId,
    currentSpaceId,
    userId,
    currentUserId,
    globalTaxRateObj,
    currentGlobalTaxRateObj,
    slotSize,
    currentSlotSize,
  } = useSelector((item) => item.spaceReducer);

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [loader, setLoader] = useState(false);

  const [openModal, setOpenModal] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [selectDelete, setSelectDelete] = useState(false);

  const [deleteBooking, resultStats] = useDeleteBookingMutation();
  const [updateBooking, resultStats1] = useUpdateBookingMutation();

  const cancelBooking = () => {
    setOpenCheck(true);
    setSelectDelete(true);
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoader(true);
    } else if (resultStats?.isSuccess) {
      setType(true);
      setOpenModal(false);
      onCloseFilter();
      setLoader(false);
      setOpen(true);
      setMessage('Booking canceled successfully.');
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);

      setMessage(errorMessage);
      setLoader(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    if (!openModal) {
      setOpenModal(true);
      return;
    }

    // const updatedData = removeFieldsWithId(data, id);

    const updatedData = removeFieldsWithId(data, id);

    const endTime = updatedData?.endTime.format('HH:mm');

    // Assuming targetDate is defined elsewhere in your code
    const targetDate = updatedData?.startDate.format('YYYY-MM-DD');
    const combinedEndTime = dayjs(`${targetDate} ${endTime}`);

    // Combine targetDate and startTimeForDay
    // const combinedDateTime = dayjs(`${targetDate} ${formattedTime}`);

    // Get the new timestamp in seconds
    // const newTimestamp = combinedDateTime.unix();

    if (bookingType === 2) {
      updateBooking({
        bookingId: id,
        spaceId: spaceId || currentSpaceId,
        data: {
          startTime: convertToTimestampUTC(updatedData?.startDate.format('YYYY-MM-DD')),
          endTime: convertToTimestampUTC(updatedData?.startDate.format('YYYY-MM-DD')),
          capacity: meetingRoomCapacity,
          member: userId || currentUserId,
          type: 2,
        },
      });
    } else {
      updateBooking({
        bookingId: id,
        spaceId: spaceId || currentSpaceId,
        data: {
          // startTime: updatedData?.startTimestamp,
          // endTime: updatedData?.endTimestamp,
          startTime: updatedData?.startDate?.unix(),
          endTime: combinedEndTime?.unix(),
          capacity: meetingRoomCapacity,
          member: userId || currentUserId,
          meetingRoom: meetingRoomId,
        },
      });
    }
  };

  useEffect(() => {
    if (resultStats1?.isLoading) {
      setLoader(true);
    } else if (resultStats1?.isSuccess) {
      setType(true);
      setOpenModal(false);
      setLoader(false);
      setOpen(true);
      onCloseFilter();
      setMessage('Booking updated successfully.');
    } else if (resultStats1?.isError) {
      const errorMessage = Object?.values(resultStats1?.error?.data).find((error) => error.length > 0);
      setMessage(errorMessage);
      setLoader(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats1]);

  useEffect(() => {
    const formattedDate = dayjs.unix(startDateTime);

    if (bookingType === 2) {
      setValue(`startDate_${id}`, formattedDate); // Set initial start date
    } else {
      setValue(`startDate_${id}`, formattedDate); // Set initial start date
      setValue(`slot_${id}`, updateSlot(startDateTime, endDateTime, slotSize, currentSlotSize)); // Set initial start date
    }
  }, [openFilter]);

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: { xs: '98%', sm: '420px' }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Booking Details ({name})
          </Typography>
          <IconButton onClick={onCloseFilter} color="secondary">
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Stack spacing={1} sx={{ p: 1 }}>
            {!editStates[idx] ? (
              <>
                {price && bookingType !== 2 && (
                  <Typography variant="subtitle1">
                    Price: <Typography component={'span'}>{price}</Typography>
                  </Typography>
                )}

                <Typography variant="subtitle1">
                  {bookingType === 2 ? 'Hot Desk Capacity' : 'Meeting Room Capacity'}:
                  <Typography component={'span'}> {meetingRoomCapacity}</Typography>
                </Typography>
              </>
            ) : (
              <EnquiryForm
                errors={errors}
                register={register}
                trigger={trigger}
                watch={watch}
                setValue={setValue}
                isEdit
                control={control}
                uniqueId={id}
                meetingRoomId={meetingRoomId}
                type={bookingType}
                start={new Date(startDateTime * 1000)?.toISOString()}
                end={new Date(endDateTime * 1000)?.toISOString()}
              />
            )}
          </Stack>
        </Scrollbar>

        {!editStates[idx] ? (
          <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown }}>
            <Box sx={{ px: 3, mb: 2, mt: 1 }}>
              <LoadingButton loading={loader} variant="contained" onClick={cancelBooking} color="error" fullWidth>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Iconify icon="line-md:cancel" sx={{ mr: '5px' }} /> Cancel Booking
                </Stack>
              </LoadingButton>
            </Box>

            {bookingType !== 2 && (
              <Box>
                <Box sx={{ px: 3, mb: 2, mt: 1 }}>
                  <Button
                    fullWidth
                    onClick={() => {
                      const updatedEditStates = [...editStates];
                      updatedEditStates[idx] = true;
                      setEditStates(updatedEditStates);
                    }}
                    variant="contained"
                    startIcon={<Iconify icon="basil:edit-outline" />}
                  >
                    Edit Booking
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown }}>
            <Box sx={{ px: 3, mb: 2, mt: 1 }}>
              <Button
                fullWidth
                onClick={() => {
                  const updatedEditStates = [...editStates];
                  updatedEditStates[idx] = false;
                  setEditStates(updatedEditStates);
                }}
                variant="contained"
                color="error"
                startIcon={<Iconify icon="ep:back" />}
              >
                Back
              </Button>
            </Box>

            <Box sx={{ px: 3, mb: 1 }}>
              <Button
                variant="contained"
                onClick={handleSubmit(onSubmit)}
                disabled={type === 1 && (!watch(`slot_${id}`) || watch(`slot_${id}`)?.length <= 0)}
                fullWidth
              >
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} /> Update Booking
                </Stack>
              </Button>
            </Box>
          </Box>
        )}
      </Drawer>

      <BookingSummary
        open={openModal}
        loading={loader}
        handleClose={handleClose}
        onClick={handleSubmit(onSubmit)}
        capacity={meetingRoomCapacity}
        name={name}
        price={price}
        watch={watch}
        register={register}
        errors={errors}
        startDate={watch(`startDate_${id}`)}
        endDate={watch(`endTime_${id}`)}
        start={watch(`startTimestamp_${id}`)}
        end={watch(`endTimestamp_${id}`)}
        type={bookingType}
        roomId={meetingRoomId}
        trigger={trigger}
        uniqueId={id}
        setValue={setValue}
        globalTaxRateObj={globalTaxRateObj}
        currentGlobalTaxRateObj={currentGlobalTaxRateObj}
        inclusiveTax={inclusiveTax}
        spaceType={spaceType}
      />

      {selectDelete && (
        <DeleteComponent
          handleCloseCheck={handleCloseCheck}
          openCheck={openCheck}
          name={'booking'}
          deleted={() => deleteBooking({ bookingId: id, spaceId: spaceId || currentSpaceId })}
        />
      )}

      {<CustomAlert type={type ? 'success' : 'error'} message={message} open={open} setOpen={setOpen} />}
    </>
  );
}
