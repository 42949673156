import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
// @mui
import {
  Autocomplete,
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  IconButton,
  Link,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tabs,
  Typography,
  styled,
  useMediaQuery,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { downloadPDF } from '../utils/downloadPdf';
import { formatDateNotTime } from '../utils/displayDateTime';
import Scrollbar from '../components/scrollbar/Scrollbar';
import { useGetInvoicesGlobalQuery, useGetInvoicesQuery, useGetUserDetailsQuery } from '../Slices/spaceApi';
import Loader from '../components/Loader/Loader';

import Iconify from '../components/iconify/Iconify';
// ----------------------------------------------------------------------

export default function ProductsPage() {
  const location = useLocation();
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const { currentUserId, userId, name, currentName, spaceId, currentSpaceId } = useSelector(
    (item) => item.spaceReducer
  );

  const [invoiceId, setInvoiceId] = useState('');

  const { data: profileData, isLoading } = useGetUserDetailsQuery(userId || currentUserId);

  const [id, setId] = useState('');

  const navigate = useNavigate();

  const sxStyles = {
    left: '24px',
    bottom: '24px',
    zIndex: '10',
    paddingTop: '0px',
    position: 'absolute',
  };
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [statusFilter, setStatusFilter] = useState('');
  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const {
    data: invoiceData,
    isLoading: invoiceLoading,
    refetch,
    isFetching,
  } = useGetInvoicesQuery({
    spaceId: spaceId || currentSpaceId,
    status: statusFilter,
    activePage,
    page_size: 15,
  });

  const { data: invoiceDataGlobal, isLoading: invoiceLoadingGlobal } = useGetInvoicesGlobalQuery(
    {
      spaceId: spaceId || currentSpaceId,
    },
    {
      skip: !invoiceId,
    }
  );

  useEffect(() => {
    setActivePage(1);
    refetch();
  }, [statusFilter]);

  const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))({
    '& .MuiTabs-indicatorSpan': {},
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    '&.Mui-focusVisible': {},
  }));

  const data = profileData;

  useEffect(() => {
    // Parse the tabValue from the query parameters and set the initial value accordingly
    const searchParams = new URLSearchParams(location.search);
    const tabValue = searchParams.get('tabValue');
    const checkId = searchParams.get('assignPackageId');

    if (tabValue) {
      setValue(parseInt(tabValue, 10));
    }

    if (checkId) {
      setInvoiceId(checkId);
    }
  }, [location.search]);

  useEffect(() => {
    const check = invoiceDataGlobal?.invoices?.find((item) => item.assignedPackage?.id === parseInt(invoiceId, 10));

    if (check) {
      navigate(`/invoice/${check.id}`);
    }
  }, [invoiceId, invoiceDataGlobal]);

  useEffect(() => {
    const queryString = window.location.search;

    const queryParams = new URLSearchParams(queryString);

    const userId = queryParams.get('id');
    setId(userId);
  }, []);

  if (isLoading || invoiceLoading || invoiceLoadingGlobal) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> Profile | {name || currentName}</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Profile
        </Typography>

        <Box
          sx={{
            backgroundColor: 'rgb(255, 255, 255)',
            color: 'rgb(33, 43, 54)',
            transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            backgroundImage: 'none',
            overflow: 'hidden',
            position: 'relative',
            boxShadow: (theme) => theme.customShadows.card,
            borderRadius: '16px',
            zIndex: '0',
            marginBottom: '24px',
            height: '290px',
          }}
        >
          <Box
            sx={{
              height: '100%',
              color: 'rgb(255, 255, 255)',
              background:
                'linear-gradient(rgba(0, 75, 80, 0.8), rgba(0, 75, 80, 0.8)) center center / cover no-repeat, url(/assets/images/covers/cover_25.jpg)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center center',
            }}
          >
            <Stack
              flexDirection={isMediumScreen ? 'row' : 'column'}
              alignItems={'center'}
              sx={isMediumScreen ? sxStyles : {}}
            >
              <Box pt={isMediumScreen ? '' : '48px'}>
                <Avatar
                  sx={{
                    width: isMediumScreen ? '124px' : '64px',
                    height: isMediumScreen ? '124px' : '64px',
                    mx: 'auto',
                  }}
                  src={data?.profileImg || '/assets/placeholder.svg'}
                  alt="photoURL"
                />
              </Box>
              <Box mt={isMediumScreen ? '' : 3} mx={'auto'} ml={isMediumScreen ? 2 : 'auto'} textAlign={'center'}>
                <Typography variant="h4">{data?.name}</Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ opacity: (theme) => theme.palette.action.disabledOpacity, fontSize: '0.875rem' }}
                  mt={0.5}
                >
                  {data?.title}
                </Typography>
              </Box>
            </Stack>
          </Box>

          <Card
            sx={{
              position: 'absolute',
              bottom: 0,
              borderTopLeftRadius: 0,
              borderTopRightRadius: 0,
              width: '100%',
              px: 3,
            }}
          >
            <Scrollbar>
              <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', pt: 0.5 }}>
                  <StyledTabs
                    value={value}
                    onChange={handleChange}
                    sx={{
                      '& .MuiTabs-indicator': {
                        backgroundColor: (theme) => theme.palette.text.primary,
                      },
                      '& .MuiTabs-flexContainer': {
                        justifyContent: isMediumScreen ? 'flex-end' : 'flex-start',
                      },
                      '&.Mui-focusVisible': {
                        backgroundColor: '',
                        outline: 'none',
                        boxShadow: 'none',
                      },
                    }}
                    aria-label="styled tabs example"
                  >
                    <StyledTab
                      sx={{
                        p: 0,
                        mr: 3,
                        '&.Mui-selected': {
                          color: (theme) => theme.palette.text.primary, // Active tab text color
                          backgroundColor: 'transparent',
                          fontWeight: 600,
                        },
                        fontWeight: 500,
                      }}
                      label={
                        <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                          <Iconify icon="solar:user-id-bold" sx={{ marginRight: '10px' }} />
                          Profile
                        </Typography>
                      }
                    />

                    <StyledTab
                      sx={{
                        p: 0,
                        mr: 3,
                        '&.Mui-selected': {
                          color: (theme) => theme.palette.text.primary, // Active tab text color
                          fontWeight: 600,
                        },
                        fontWeight: 500,
                      }}
                      label={
                        <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                          <Iconify icon="basil:invoice-solid" sx={{ marginRight: '10px' }} />
                          Invoices
                        </Typography>
                      }
                    />
                  </StyledTabs>
                </Box>
              </Box>
            </Scrollbar>
          </Card>
        </Box>

        {value === 0 && (
          <Scrollbar>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="About"
                    action={
                      !id && (
                        <IconButton color="secondary" onClick={() => navigate('/user/account')}>
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>
                      )
                    }
                  />
                  <Stack gap={3} p={3} sx={{ wordBreak: 'break-all' }}>
                    <Typography>{data?.description}</Typography>
                    <Typography alignItems={'center'} display={'flex'}>
                      <Iconify icon="fluent:location-12-filled" sx={{ mr: 2 }} />
                      Live at &nbsp;{' '}
                      <Typography
                        component={'span'}
                        sx={{ '&:hover': { textDecoration: 'underline' }, fontWeight: '600' }}
                      >
                        {' '}
                        {data?.address}
                      </Typography>
                    </Typography>

                    <Typography alignItems={'center'} display={'flex'}>
                      <Iconify icon="fluent:mail-24-filled" sx={{ mr: 2 }} />
                      {data?.email}
                    </Typography>
                    <Typography alignItems={'center'} display={'flex'}>
                      <Iconify icon="ic:baseline-phone" sx={{ mr: 2 }} />
                      Phone No. &nbsp;
                      <Typography
                        component={'span'}
                        sx={{ '&:hover': { textDecoration: 'underline' }, fontWeight: '600' }}
                      >
                        {data?.phoneNo}
                      </Typography>
                    </Typography>

                    {data?.skills?.length > 0 && (
                      <Stack>
                        <Typography variant="h6">Skills</Typography>
                        <Stack flexDirection={'row'} gap={1}>
                          {data?.skills.map((item, idx) => (
                            <Chip label={item} key={idx} />
                          ))}
                        </Stack>
                      </Stack>
                    )}

                    {data?.interests?.length > 0 && (
                      <Stack>
                        <Typography variant="h6">Interests</Typography>
                        <Stack flexDirection={'row'} gap={1} mt={0.5}>
                          {data?.interests.map((item, idx) => (
                            <Chip label={item} key={idx} />
                          ))}
                        </Stack>
                      </Stack>
                    )}
                  </Stack>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <CardHeader
                    title="Social"
                    action={
                      !id && (
                        <IconButton color="secondary" onClick={() => navigate('/user/account?tabValue=1')}>
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>
                      )
                    }
                  />
                  <Stack gap={3} p={3} sx={{ wordBreak: 'break-all' }}>
                    {data?.profileUrls?.map((item, index) => {
                      const socialMedia = Object.keys(item)[0];
                      const { url, public: isPublic } = item[socialMedia];

                      const iconColor =
                        socialMedia === 'Twitter'
                          ? '#1DA1F2'
                          : socialMedia === 'LinkedIn'
                          ? '#0A66C2'
                          : socialMedia === 'Facebook'
                          ? '#1877f2'
                          : socialMedia === 'Instagram'
                          ? '#e02d69'
                          : '#000'; // Default color

                      if (url) {
                        return (
                          <Link
                            color={'secondary'}
                            key={index}
                            component={'a'}
                            alignItems={'center'}
                            display={'flex'}
                            href={url.startsWith('http') ? url : `https://${url}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <Iconify icon={`bxl:${socialMedia.toLowerCase()}`} color={iconColor} sx={{ mr: 2 }} />
                            {url}
                          </Link>
                        );
                      }
                      return null; // or any other fallback UI for cases where URL is not available
                    })}
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Scrollbar>
        )}

        {value === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                  <Typography mr={1}>Filters</Typography>
                  <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} displayEmpty>
                    <MenuItem value="">All Status</MenuItem>
                    <MenuItem value="1">Paid</MenuItem>
                    <MenuItem value="0">Unpaid</MenuItem>
                  </Select>
                </Box>

                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Create</TableCell>
                      <TableCell>Due</TableCell>
                      <TableCell>Amount</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Download PDF</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {invoiceLoading ? (
                      <Typography p={2}>Loading..</Typography>
                    ) : invoiceData?.invoices?.length > 0 ? (
                      invoiceData?.invoices?.map((invoice) => (
                        <TableRow
                          key={invoice.id}
                          hover
                          onClick={() => navigate(`/invoice/${invoice.id}`)}
                          sx={{ cursor: 'pointer' }}
                        >
                          <TableCell>
                            <Stack>
                              <Box>{formatDateNotTime(invoice.issued_date)}</Box>
                              <Typography variant="subtitle2">
                                {dayjs(invoice.issued_date).format('hh:mm A')}
                              </Typography>
                            </Stack>
                          </TableCell>

                          <TableCell>
                            <Stack>
                              <Box>{formatDateNotTime(invoice.due_date)}</Box>
                              <Typography variant="subtitle2">{dayjs(invoice.due_date).format('hh:mm A')}</Typography>
                            </Stack>
                          </TableCell>

                          <TableCell>{invoice.total}</TableCell>

                          <TableCell>
                            <Chip
                              label={`${invoice.paid ? 'Paid' : 'Unpaid'}`}
                              color={invoice.paid ? 'success' : 'error'}
                            />
                          </TableCell>

                          <TableCell>
                            <Stack flexDirection={'row'} alignItems={'center'}>
                              <IconButton
                                color="secondary"
                                sx={{ mr: 0.5 }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  downloadPDF(invoice);
                                }}
                              >
                                <Iconify icon="mingcute:download-3-fill" />
                              </IconButton>
                              <Typography variant="body2" sx={{ cursor: 'pointer' }}>
                                {`INV—${formatDateNotTime(invoice.due_date)}`}
                              </Typography>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <Typography p={2}>No invoice available</Typography>
                    )}
                  </TableBody>
                  {invoiceData?.invoices?.length > 0 && !invoiceLoading && (
                    <TableFooter>
                      <TableCell colSpan={5}>
                        <Pagination
                          count={invoiceData?.totalPages}
                          color="primary"
                          page={activePage}
                          onChange={handlePageChange}
                          sx={{ '.MuiPagination-ul': { justifyContent: 'center', width: '100%' } }}
                        />
                      </TableCell>
                    </TableFooter>
                  )}
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        )}
      </Container>
    </>
  );
}
