import { Autocomplete, Box, Button, Container, Grid, Pagination, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { sortProductsByDuration } from '../utils/sortProducts';
import { useGetProductCategoryQuery } from '../Slices/constantApi';
import { ProductCard } from '../components/product/ProductCard';
import ProductSummary from '../components/product/ProductSummary';
import EnquiryProduct from '../components/product/EnquiryProduct';
import Loader from '../components/Loader/Loader';
import { useGetMeetingRoomsQuery, useGetProductsQuery, useUseGetDurationQuery } from '../Slices/spaceApi';

const ProductsPage = () => {
  const {
    spaceId,
    currentSpaceId,
    currency,
    currentCurrency,
    name,
    currentName,
    globalTaxRateObj,
    currentGlobalTaxRateObj,
  } = useSelector((item) => item.spaceReducer);
  const location = useLocation();

  const { data: meetingRooms, isLoading: meetingRoomsLoading } = useGetMeetingRoomsQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { data: getCategories, isLoading: categoriesLoading } = useGetProductCategoryQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { data: duration, isLoading: durationLoading } = useUseGetDurationQuery({ spaceId: spaceId || currentSpaceId });

  const [openFilter, setOpenFilter] = useState(false);
  const [selectProductEnquiry, setselectProductEnquiry] = useState();
  const [openModal, setOpenModal] = useState(false);
  const [checkoutProduct, setCheckoutProduct] = useState({ products: [], publicUser: {} });
  const [openSummaryModal, setOpenSummaryModal] = useState(false);

  const [activePage, setActivePage] = useState(1);
  const [selectedOtherType, setSelectedOtherType] = useState(null);

  // const newOtherTypes = getCategories?.categories.filter((item) => item.name !== 'Hot Desks');

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const handleSummaryClose = () => {
    setOpenSummaryModal(false);
  };

  const handleSummaryOpen = () => {
    setOpenSummaryModal(true);
  };

  const handleEnquiry = (item) => {
    const existingIndex = checkoutProduct?.products.findIndex((product) => product.product === item.id);

    if (existingIndex !== -1) {
      const updatedProducts = [...checkoutProduct.products];
      updatedProducts.splice(existingIndex, 1);
      setCheckoutProduct((prevCheckoutProduct) => ({
        ...prevCheckoutProduct,
        products: updatedProducts,
      }));
    } else {
      setOpenFilter(true);
      setselectProductEnquiry({ product: item });
    }
  };

  const [hotDesk, sethotDesk] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabValue = searchParams.get('hotDesk');
    if (tabValue) {
      sethotDesk(true);
      setSelectedOtherType(getCategories?.categories?.find((item) => item?.name === 'Hot Desks'));
    }
  }, [location.search, getCategories?.categories]);


  const { data, isLoading, isFetching } = useGetProductsQuery({
    spaceId: spaceId || currentSpaceId,
    activePage,
    page_size: 30,
    categoryType: selectedOtherType?.id,
  });

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  if (meetingRoomsLoading || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Products | {name || currentName}</title>
      </Helmet>

      <Container>
        <Stack flexDirection={'row'} sx={{ mb: 3 }} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h4">{hotDesk ? 'Packages' : 'Products'}</Typography>

          <Button variant="contained" onClick={handleSummaryOpen} disabled={checkoutProduct?.products?.length === 0}>
            Basket
          </Button>
        </Stack>

        {getCategories?.categories.length > 0 && (
          <Autocomplete
            options={getCategories?.categories}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            value={selectedOtherType}
            onChange={(event, newValue) => {
              if (newValue?.name === 'Hot Desks') {
                setSelectedOtherType(newValue);
                sethotDesk(true);
              } else {
                setSelectedOtherType(newValue);
                sethotDesk(false);
              }
            }}
            renderInput={(params) => <TextField {...params} label="Select Type" />}
          />
        )}

        <Grid container spacing={2} mt={5}>
          {sortProductsByDuration(data?.products, duration?.spaceDurations)?.length > 0 ? (
            sortProductsByDuration(data?.products, duration?.spaceDurations)?.map((item, idx) => (
              <ProductCard
                key={idx}
                item={item}
                handleEnquiry={handleEnquiry}
                duration={duration}
                currency={currency}
                currentCurrency={currentCurrency}
                checkoutProduct={checkoutProduct?.products}
                meetingRooms={meetingRooms}
                hotDesk={hotDesk}
                fromPublic={false}
              />
            ))
          ) : (
            <Grid item xs={12} textAlign={'center'}>
              <Typography variant="h6" paragraph>
                There's no {hotDesk ? 'package' : 'product'}
              </Typography>
            </Grid>
          )}

          {sortProductsByDuration(data?.products, duration?.spaceDurations)?.length > 0 && !isFetching && (
            <Box width={'100%'} mt={2}>
              <Pagination
                count={data?.totalPages}
                color="primary"
                page={activePage}
                onChange={handlePageChange}
                sx={{ '.MuiPagination-ul': { justifyContent: 'center', width: '100%' } }}
              />
            </Box>
          )}
        </Grid>

        {selectProductEnquiry && (
          <EnquiryProduct
            openFilter={openFilter}
            onCloseFilter={handleCloseFilter}
            {...selectProductEnquiry}
            currency={currency}
            currentCurrency={currentCurrency}
            openModal={openModal}
            setOpenModal={setOpenModal}
            spaceId={spaceId}
            currentSpaceId={currentSpaceId}
            setCheckoutProduct={setCheckoutProduct}
            checkoutProduct={checkoutProduct}
            categories={getCategories?.categories}
          />
        )}

        <ProductSummary
          products={checkoutProduct}
          open={openSummaryModal}
          handleClose={handleSummaryClose}
          currency={currency}
          currentCurrency={currentCurrency}
          quantity={false}
          setProducts={setCheckoutProduct}
          currentGlobalTaxRateObj={currentGlobalTaxRateObj}
          globalTaxRateObj={globalTaxRateObj}
          hotDesk={hotDesk}
        />
      </Container>
    </>
  );
};

export default ProductsPage;
