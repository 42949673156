import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Dialog,
  Divider,
  Drawer,
  IconButton,
  Link,
  Paper,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import SetCookie from '../../utils/setCookie';
import {
  BOOKINGCONFIGURATION,
  CREDITPRICEINCLUSIVETAX,
  CURRENCY,
  EMAIL,
  OPENDAYS,
  OPENDAYSTIME,
  SPACEBILLINGOPTIONS,
  SPACECREDS,
  SPACEID,
  SPACELOGO,
  SPACENAME,
  SPACEUSERID,
  STRIPE,
  TAXRATEOBJ,
  THEMECOLOR,
  USERTYPE,
} from '../../Slices/spaceSlice';
import { useSendOtpMutation } from '../../Slices/constantApi';
import Iconify from '../iconify';
import { useCheckHotDeskAvailibilityMutation, usePurchaseProductMutation } from '../../Slices/spaceApi';
import Scrollbar from '../scrollbar';
import EnquiryProductForm from './EnquiryProductForm';

const EnquiryProduct = ({
  hotDesk,
  openFilter,
  onCloseFilter,
  product,
  setCheckoutProduct,
  checkoutProduct,
  publicRoom,
  hotDeskDate,
  weekdaysData,
  duration,
  categories,
  currency,
  globalTaxRateObj,
  currentGlobalTaxRateObj,
  locationId,
  stripe,
}) => {
  const { userId, currentUserId, spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const navigate = useNavigate();

  const [purchaseProducts, resultStats1] = usePurchaseProductMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const Dispatch = useDispatch();

  const { publicEmail, currentPublicEmail } = useSelector((item) => item.spaceReducer);

  const [otp, setOTP] = useState('');
  const [verified, setVerified] = useState(false);
  const [otpError, setOtpError] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const inputRefs = useRef([]);

  const [resendCountdown, setResendCountdown] = useState(0);

  const handleInputChange = (index, value) => {
    const newOTP = otp.substr(0, index) + value + otp.substr(index + 1);
    setOTP(newOTP);

    // Move focus to the next input
    if (value && index < 5) {
      inputRefs.current[index + 1].focus();
    }
  };

  const [sendOtp, resultStats5] = useSendOtpMutation();

  const onSubmit = async (dataOrig, resend, verify) => {
    if (publicRoom && hotDesk) {
      // eslint-disable-next-line camelcase
      const { email, name, phone_number } = dataOrig;

      if (!dialogOpen) {
        setDialogOpen(true);

        if (publicEmail === email || currentPublicEmail === email) {
          setOTP('');
          setVerified(true);
          setOtpError(false);

          const startOfDay = dayjs(hotDeskDate).startOf('day');
          const endOfDay = dayjs(hotDeskDate).endOf('day');

          const data = {
            items: [
              {
                quantity: dataOrig.quantity,
                product: product.id,
                startDate: dayjs(dataOrig?.startDate).format('YYYY-MM-DD'),
              },
            ],
            member: +userId || +currentUserId,
            discountCode: dataOrig?.activeCode,
            ...(hotDesk && {
              booking: {
                capacity: 1,
                startTime: startOfDay.add(1, 'minute').unix(), // 12:01 am
                endTime: endOfDay.subtract(1, 'minute').unix(), // 11:59 pm
                member: +userId || +currentUserId,
                meetingRoom: null,
                type: 2,
              },
            }),
          };

          purchaseProducts({
            spaceId: locationId,
            data,
          });
        } else {
          setOTP('');
          setVerified(false);
          setOtpError(false);
          sendOtp({
            spaceId: locationId,
            data: {
              name,
              email,
              // eslint-disable-next-line camelcase
              phone_number,
            },
          });
        }
      } else if (resend) {
        setResendCountdown(59);

        sendOtp({
          spaceId: locationId,
          data: {
            name,
            email,
            // eslint-disable-next-line camelcase
            phone_number,
          },
        });
      } else if (verify) {
        try {
          const response = await axios.post(`/space/${locationId}/profile/verifyOtp/`, {
            otp,
            email,
          });

          const startOfDay = dayjs(hotDeskDate).startOf('day');
          const endOfDay = dayjs(hotDeskDate).endOf('day');

          const data = {
            items: [
              {
                quantity: dataOrig.quantity,
                product: product.id,
                startDate: dayjs(dataOrig?.startDate).format('YYYY-MM-DD'),
              },
            ],
            discountCode: dataOrig?.activeCode,
            member: +response?.data?.userId,
            ...(hotDesk && {
              booking: {
                capacity: 1,
                startTime: startOfDay.add(1, 'minute').unix(), // 12:01 am
                endTime: endOfDay.subtract(1, 'minute').unix(), // 11:59 pm
                member: +response?.data?.userId,
                meetingRoom: null,
                type: 2,
              },
            }),
          };

          SetCookie('token', response?.data?.['login-token'].token);
          SetCookie('userId', response?.data?.userId);
          SetCookie('spaceId', response?.data?.Spaces?.[0]);
          SetCookie('creds-member', response?.data?.creds);
          SetCookie('billing_option', response?.data?.billing_option);
          SetCookie('currency', response?.data?.currency);
          SetCookie('openDays', JSON.stringify(response?.data?.openDays));
          SetCookie('spaceLogo', response?.data?.imageUrls);
          SetCookie('spaceName', response?.data?.name);
          SetCookie('booking_configuration', response?.data?.booking_configuration);
          SetCookie('openDaysTime', JSON.stringify(response?.data?.openDaysTime));
          SetCookie('userType', response?.data?.userType);
          SetCookie('email', response?.data?.email);

          SetCookie(
            'taxRateObj',
            JSON.stringify(
              response?.data?.taxRateObj
                ? response?.data?.taxRateObj
                : JSON.stringify({
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  })
            )
          );

          SetCookie('stripe', response?.data?.stripe);
          Dispatch(STRIPE(response?.data?.stripe));

          SetCookie('creditPriceInclusiveTax', response?.data.creditPriceInclusiveTax || true);

          const brandColors = response?.data?.brandColors;

          SetCookie('themeColor', JSON.stringify(brandColors));

          Dispatch(THEMECOLOR(brandColors));

          Dispatch(CURRENCY(response?.data?.currency));
          Dispatch(EMAIL(response?.data?.email));

          Dispatch(SPACECREDS(response?.data?.creds));
          Dispatch(SPACEUSERID(response?.data?.userId));
          Dispatch(SPACEID(response?.data?.Spaces?.[0]));
          Dispatch(SPACEBILLINGOPTIONS(response?.data?.billing_option));
          Dispatch(OPENDAYS(response?.data?.openDays));
          Dispatch(SPACELOGO(response?.data?.imageUrls));
          Dispatch(SPACENAME(response?.data?.name));
          Dispatch(OPENDAYSTIME(response?.data?.openDaysTime));
          Dispatch(USERTYPE(response?.data?.userType));
          Dispatch(CREDITPRICEINCLUSIVETAX(response?.data.creditPriceInclusiveTax || true));
          Dispatch(BOOKINGCONFIGURATION(response?.data?.booking_configuration));

          Dispatch(
            TAXRATEOBJ(
              response?.data?.taxRateObj
                ? response?.data?.taxRateObj
                : {
                    setOverallTax: false,
                    rentalFee: 0,
                    serviceCharges: 0,
                  }
            )
          );

          setVerified(true);
          setOtpError(false);

          purchaseProducts({
            spaceId: locationId,
            data,
          });
        } catch (error) {
          setOTP('');
          setVerified(false);
          setOtpError(true);
        }
      }
    } else if (publicRoom) {
      setCheckoutProduct((prevCheckoutProduct) => ({
        ...prevCheckoutProduct,
        products: [
          ...prevCheckoutProduct.products,
          {
            product: product.id,
            quantity: +dataOrig?.[`quantity_${product.id}`] || 1,
            name: product.name,
            price: product.price,
            taxRateObj: product.taxRateObj,
            inclusiveTax: product.inclusiveTax,
            startDate: +dataOrig?.[`startDate_${product.id}`],
          },
        ],
        publicUser: {
          name: dataOrig?.name,
          email: dataOrig?.email,
          phone_number: dataOrig?.phone_number,
        },
      }));
    } else {
      const checkHotDesk =
        (checkoutProduct?.products?.some((product) =>
          categories?.some((category) => category.id === product.category && category.name === 'Hot Desks')
        ) &&
          checkoutProduct?.products?.some((item) => item?.benefits?.dayPass?.quantity > 0)) ||
        (product.id && categories?.some((item) => item.id === product?.category && item.name === 'Hot Desks'));

      if (checkHotDesk) {
        /* empty */
      } else {
        setValue('hotDesk', false);
      }

      setCheckoutProduct((prevCheckoutProduct) => ({
        ...prevCheckoutProduct,
        products: [
          ...prevCheckoutProduct.products,
          {
            product: product.id,
            quantity: +dataOrig?.[`quantity_${product.id}`] || 1,
            name: product.name,
            price: product.price,
            taxRateObj: product.taxRateObj,
            inclusiveTax: product.inclusiveTax,
            startDate: +dataOrig?.startDate,
            category: product?.category,
            benefits: {
              dayPass: { quantity: product?.benefits?.dayPass?.quantity || 0 },
            },
          },
        ],
        publicUser: {
          name: checkHotDesk ? dataOrig?.hotDesk : false,
          email: checkHotDesk ? dataOrig?.hotDesk : false,
          phone_number: checkHotDesk ? dataOrig?.hotDesk : false,
        },
      }));
    }

    onCloseFilter();
  };

  useEffect(() => {
    const checkHotDesk =
      checkoutProduct?.products?.some((product) =>
        categories?.some((category) => category.id === product.category && category.name === 'Hot Desks')
      ) && checkoutProduct?.products?.some((item) => item?.benefits?.dayPass?.quantity > 0);

    if (!checkHotDesk && !publicRoom) {
      setValue('hotDesk', false);
      setCheckoutProduct((prevCheckoutProduct) => ({
        ...prevCheckoutProduct,
        publicUser: {
          name: false,
          email: false,
          phone_number: false,
        },
      }));
    }
  }, [checkoutProduct?.products]);

  const [checkAvailability, resultStats] = useCheckHotDeskAvailibilityMutation();

  useEffect(() => {
    if (watch('hotDesk')) {
      const startOfDay = watch('startDate')?.startOf('day');
      const endOfDay = watch('startDate')?.endOf('day');

      checkAvailability({
        spaceId: spaceId || currentSpaceId,
        data: {
          startTime: startOfDay.add(1, 'minute').unix(), // 12:01 am
          endTime: endOfDay.subtract(1, 'minute').unix(), // 11:59 pm
          meetingRoom: null,
        },
      });
    }
  }, [watch('hotDesk'), watch('startDate')]);

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleLogin = () => {
    // Navigate to the "/forgotPsw" route
    navigate('/login');
  };

  const redirectPay = () => {
    if (resultStats1?.isSuccess && resultStats1?.data?.hostedInvoiceUrl) {
      window.location.href = resultStats1?.data?.hostedInvoiceUrl;
    }
  };

  useEffect(() => {
    let intervalId;
    if (resendCountdown > 0) {
      intervalId = setInterval(() => {
        setResendCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [resendCountdown]);

  useEffect(() => {
    if (resultStats1?.isSuccess && stripe) {
      redirectPay();
    }
  }, [resultStats1]);

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={!resultStats1?.isSuccess ? handleCloseDialog : undefined}
        maxWidth="xs"
        fullWidth
      >
        <Card component={Paper}>
          {!resultStats1?.isSuccess && (
            <CardHeader
              action={
                <IconButton color="secondary" aria-label="close" onClick={handleCloseDialog}>
                  <Iconify icon="eva:close-fill" />
                </IconButton>
              }
            />
          )}

          <CardContent>
            {resultStats5?.isLoading ? (
              <Typography variant="subtitle1" textAlign={'center'}>
                {'Loading'}
              </Typography>
            ) : resultStats5?.isError ? (
              <Typography variant="subtitle1" textAlign={'center'}>
                {resultStats5?.error?.data?.message}
                <br />
                <Link
                  color={'secondary'}
                  underline="hover"
                  component="button" // Use "button" instead of "routerLink"
                  onClick={handleLogin}
                >
                  Login
                </Link>
              </Typography>
            ) : (
              !verified && (
                <>
                  <Typography variant="subtitle1" textAlign={'center'} mb={2}>
                    Verification
                  </Typography>
                  {otpError && (
                    <Chip label={`Incorrect OTP please try again `} color="error" sx={{ mb: 2, width: '100%' }} />
                  )}

                  <Typography textAlign={'center'} mb={2}>
                    Code has sent to <br /> {watch(`email`)}
                  </Typography>
                  <Stack gap={2} flexDirection={'row'} justifyContent={'center'}>
                    {[...Array(6)].map((_, index) => (
                      <Box key={index}>
                        <TextField
                          variant="outlined"
                          type="number"
                          fullWidth
                          size="small"
                          inputProps={{ maxLength: 1, style: { textAlign: 'center' } }}
                          value={otp[index] || ''}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          // eslint-disable-next-line no-return-assign
                          inputRef={(ref) => (inputRefs.current[index] = ref)}
                        />
                      </Box>
                    ))}
                  </Stack>

                  <Button
                    variant="contained"
                    fullWidth
                    sx={{ mt: 2 }}
                    color="primary"
                    onClick={handleSubmit((data) => onSubmit(data, false, true))}
                  >
                    Verify
                  </Button>

                  {resendCountdown === 0 ? (
                    <Typography textAlign="center" mt={2}>
                      Have not received the verification code?{' '}
                      <Typography
                        color="primary"
                        fontWeight="bold"
                        sx={{ cursor: 'pointer' }}
                        onClick={handleSubmit((data) => onSubmit(data, true, false))}
                      >
                        Resend
                      </Typography>
                    </Typography>
                  ) : (
                    <Typography textAlign="center" mt={2} variant="body2">
                      We've sent you again now you can resend code in {resendCountdown} seconds
                    </Typography>
                  )}
                </>
              )
            )}

            {verified && (
              <>
                {verified && <Chip label={`Verified : ${watch(`email`)}`} color="success" />}
                <Typography mt={0.5}>
                  {resultStats1?.isLoading ? (
                    <Typography my={2}>Loading...</Typography>
                  ) : resultStats1?.isSuccess && !stripe ? (
                    <>
                      <Typography variant="h6" my={2}>
                        Your {hotDesk ? 'package' : 'purchases'} has been confirmed
                      </Typography>
                      {/* <Typography variant="h6" mt={2}>
                        Payment Options
                      </Typography>
                      <Typography mt={1}>
                        How would you like to pay? Choose from the options below to proceed with your payment.
                      </Typography> */}

                      <Stack
                        flexDirection={'row'}
                        justifyContent={'flex-end'}
                        alignItems={'center'}
                        mt={4}
                        gap={2}
                        flexWrap={'wrap'}
                      >
                        {stripe && (
                          <Button variant="contained" onClick={redirectPay}>
                            Pay now
                          </Button>
                        )}
                        <Button variant="contained" onClick={() => navigate('/bookingEnquiry')}>
                          Pay at space
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    resultStats1?.error && Object.values(resultStats1.error.data).find((error) => error.length > 0)
                  )}
                </Typography>
              </>
            )}
          </CardContent>
        </Card>
      </Dialog>

      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: {
            width: { xs: '98%', sm: '375px' },
            border: 'none',
            overflow: 'hidden',
            zIndex: hotDesk ? 2 : 'default',
          },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            {hotDesk ? product?.name : `Add to basket (${product?.name})`}
          </Typography>
          <IconButton onClick={onCloseFilter} color="secondary">
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />

        <Scrollbar>
          <Stack spacing={2} sx={{ p: 1 }}>
            <EnquiryProductForm
              errors={errors}
              register={register}
              trigger={trigger}
              watch={watch}
              control={control}
              setValue={setValue}
              product={product}
              publicRoom={publicRoom}
              hotDesk={hotDesk}
              hotDeskDate={hotDeskDate}
              weekdaysData={weekdaysData}
              duration={duration}
              products={checkoutProduct}
              categories={categories}
              currency={currency}
              currentGlobalTaxRateObj={currentGlobalTaxRateObj}
              globalTaxRateObj={globalTaxRateObj}
              locationId={locationId}
            />
          </Stack>
        </Scrollbar>

        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown }}>
          <Box sx={{ px: 3, my: 2 }}>
            <LoadingButton
              onClick={handleSubmit(onSubmit)}
              disabled={!product.oneOffPurchase || (resultStats?.isError && watch('hotDesk')) || resultStats?.isLoading}
              variant="contained"
              fullWidth
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                {resultStats?.isLoading
                  ? 'Loading...'
                  : resultStats?.isError && watch('hotDesk')
                  ? 'Hot Desk Not Available on this date'
                  : product.oneOffPurchase && hotDesk
                  ? 'Confirm'
                  : product.oneOffPurchase
                  ? 'Add to basket'
                  : 'Contact with admin'}
              </Stack>
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default EnquiryProduct;
