export function convertToTimestamp(dateTimeString) {
  const dateTime = new Date(dateTimeString);
  const timestampInSeconds = Math.floor(dateTime.getTime() / 1000); // Divide by 1000 to convert milliseconds to seconds
  return timestampInSeconds;
}

export function convertToTimestampUTC(dateString) {
  const dateTime = new Date(dateString);

  // Set the time to midnight in the local timezone (12:00 AM)
  dateTime.setHours(0, 0, 0, 0);

  // Get the timestamp in seconds since the Unix epoch (midnight local time)
  const timestampInSeconds = Math.floor(dateTime.getTime() / 1000);

  return timestampInSeconds;
}

// Function to get timestamps for startDate + start and startDate + end
export function getTimestamps(startDate, startTime, endTime) {
  // Combine startDate with startTime and endDate with endTime to create full date strings
  const startDateTimeString = `${startDate}T${startTime}`;
  let endDateTimeString = `${startDate}T${endTime}`;

  // Check if end time is before start time (indicating it's on the next day)
  if (new Date(`${startDate}T${endTime}`) < new Date(`${startDate}T${startTime}`)) {
    // Increment the date for the end time
    const nextDay = new Date(new Date(`${startDate}T${startTime}`).getTime() + 24 * 60 * 60 * 1000);
    endDateTimeString = `${nextDay.toISOString().split('T')[0]}T${endTime}`;
  }

  // Call your convertToTimestamp function to get timestamps
  const startTimestamp = convertToTimestamp(startDateTimeString);
  const endTimestamp = convertToTimestamp(endDateTimeString);

  return { startTimestamp, endTimestamp, startDateTimeString };
}
