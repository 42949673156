import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';

export default function LinearDeterminate({ packageProp }) {
  return (
    <LinearProgress
      color="primary"
      sx={{
        m: 'auto',
        top: packageProp ? '50%' : '',
        mt: packageProp ? '' : 'calc(50vh - 88px)',
        maxWidth: { xs: '70%', md: '50%' },
      }}
    />
  );
}
