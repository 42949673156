import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
// components
import { useCallback, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { removeFieldsWithId } from '../../utils/removeIdFromFieldNames';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import TicketForm from './TicketForm';
import { CustomAlert } from '../../utils/Alert';
import { useUpdateTicketMutation } from '../../Slices/spaceApi';
import { maxFileSize } from '../../utils/maxFileSize';

// eslint-disable-next-line camelcase
export default function ShopFilterSidebar({
  openFilter,
  onCloseFilter,
  purpose,
  description,
  category,
  // eslint-disable-next-line camelcase
  attach_photo,
  id,
  categories,
}) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageAdd, setMessageAdd] = useState('');
  const [hovered, setHovered] = useState(false);
  const [typeImage, setTypeImage] = useState('');
  const [openImage, setOpenImage] = useState(false);
  const [imageMessage, setImageMessage] = useState('');

  const [updateTicket, resultStats] = useUpdateTicketMutation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    const fieldId = `${id}`;

    // if (!(data[`file_${fieldId}`] instanceof File) && data[`attach_photo_${fieldId}`] === '') {
    //   return;
    // }

    delete data[`file_${fieldId}`];

    if (data[`attach_photo_${fieldId}`] && data[`attach_photo_${fieldId}`].startsWith('https')) {
      delete data[`attach_photo_${fieldId}`];
    }

    delete data[`mainCategory_${fieldId}`];

    // Replace the category object with just the id property
    if (data[`category_${fieldId}`]) {
      data[`category_${fieldId}`] = data[`category_${fieldId}`]?.id;
    }

    const newData = removeFieldsWithId(data, id);

    updateTicket({ spaceId: spaceId || currentSpaceId, data: newData, ticketId: id });
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setMessageAdd('Ticket updated successfully.');
      setType(true);
      setLoading(false);
      setOpen(true);
      onCloseFilter();
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setLoading(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  useEffect(() => {
    // Define an object with dynamic field names and corresponding values
    const fieldData = {
      [`purpose_${id}`]: purpose,
      [`description_${id}`]: description,
      // eslint-disable-next-line camelcase
      [`attach_photo_${id}`]: attach_photo,
      [`mainCategory_${id}`]: categories?.find((mainCategory) =>
        mainCategory.children.some((child) => child.id === category)
      ),
      [`category_${id}`]: categories?.reduce((result, mainCategory) => {
        const matchingChild = mainCategory.children.find((child) => child.id === category);
        if (matchingChild) {
          result = matchingChild;
        }
        return result;
      }, null),
    };

    // Loop through the keys in fieldData and set values using setValue
    Object.keys(fieldData).forEach((fieldName) => {
      setValue(fieldName, fieldData[fieldName]);
    });
  }, [openFilter]);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setLoading(true);

      if (selectedFile.size > maxFileSize) {
        setImageMessage('File size exceeds the limit (3.1 MB).');
      } else {
        setValue(`file_${id}`, selectedFile);
      }
    }
  };

  useEffect(() => {
    const selectedFile = watch(`file_${id}`);

    if (selectedFile) {
      if (errors[`file_${id}`]) {
        setValue(`file_${id}`, ''); // Clear the file input
        setOpenImage(true);
        setLoading(false);
        setTypeImage(false);
      } else if (
        selectedFile.type &&
        (selectedFile.type.includes('image/jpeg') ||
          selectedFile.type.includes('image/jpg') ||
          selectedFile.type.includes('image/png') ||
          selectedFile.type.includes('image/webp'))
      ) {
        const fd = new FormData();
        fd.append('image', selectedFile); // Append the selected file to the FormData
        axios
          .post('/uploadImage/', fd)
          .then((resp) => {
            setImageMessage('Image uploaded sucessfully.');
            setLoading(false);
            setOpenImage(true);
            setTypeImage(true);
            setValue(`attach_photo_${id}`, resp.data?.[0]); // Update the 'profileImg' field value with id
          })
          .catch((err) => {
            setImageMessage('Failed to upload an image.');
            setLoading(false);
            setOpenImage(false);
            setTypeImage(false);
          });
      } else {
        setImageMessage('Invalid image format. Please upload a valid JPEG, JPG, PNG, or WebP file.');
        // Invalid file type
        setValue(`file_${id}`, ''); // Clear the file input
        setOpenImage(true);
        setLoading(false);
        setTypeImage(false);
      }
    }
  }, [watch(`file_${id}`)]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: { xs: '98%', sm: '420px' }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Update Ticket
          </Typography>
          <IconButton onClick={onCloseFilter} color='secondary'> 
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <TicketForm
            errors={errors}
            handleFileChange={handleFileChange}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            hovered={hovered}
            categories={categories}
            setValue={setValue}
            id={id}
            loading={loading}
            register={register}
            trigger={trigger}
            watch={watch}
            isEdit
          />
        </Scrollbar>

        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown, p: 2 }}>
          <Box>
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{ float: 'right' }}
              onClick={handleSubmit(onSubmit)}
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                Update ticket
              </Stack>
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>

      <CustomAlert
        type={typeImage ? 'success' : 'error'}
        message={imageMessage}
        open={openImage}
        autoHideDuration={2000}
        setOpen={setOpenImage}
      />

      {<CustomAlert type={type ? 'success' : 'error'} message={messageAdd} open={open} setOpen={setOpen} />}
    </>
  );
}
