import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
// components
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import { removeFieldsWithId } from '../../utils/removeIdFromFieldNames';
import { timestampToDateTime } from '../../utils/timeStamptoDateTime';
import { calculateDuration } from '../../utils/getDuration';
import { CustomAlert } from '../../utils/Alert';
import VisitForm from './VisitForm';
import { useUpdateVisitMutation } from '../../Slices/spaceApi';

export default function ShopFilterSidebar({
  openFilter,
  onCloseFilter,
  data,
  visitor,
  visitId,
  // eslint-disable-next-line camelcase
  expected_visit_datetime,
  // eslint-disable-next-line camelcase
  person_visiting,
  location,
  // eslint-disable-next-line camelcase
  purpose_of_visit,
  id,
}) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageAdd, setMessageAdd] = useState('');
  const [updateVisit, resultStats] = useUpdateVisitMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    const newData = removeFieldsWithId(data, id);
    // eslint-disable-next-line camelcase
    const { expected_visit_datetime, ...otherFields } = newData;

    // eslint-disable-next-line camelcase
    const originalDateString = expected_visit_datetime;

    const originalDate = new Date(originalDateString);

    const utcISOString = originalDate.toISOString();

    const convertedData = {
      ...otherFields,
      // eslint-disable-next-line camelcase
      expected_visit_datetime: utcISOString,
      visitor: newData.visitor.id,
    };

    updateVisit({ spaceId: spaceId || currentSpaceId, data: convertedData, visitId: id });
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setMessageAdd('Visit updated successfully.');
      setType(true);
      setLoading(false);
      setOpen(true);
      onCloseFilter();
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setLoading(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  useEffect(() => {
    // Define an object with dynamic field names and corresponding values
    const fieldData = {
      [`visitor_${id}`]: visitor,
      [`location_${id}`]: location,
      // eslint-disable-next-line camelcase
      [`expected_visit_datetime_${id}`]: dayjs(expected_visit_datetime),
      // eslint-disable-next-line camelcase
      [`purpose_of_visit_${id}`]: purpose_of_visit,
      // eslint-disable-next-line camelcase
      [`person_visiting_${id}`]: person_visiting,
    };

    // Loop through the keys in fieldData and set values using setValue
    Object.keys(fieldData).forEach((fieldName) => {
      setValue(fieldName, fieldData[fieldName]);
    });
  }, [id]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: { xs: '98%', sm: '420px' }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Update Visit
          </Typography>
          <IconButton onClick={onCloseFilter} color='secondary'>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <VisitForm
            errors={errors}
            loading={loading}
            data={data}
            trigger={trigger}
            register={register}
            setValue={setValue}
            control={control}
            watch={watch}
            isEdit
            id={id}
          />
        </Scrollbar>

        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown, p: 2 }}>
          <Box>
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{ float: 'right' }}
              onClick={handleSubmit(onSubmit)}
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                Update Visit
              </Stack>
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>

      {<CustomAlert type={type ? 'success' : 'error'} message={messageAdd} open={open} setOpen={setOpen} />}
    </>
  );
}
