// @mui
import {
  Box,
  Stack,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Grid,
  InputAdornment,
  TextField,
  ListItem,
  ListItemText,
  List,
  Popover,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
// components
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import { CustomAlert, DeleteComponent } from '../../utils/Alert';
import {
  useCreateTicketReplyMutation,
  useDeleteTikcetReplyMutation,
  useGetAllTicketRepliesQuery,
  useUpdateTikcetReplyMutation,
} from '../../Slices/spaceApi';
import { TimeAgo } from '../../utils/TimeAgoFunction';

// eslint-disable-next-line camelcase
export default function ShopFilterSidebar({ openFilter, onCloseFilter, id, creator }) {
  const { spaceId, currentSpaceId, userId, currentUserId } = useSelector((item) => item.spaceReducer);

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md')); // Checks if it's a medium or larger screen

  const { data, isLoading } = useGetAllTicketRepliesQuery({ spaceId: spaceId || currentSpaceId, ticketId: id });

  const [createReply, resultStats] = useCreateTicketReplyMutation();
  const [deleteReply, resultStats1] = useDeleteTikcetReplyMutation();
  const [updateReply, resultStats2] = useUpdateTikcetReplyMutation();

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [messageAdd, setMessageAdd] = useState('');
  const [selectedCommentReply, setSelectedCommentReply] = useState({ message: '' });

  const [anchorEl1, setAnchorEl1] = useState(null);
  const open1 = Boolean(anchorEl1);

  const [openCheck, setOpenCheck] = useState(false);
  const [selectDelete, setSelectDelete] = useState(false);

  const handleDeleteReply = (comment) => {
    setSelectDelete(comment);
    setOpenCheck(true);
    setSelectedCommentReply(null);
  };

  const [selectedCommentEdit, setSelectedCommentEdit] = useState('');

  const handleEditReply = (comment) => {
    setSelectedCommentEdit(comment);
    handlePopoverCommentClose();
  };

  const postReplyComment = () => {
    createReply({
      spaceId: spaceId || currentSpaceId,
      ticketId: id,
      data: {
        message: selectedCommentReply?.message,
      },
    });
  };

  const postReplyUpdate = (comment) => {
    updateReply({
      spaceId: spaceId || currentSpaceId,
      ticketId: comment.ticket,
      replyId: comment.id,
      data: {
        message: selectedCommentEdit?.message,
      },
    });
  };

  const handlePopoverReplyOpen = (event, commentId) => {
    setAnchorEl1(event.currentTarget); // Use event.currentTarget here
    setSelectedCommentReply(commentId); // Set the selected comment ID
  };

  const handlePopoverCommentClose = () => {
    setAnchorEl1(null);
    setSelectedCommentReply(null); // Clear the selected comment ID when closing the popover
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }
    if (resultStats?.isSuccess) {
      setSelectedCommentReply({ ...selectedCommentReply, message: '' });
    }
  }, [resultStats]);

  useEffect(() => {
    if (resultStats1?.isError) {
      const errorMessage = Object.values(resultStats1?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }
  }, [resultStats1]);

  useEffect(() => {
    if (resultStats2?.isError) {
      const errorMessage = Object.values(resultStats2?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }

    if (resultStats2?.isSuccess) {
      setSelectedCommentEdit({ ...selectedCommentEdit, message: '' });
    }
  }, [resultStats2]);

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: { xs: '98%', sm: '420px' }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Comments
          </Typography>
          <IconButton onClick={onCloseFilter} color='secondary'>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <Grid container spacing={2} p={1}>
            {data?.messages?.length > 0 ? (
              data?.messages?.map((comment, idx) => (
                <Grid item xs={12} key={comment.id} alignItems="center" justifyContent="space-between">
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      width: comment?.owner ? 'calc(100%  - 50px)' : '100%',
                      marginLeft: comment?.owner ? 'auto' : '',
                      bgcolor: comment?.owner ? 'lightgray' : 'rgba(145, 158, 171, 0.08)',
                      //   mb: ,
                      borderRadius: '12px',
                      overflow: 'hidden',
                    }}
                  >
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1" color="text.primary">
                          {comment?.owner?.name || creator?.name || 'You'}
                        </Typography>
                      }
                      secondary={
                        <>
                          <Typography variant="body2" color="text.primary" sx={{ width: { xs: '80%', sm: '90%' } }}>
                            {comment.message}
                          </Typography>
                          <Typography
                            component={'span'}
                            // ml={2}
                            variant="caption"
                            color="teal"
                            sx={{
                              mt: 0.5,
                            }}
                          >
                            <TimeAgo date={comment.created_at} />
                          </Typography>
                        </>
                      }
                    />
                    {(+userId || +currentUserId) === (+comment?.owner?.id || creator?.id) && (
                      <IconButton
                      color='secondary'
                        className={`additional-content`}
                        onClick={(e) => handlePopoverReplyOpen(e, comment.id)}
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          right: '15px',
                          transform: 'translateY(-50%)',
                        }}
                      >
                        <Iconify icon="ri:more-fill" />
                      </IconButton>
                    )}
                  </ListItem>
                  <Popover
                    open={open1 && selectedCommentReply === comment.id}
                    anchorEl={anchorEl1}
                    onClose={handlePopoverCommentClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    PaperProps={{
                      sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                          px: 1,
                          typography: 'body2',
                          borderRadius: 0.75,
                        },
                      },
                    }}
                  >
                    <List>
                      {(+userId || +currentUserId) === (+comment?.owner?.id || creator?.id) && (
                        <>
                          <MenuItem onClick={() => handleEditReply(comment)}>
                            <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                            Edit
                          </MenuItem>
                          <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteReply(comment)}>
                            <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                            Delete
                          </MenuItem>
                        </>
                      )}
                    </List>
                  </Popover>
                  {selectedCommentEdit?.id === comment.id && (
                    <Box sx={{ p: 1 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Write a comment..."
                        size="small"
                        value={selectedCommentEdit?.message}
                        onChange={(e) => setSelectedCommentEdit({ ...selectedCommentEdit, message: e.target.value })}
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            e.preventDefault();
                            postReplyUpdate(comment);
                          }
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                              color='secondary'
                                aria-label="upload image"
                                size="small"
                                onClick={() => postReplyUpdate(comment)}
                              >
                                <Iconify icon="line-md:confirm-circle" />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  )}
                </Grid>
              ))
            ) : (
              <Grid item xs={12} textAlign={'center'}>
                <Typography variant="h6" paragraph>
                  There's no Comments
                </Typography>

                <Typography variant="body2">No results found for comments</Typography>
              </Grid>
            )}
          </Grid>
        </Scrollbar>

        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown, p: 1 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Write a comment..."
            size="small"
            value={selectedCommentReply?.message}
            onChange={(e) => setSelectedCommentReply({ ...selectedCommentReply, message: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                postReplyComment();
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton aria-label="upload image" color='secondary' size="small" onClick={postReplyComment}>
                    <Iconify icon="line-md:confirm-circle" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Drawer>

      {selectDelete?.id && (
        <DeleteComponent
          handleCloseCheck={handleCloseCheck}
          openCheck={openCheck}
          name={'comment'}
          deleted={() =>
            deleteReply({ spaceId: spaceId || currentSpaceId, ticketId: selectDelete.ticket, replyId: selectDelete.id })
          }
        />
      )}

      {<CustomAlert type={type ? 'success' : 'error'} message={messageAdd} open={open} setOpen={setOpen} />}
    </>
  );
}
