import dayjs from 'dayjs';

// eslint-disable-next-line camelcase
export function formatDate(created_at) {
  const currentDate = dayjs();
  const postDate = dayjs(created_at);

  const isCurrentYear = currentDate.year() === postDate.year();

  if (isCurrentYear) {
    return postDate.format('MMM D, hh:mm A');
  }
  return postDate.format('MMM D YYYY, hh:mm A');
}

// eslint-disable-next-line camelcase
export function formatDateNotTime(created_at) {
  const currentDate = dayjs();
  const postDate = dayjs(created_at);

  const isCurrentYear = currentDate.year() === postDate.year();

  if (isCurrentYear) {
    return postDate.format('MMM D');
  }
  return postDate.format('MMM D YYYY');
}
