import { Box, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import axios from 'axios';
import { CustomAlert } from '../../utils/Alert';
import { maxFileSize } from '../../utils/maxFileSize';
import Iconify from '../iconify';

const EmployeeForm = ({
  control,
  trigger,
  errors,
  register,
  setVALUES,
  watch,
  setLoading,
  uniqueId,
  profileImg,
  cnicImage,
}) => {
  const [hovered, setHovered] = useState(false);
  const [cnicHovered, setCnicHovered] = useState(false);

  const [typeImage, setTypeImage] = useState('');
  const [openImage, setOpenImage] = useState(false);
  const [imageMessage, setImageMessage] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setLoading(true);

    if (selectedFile) {
      if (selectedFile.size > maxFileSize) {
        setImageMessage('File size exceeds the limit (3.1 MB).');
        setLoading(false);
        // Clear the file input
        setVALUES(`file_${uniqueId}`, '');
        setOpenImage(true);
        setTypeImage(false);
      } else {
        // Update the 'file' field value
        setVALUES(`file_${uniqueId}`, selectedFile);

        // Check file format and size
        if (
          selectedFile.type &&
          (selectedFile.type.includes('image/jpeg') ||
            selectedFile.type.includes('image/jpg') ||
            selectedFile.type.includes('image/png') ||
            selectedFile.type.includes('image/webp'))
        ) {
          const fd = new FormData();
          fd.append('image', selectedFile); // Append the selected file to the FormData
          console.warn(selectedFile, 'fd');
          axios
            .post('/uploadImage/', fd)
            .then((resp) => {
              setImageMessage('Profile image uploaded sucessfully.');
              setOpenImage(true);
              setTypeImage(true);
              setVALUES(`profileImg_${uniqueId}`, resp.data?.[0]); // Update the 'profileImg' field value
            })
            .catch((err) => {
              setImageMessage('Failed to upload a profile image.');
              setOpenImage(false);
              setTypeImage(false);
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          setImageMessage('Invalid image format. Please upload a valid JPEG, JPG, PNG, or WebP file.');
          setOpenImage(true);
          setTypeImage(false);
          setLoading(false);
        }
      }
    }
  };

  const handleCnicFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setLoading(true);
      if (selectedFile.size > maxFileSize) {
        setImageMessage('File size exceeds the limit (3.1 MB).');
        setLoading(false);
      } else if (
        selectedFile.type &&
        (selectedFile.type.includes('image/jpeg') ||
          selectedFile.type.includes('image/jpg') ||
          selectedFile.type.includes('image/png') ||
          selectedFile.type.includes('image/webp'))
      ) {
        const fd = new FormData();
        fd.append('image', selectedFile); // Append the selected file to the FormData
        console.warn(selectedFile, 'fd');
        axios
          .post('/uploadImage/', fd)
          .then((resp) => {
            setImageMessage('Cnic image uploaded sucessfully.');
            setLoading(false);
            setOpenImage(true);
            setTypeImage(true);
            setVALUES(`cnicImg_${uniqueId}`, resp.data?.[0]); // Update the 'file' field value
          })
          .catch((err) => {
            setImageMessage('Failed to upload a cnic image.');
            setLoading(false);
            setOpenImage(false);
            setTypeImage(false);
          });
      } else {
        setImageMessage('Invalid image format. Please upload a valid JPEG, JPG, PNG, or WebP file.');
        setLoading(false);
      }

      // Update the 'cnic-file' field value regardless of file format or size
      setVALUES(`cnic-file_${uniqueId}`, selectedFile);
    }
  };

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleCnicMouseEnter = () => {
    setCnicHovered(true);
  };

  const handleCnicMouseLeave = () => {
    setCnicHovered(false);
  };

  const openInput = () => {
    document.getElementById('input-photo').click(); // Trigger the file input when the image container is clicked
  };

  const openCnicInput = () => {
    document.getElementById('cnic-input-photo').click(); // Trigger the file input when the image container is clicked
  };

  return (
    <>
      <Box sx={{ display: 'flex ', alignItems: 'center' }}>
        <Box
          sx={{
            padding: '8px',
            border: '1px dashed rgba(145, 158, 171, 0.2)',
            borderRadius: '50%',
            overflow: 'hidden',
            width: '144px',
            height: '144px',
            cursor: 'pointer',
            textAlign: 'center',
            marginRight: 1.5,
          }}
        >
          <Box
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={openInput}
          >
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={
                watch(`file_${uniqueId}`) instanceof File
                  ? URL.createObjectURL(watch(`file_${uniqueId}`))
                  : profileImg || '/assets/placeholder.svg'
              }
              alt="Profile"
              onError={(e) => {
                e.target.src = '/assets/placeholder.svg'; // Set the placeholder on error
              }}
            />

            {(hovered || (watch(`file_${uniqueId}`) instanceof File ? false : !profileImg)) && (
              <>
                <Box
                  style={{
                    top: '0',
                    position: 'absolute',
                    left: '0',
                    backgroundColor: 'rgb(0 0 0 / 50%)',
                    color: 'white',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Box
                    component={'span'}
                    sx={{
                      top: '50%',
                      position: 'absolute',
                      left: '50%',
                      transform: 'translate(-50% , -50%)',
                    }}
                  >
                    <Iconify icon="bxs:camera-plus" />
                    <Typography component={'span'} sx={{ textWrap: 'nowrap' }} variant="caption">
                      Update photo
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box>
          <input
            type="file"
            accept=".jpeg, .jpg, .png , .webp"
            id="input-photo"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <Typography variant="subtitle1">Profile Image (optional)</Typography>
          <Typography variant="caption" mt={3} sx={{ color: (theme) => theme.palette.text.disabled }}>
            Allowed *.jpeg, *.jpg, *.png , *.webp
            <br /> max size of 3.1 MB
          </Typography>
        </Box>
      </Box>

      <Box>
        <TextField
          label="Name"
          variant="outlined"
          fullWidth
          {...register(`name_${uniqueId}`, {
            required: 'Name is required',
          })}
          error={!!errors?.[`name_${uniqueId}`]}
          helperText={errors?.[`name_${uniqueId}`]?.message}
          onBlur={() => trigger(`name_${uniqueId}`)}
        />
      </Box>

      <Box>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          {...register(`email_${uniqueId}`, {
            required: 'Email is required',
            pattern: {
              value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
              message: 'Invalid email address',
            },
          })}
          error={!!errors?.[`email_${uniqueId}`]}
          helperText={errors?.[`email_${uniqueId}`]?.message}
          onBlur={() => trigger(`email_${uniqueId}`)}
        />
      </Box>

      <Box>
        <Controller
          name={`phoneNo_${uniqueId}`}
          control={control}
          rules={{
            required: 'Phone Number is required',
            pattern: {
              value: /^[0-9]+$/,
              message: 'Please enter only numeric digits.',
            },
            minLength: {
              value: 11,
              message: 'Phone number must have at least 11 characters',
            },
          }}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Phone Number"
              {...field}
              error={Boolean(errors?.[`phoneNo_${uniqueId}`])}
              helperText={errors?.[`phoneNo_${uniqueId}`] ? errors?.[`phoneNo_${uniqueId}`].message : ''}
              onBlur={() => trigger(`phoneNo_${uniqueId}`)} // Trigger validation onBlur
            />
          )}
        />
      </Box>

      <Box>
        <TextField
          label="Address (optional)"
          variant="outlined"
          fullWidth
          {...register(`address_${uniqueId}`)}
          error={!!errors?.[`address_${uniqueId}`]}
          helperText={errors?.[`address_${uniqueId}`]?.message}
          onBlur={() => trigger(`address_${uniqueId}`)}
        />
      </Box>

      <Box>
        <TextField
          label="About (optional)"
          variant="outlined"
          fullWidth
          {...register(`description_${uniqueId}`)}
          error={!!errors?.[`description_${uniqueId}`]}
          helperText={errors?.[`description_${uniqueId}`]?.message}
          onBlur={() => trigger(`description_${uniqueId}`)}
          multiline
          rows={5}
        />
      </Box>

      <Box>
        <TextField
          label="Experience Years (optional)"
          variant="outlined"
          fullWidth
          type="number"
          {...register(`experienceYears_${uniqueId}`)}
          error={!!errors?.[`experienceYears_${uniqueId}`]}
          helperText={errors?.[`experienceYears_${uniqueId}`]?.message}
          onBlur={() => trigger(`experienceYears_${uniqueId}`)}
        />
      </Box>

      <Box>
        <TextField
          label="ID Card Number"
          variant="outlined"
          fullWidth
          type="number"
          {...register(`cnicNo_${uniqueId}`, {
            required: 'ID card number is mandatory',
            maxLength: {
              value: 11,
              message: "ID Card Number can't have more than 13 chracters",
            },
          })}
          error={!!errors?.[`cnicNo_${uniqueId}`]}
          helperText={errors?.[`cnicNo_${uniqueId}`]?.message}
          onBlur={() => trigger(`cnicNo_${uniqueId}`)}
        />
      </Box>

      <Box sx={{ display: 'flex ', alignItems: 'center' }}>
        <Box
          sx={{
            padding: '8px',
            border: '1px dashed rgba(145, 158, 171, 0.2)',
            borderRadius: '50%',
            overflow: 'hidden',
            width: '144px',
            height: '144px',
            cursor: 'pointer',
            textAlign: 'center',
            marginRight: 1.5,
          }}
        >
          <Box
            sx={{
              borderRadius: '50%',
              overflow: 'hidden',
              width: '100%',
              height: '100%',
              position: 'relative',
            }}
            onMouseEnter={handleCnicMouseEnter}
            onMouseLeave={handleCnicMouseLeave}
            onClick={openCnicInput}
          >
            <img
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              src={
                watch(`cnic-file_${uniqueId}`) instanceof File
                  ? URL.createObjectURL(watch(`cnic-file_${uniqueId}`))
                  : cnicImage || '/assets/placeholder.svg'
              }
              alt="Cnic"
              onError={(e) => {
                e.target.src = '/assets/placeholder.svg'; // Set the placeholder on error
              }}
            />

            {(cnicHovered || (watch(`cnic-file_${uniqueId}`) instanceof File ? false : !cnicImage)) && (
              <>
                <Box
                  style={{
                    top: '0',
                    position: 'absolute',
                    left: '0',
                    backgroundColor: 'rgb(0 0 0 / 50%)',
                    color: 'white',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <Box
                    component={'span'}
                    sx={{
                      top: '50%',
                      position: 'absolute',
                      left: '50%',
                      transform: 'translate(-50% , -50%)',
                    }}
                  >
                    <Iconify icon="bxs:camera-plus" />
                    <Typography component={'span'} sx={{ textWrap: 'nowrap' }} variant="caption">
                      Update photo
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </Box>

        <Box>
          <input
            type="file"
            accept=".jpeg, .jpg, .png , .webp"
            id="cnic-input-photo"
            style={{ display: 'none' }}
            onChange={handleCnicFileChange}
          />
          <Typography variant="subtitle1">CNIC Image (optional)</Typography>
          <Typography variant="caption" mt={3} sx={{ color: (theme) => theme.palette.text.disabled }}>
            Allowed *.jpeg, *.jpg, *.png , *.webp
            <br /> max size of 3.1 MB
          </Typography>
        </Box>
      </Box>

      <CustomAlert
        type={typeImage ? 'success' : 'error'}
        message={imageMessage}
        open={openImage}
        autoHideDuration={2000}
        setOpen={setOpenImage}
      />
    </>
  );
};

export default EmployeeForm;
