export const removeFieldsWithId = (formData, idToRemove) => {
  const newData = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const fieldName of Object.keys(formData)) {
    const parts = fieldName.split('_');
    const lastPart = parts[parts.length - 1];
    // eslint-disable-next-line eqeqeq
    if (lastPart == idToRemove) {
      const newFieldName = parts.slice(0, -1).join('_');
      newData[newFieldName] = formData[fieldName];
    } else {
      newData[fieldName] = formData[fieldName];
    }
  }
  return newData;
};
