import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Container, Card, Grid, Typography, TextField, Button, Stack, Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import { CustomAlert } from '../utils/Alert';
import { useCreateTicketMutation, useGetCategoriesQuery } from '../Slices/spaceApi';
import TicketForm from '../components/Tickets/TicketForm';
import { maxFileSize } from '../utils/maxFileSize';

function BookingEnquiryPage() {
  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);

  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    trigger,
    setValue,
  } = useForm();

  const navigate = useNavigate();
  const [createTicket, resultStats] = useCreateTicketMutation();
  const { data: categories, isLoading: categoriesLoading } = useGetCategoriesQuery();

  const [loading, setLoading] = useState(false);

  const [typeRegister, setTypeRegister] = useState('');
  const [messageRegister, setMessageRegister] = useState('');
  const [open, setOpen] = useState(false);

  const [hovered, setHovered] = useState(false);
  const [typeImage, setTypeImage] = useState('');
  const [openImage, setOpenImage] = useState(false);
  const [imageMessage, setImageMessage] = useState('');

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    const selectedFile = watch('file');
    if (selectedFile) {
      // Check file format and size
      if (errors.file) {
        setValue('file', ''); // Clear the file input
        setOpenImage(true);
        setLoading(false);
        setTypeImage(false);
      } else if (
        selectedFile.type &&
        (selectedFile.type.includes('image/jpeg') ||
          selectedFile.type.includes('image/jpg') ||
          selectedFile.type.includes('image/png') ||
          selectedFile.type.includes('image/webp'))
      ) {
        const fd = new FormData();
        fd.append('image', selectedFile); // Append the selected file to the FormData
        axios
          .post('/uploadImage/', fd)
          .then((resp) => {
            setImageMessage('Image uploaded sucessfully.');
            setLoading(false);
            setOpenImage(true);
            setTypeImage(true);
            setValue('attach_photo', resp.data?.[0]); // Update the 'file' field value
          })
          .catch((err) => {
            setImageMessage('Failed to upload an image.');
            setLoading(false);
            setOpenImage(false);
            setTypeImage(false);
          });
      } else {
        setImageMessage('Invalid image format. Please upload a valid JPEG, JPG, PNG, or WebP file.');
        // Invalid file type
        setValue('file', ''); // Clear the file input
        setOpenImage(true);
        setLoading(false);
        setTypeImage(false);
      }
    }
  }, [watch('file')]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setLoading(true);
      if (selectedFile.size > maxFileSize) {
        setImageMessage('File size exceeds the limit (3.1 MB).');
      } else {
        setValue(`file`, selectedFile);
      }
    }
  };

  const onSubmit = (data) => {
    // if (!(data?.file instanceof File)) {
    //   return;
    // }

    delete data.file;
    delete data.mainCategory;

    // Replace the category object with just the id property
    if (data.category) {
      data.category = data.category.id;
    }

    if (data.attach_photo && data.attach_photo.startsWith('https')) {
      delete data.attach_photo;
    }

    createTicket({ spaceId: spaceId || currentSpaceId, data });
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setTypeRegister(true);
      setLoading(false);
      setOpen(true);
      setTimeout(() => {
        navigate('/support/tickets');
      }, 1000);
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageRegister(errorMessage);
      setLoading(false);
      setOpen(true);
      setTypeRegister(false);
    }
  }, [resultStats]);

  if (categoriesLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Add Ticket | {name || currentName}</title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Add Ticket
        </Typography>

        <form onSubmit={handleSubmit(onSubmit)}>
          <TicketForm
            categories={categories}
            errors={errors}
            handleFileChange={handleFileChange}
            handleMouseEnter={handleMouseEnter}
            handleMouseLeave={handleMouseLeave}
            hovered={hovered}
            loading={loading}
            register={register}
            trigger={trigger}
            setValue={setValue}
            watch={watch}
          />
        </form>
      </Container>

      <CustomAlert
        type={typeImage ? 'success' : 'error'}
        message={imageMessage}
        open={openImage}
        autoHideDuration={2000}
        setOpen={setOpenImage}
      />

      <CustomAlert
        type={typeRegister ? 'success' : 'error'}
        message={messageRegister || 'Ticked created successfully.'}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
}

export default BookingEnquiryPage;
