import dayjs from 'dayjs';

export const getNextOpenDateTime = (openDaysTime, currentOpenDaysTime) => {
  const currentDate = dayjs();
  const weekdays =
    openDaysTime?.length > 0 ? openDaysTime : currentOpenDaysTime?.length > 0 ? JSON.parse(currentOpenDaysTime) : [];

  const nextOpenDay = weekdays
    ?.map((day) => (day.day === 7 ? 0 : day.day)) // Convert Sunday (7) to 0
    .filter((day) => day >= currentDate.day()) // Filter days greater than or equal to the current day
    .sort((a, b) => a - b)[0]; // Sort and get the first (smallest) day

  let nextOpenDateTime =
    nextOpenDay !== undefined
      ? currentDate
          .day(nextOpenDay)
          .hour(dayjs.unix(weekdays.find((day) => day.day === nextOpenDay).start_time).hour())
          .minute(dayjs.unix(weekdays.find((day) => day.day === nextOpenDay).start_time).minute())
      : weekdays.length > 0
      ? currentDate
          .add(1, 'week')
          .startOf('week')
          .day(weekdays[0].day)
          .hour(dayjs.unix(weekdays.find((day) => day.day === weekdays[0].day).start_time).hour())
          .minute(dayjs.unix(weekdays.find((day) => day.day === weekdays[0].day).start_time).minute())
      : null;

  if (nextOpenDateTime && nextOpenDateTime.isBefore(currentDate)) {
    nextOpenDateTime = currentDate.add(30, 'minutes');
  }

  return nextOpenDateTime;
};
