import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Pagination,
  Paper,
  Popover,
  Select,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { formatDate, formatDateNotTime } from '../utils/displayDateTime';
import Loader from '../components/Loader/Loader';
import {
  useDeleteTicketMutation,
  useGetCategoriesQuery,
  useGetClosedTicketsQuery,
  useGetTicketsQuery,
} from '../Slices/spaceApi';
import Iconify from '../components/iconify/Iconify';
import { CustomAlert, DeleteComponent } from '../utils/Alert';
import EditTicket from '../components/Tickets/EditTicket';
import MessagingTicket from '../components/Tickets/MessagingTicket';

const TicketsPage = () => {
  const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))({
    '& .MuiTabs-indicatorSpan': {},
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 'auto',
    '&.Mui-focusVisible': {},
  }));

  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const navigate = useNavigate();

  const [statusFilter, setStatusFilter] = useState('');

  const [activePage, setActivePage] = useState(1);

  const handlePageChange = (event, value) => {
    setActivePage(value);
  };

  const [pastActivePage, setPastActivePage] = useState(1);

  const handlePastPageChange = (event, value) => {
    setPastActivePage(value);
  };

  const { data: categories, isLoading: categoriesLoading } = useGetCategoriesQuery();

  const { data, isLoading, isFetching, refetch } = useGetTicketsQuery({
    spaceId: spaceId || currentSpaceId,
    status: statusFilter,
    activePage,
    page_size: 30,
  });

  useEffect(() => {
    refetch();
  }, [activePage]);

  useEffect(() => {
    setActivePage(1);
    refetch();
  }, [statusFilter]);
  const {
    data: pastTickets,
    isLoading: pastLoading,
    isFetching: pastFetching,
    refetch: pastRefetch,
  } = useGetClosedTicketsQuery({
    spaceId: spaceId || currentSpaceId,
    status: 5,
    activePage: pastActivePage,
    page_size: 30,
  });

  useEffect(() => {
    pastRefetch();
  }, [pastActivePage]);

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [deleteTicket, resultStats] = useDeleteTicketMutation();

  const [popoverOpen, setPopoverOpen] = useState(null); // Initialize with null
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopoverOpen = (event, idx) => {
    setPopoverAnchorEl(event.currentTarget);
    setPopoverOpen(idx);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setPopoverOpen(null);
  };

  const [selectTicket, setSelectTicket] = useState();
  const [selectTicketIndex, setSelectedTicketIndex] = useState();
  const [selectTicketMessaging, setSelectTicketMessaging] = useState();
  const [selectTicketIndexMessaging, setSelectedTicketIndexMessaging] = useState();

  const [openFilterTicket, setOpenFilterTicket] = useState(false);
  const [openFilterTicketMessaging, setOpenFilterTicketMessaging] = useState(false);

  const [openCheck, setOpenCheck] = useState(false);
  const [selectDelete, setSelectDelete] = useState(false);
  const [messageAdd, setMessageAdd] = useState('');

  const handleOpenFilterTicket = (item, idx) => {
    setSelectedTicketIndex(idx);
    setSelectTicket(item);
    handlePopoverClose();
    setOpenFilterTicket(true);
  };

  const handleCloseFilterTicket = () => {
    setOpenFilterTicket(false);
  };

  const handleCloseFilterTicketMessaging = () => {
    setOpenFilterTicketMessaging(false);
  };

  const handleDelete = (item) => {
    setSelectDelete(item);
    setOpenCheck(true);
    handlePopoverClose();
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      handlePopoverClose();
      setType(true);
      setOpen(true);
      setMessageAdd('Ticket deleted successfully.');
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  if ((value === 0 ? isLoading : pastLoading) || categoriesLoading) {
    return <Loader />;
  }

  const renderCards = () => (
    <Grid item xs={12}>
      <TableContainer component={Paper}>
        {value === 0 && (
          <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <Typography mr={1}>Filters</Typography>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} displayEmpty>
              <MenuItem value="">Active</MenuItem>
              <MenuItem value="1">Open</MenuItem>
              <MenuItem value="2">Awaiting for the response</MenuItem>
              <MenuItem value="3">Respond</MenuItem>
              <MenuItem value="4">On Hold</MenuItem>
            </Select>
          </Box>
        )}
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Details</TableCell>
              <TableCell>Submit Date</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {value === 0 ? (
              isFetching ? (
                <Typography p={2}>Loading..</Typography>
              ) : data?.tickets?.length > 0 ? (
                data?.tickets?.map((ticket, idx) => (
                  <TableRow
                    key={ticket.id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/ticket/${ticket.id}`)}
                  >
                    <TableCell>
                      <Box display={'flex'} alignItems={'center'}>
                        <Box
                          sx={{
                            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            padding: '3px',
                            border: '1px dashed rgba(145, 158, 171, 0.2)',
                            borderRadius: '50%',
                          }}
                        >
                          <Avatar src={ticket.attach_photo || '/assets/placeholder.svg'} />
                        </Box>
                        <Box ml={1}>
                          <Typography>{ticket.creator.name}</Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Stack>
                        <Box>{formatDateNotTime(ticket.submit_date)}</Box>
                        <Typography variant="subtitle2">{dayjs(ticket.submit_date).format('hh:mm A')}</Typography>
                      </Stack>
                    </TableCell>

                    <TableCell>
                      {categories?.find((category) => category.children.some((child) => child.id === ticket.category))
                        ?.name || 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          ticket?.status === 2 && ticket?.replies?.length > 0
                            ? 'Awaiting for the response'
                            : ticket?.status === 3 && ticket?.replies?.length > 0
                            ? 'Respond'
                            : ticket?.status === 4
                            ? 'On Hold'
                            : ticket?.status === 5
                            ? 'Closed'
                            : 'Open'
                        }
                        color={
                          ticket?.status === 2 && ticket?.replies?.length > 0
                            ? 'info'
                            : ticket?.status === 3 && ticket?.replies?.length > 0
                            ? 'secondary'
                            : ticket?.status === 4
                            ? 'warning'
                            : ticket?.status === 5
                            ? 'error'
                            : 'success'
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Stack flexDirection={'row'}>
                        <IconButton color="secondary" onClick={() => navigate(`/ticket/${ticket.id}`)}>
                          <Iconify icon={'solar:eye-bold'} />
                        </IconButton>

                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation(); // Stop the event propagation here
                            handleOpenFilterTicket(ticket, idx);
                          }}
                        >
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>

                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation(); // Stop the event propagation here
                            handleDelete(ticket);
                          }}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="h6" paragraph mb={0}>
                      There's no{' '}
                      {parseInt(statusFilter, 10) === 1
                        ? 'Open '
                        : parseInt(statusFilter, 10) === 2
                        ? 'Awaiting for the response '
                        : parseInt(statusFilter, 10) === 3
                        ? 'Respond '
                        : parseInt(statusFilter, 10) === 4
                        ? 'On Hold '
                        : 'active '}
                      ticket
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              ''
            )}

            {value === 1 ? (
              pastFetching ? (
                <Typography p={2}>Loading..</Typography>
              ) : pastTickets?.tickets?.length > 0 ? (
                pastTickets?.tickets?.map((ticket, idx) => (
                  <TableRow
                    key={ticket.id}
                    hover
                    sx={{ cursor: 'pointer' }}
                    onClick={() => navigate(`/ticket/${ticket.id}`)}
                  >
                    <TableCell>
                      <Box display={'flex'} alignItems={'center'}>
                        <Box
                          sx={{
                            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            padding: '3px',
                            border: '1px dashed rgba(145, 158, 171, 0.2)',
                            borderRadius: '50%',
                          }}
                        >
                          <Avatar src={ticket.attach_photo || '/assets/placeholder.svg'} />
                        </Box>
                        <Box ml={1}>
                          <Typography>{ticket.creator.name}</Typography>
                        </Box>
                      </Box>
                    </TableCell>

                    <TableCell>
                      <Stack>
                        <Box>{formatDateNotTime(ticket.submit_date)}</Box>
                        <Typography variant="subtitle2">{dayjs(ticket.submit_date).format('hh:mm A')}</Typography>
                      </Stack>
                    </TableCell>

                    <TableCell>
                      {categories?.find((category) => category.children.some((child) => child.id === ticket.category))
                        ?.name || 'N/A'}
                    </TableCell>
                    <TableCell>
                      <Chip
                        label={
                          ticket?.status === 2 && ticket?.replies?.length > 0
                            ? 'Awaiting for the response'
                            : ticket?.status === 3 && ticket?.replies?.length > 0
                            ? 'Respond'
                            : ticket?.status === 4
                            ? 'On Hold'
                            : ticket?.status === 5
                            ? 'Closed'
                            : 'Open'
                        }
                        color="error"
                      />
                    </TableCell>
                    <TableCell>
                      <Stack flexDirection={'row'}>
                        <IconButton color="secondary" onClick={() => navigate(`/ticket/${ticket.id}`)}>
                          <Iconify icon={'solar:eye-bold'} />
                        </IconButton>

                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation(); // Stop the event propagation here
                            handleOpenFilterTicket(ticket, idx);
                          }}
                        >
                          <Iconify icon={'eva:edit-fill'} />
                        </IconButton>

                        <IconButton
                          color="secondary"
                          onClick={(e) => {
                            e.stopPropagation(); // Stop the event propagation here
                            handleDelete(ticket);
                          }}
                        >
                          <Iconify icon={'eva:trash-2-outline'} />
                        </IconButton>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} align="center">
                    <Typography variant="h6" paragraph mb={0}>
                      There's no closed ticket
                    </Typography>
                  </TableCell>
                </TableRow>
              )
            ) : (
              ''
            )}
          </TableBody>

          {value === 0 && data?.tickets?.length > 0 && !isFetching && (
            <TableFooter>
              <TableCell colSpan={5}>
                <Pagination
                  count={data?.totalPages}
                  color="primary"
                  page={activePage}
                  onChange={handlePageChange}
                  sx={{ '.MuiPagination-ul': { justifyContent: 'center', width: '100%' } }}
                />
              </TableCell>
            </TableFooter>
          )}

          {value === 1 && pastTickets?.tickets?.length > 0 && !pastFetching && (
            <TableFooter>
              <TableCell colSpan={5}>
                <Pagination
                  count={pastTickets?.totalPages}
                  color="primary"
                  page={pastActivePage}
                  onChange={handlePastPageChange}
                  sx={{ '.MuiPagination-ul': { justifyContent: 'center', width: '100%' } }}
                />
              </TableCell>
            </TableFooter>
          )}
        </Table>
      </TableContainer>
    </Grid>
  );

  return (
    <>
      <Helmet>
        <title> Tickets | {name || currentName} </title>
      </Helmet>

      <Container>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography variant="h4" sx={{ mb: 3 }}>
            Tickets
          </Typography>

          <RouterLink to="/support/add-ticket" style={{ textDecoration: 'none' }}>
            <Button sx={{ float: 'right' }} variant="contained">
              Create Ticket
            </Button>
          </RouterLink>
        </Stack>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledTabs
              value={value}
              onChange={handleChange}
              sx={{
                mb: 3,
                '& .MuiTabs-indicator': {
                  backgroundColor: (theme) => theme.palette.text.primary,
                },
                '&.Mui-focusVisible': {
                  backgroundColor: '',
                  outline: 'none',
                  boxShadow: 'none',
                },
              }}
            >
              <StyledTab
                sx={{
                  p: 0,
                  mr: 3,
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.text.primary, // Active tab text color
                    backgroundColor: 'transparent',

                    fontWeight: 600,
                  },
                  fontWeight: 500,
                }}
                label={
                  <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                    <Iconify icon="material-symbols:check-box-outline" sx={{ marginRight: '10px' }} />
                    Active
                  </Typography>
                }
              />
              <StyledTab
                sx={{
                  p: 0,
                  mr: 3,
                  '&.Mui-selected': {
                    color: (theme) => theme.palette.text.primary, // Active tab text color
                    fontWeight: 600,
                  },
                  fontWeight: 500,
                }}
                label={
                  <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                    <Iconify icon="gg:close-r" sx={{ marginRight: '10px' }} />
                    Closed
                  </Typography>
                }
              />
            </StyledTabs>
          </Grid>
          {renderCards()}

          {selectTicket && (
            <EditTicket
              idx={selectTicketIndex}
              {...selectTicket}
              categories={categories}
              onCloseFilter={handleCloseFilterTicket}
              openFilter={openFilterTicket}
            />
          )}

          {selectTicketMessaging && (
            <MessagingTicket
              idx={selectTicketIndexMessaging}
              {...selectTicketMessaging}
              categories={categories}
              onCloseFilter={handleCloseFilterTicketMessaging}
              openFilter={openFilterTicketMessaging}
            />
          )}
        </Grid>
      </Container>

      {selectDelete?.id && (
        <DeleteComponent
          handleCloseCheck={handleCloseCheck}
          openCheck={openCheck}
          name={'ticket'}
          deleted={() => deleteTicket({ spaceId: spaceId || currentSpaceId, ticketId: selectDelete?.id })}
        />
      )}

      <CustomAlert
        type={type ? 'success' : 'error'}
        message={messageAdd}
        open={open}
        autoHideDuration={5000}
        setOpen={setOpen}
      />
    </>
  );
};

export default TicketsPage;
