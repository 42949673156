import { Autocomplete, Box, Container, Grid, Stack, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import { Controller, useForm } from 'react-hook-form';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { CustomAlert } from '../utils/Alert';
import Iconify from '../components/iconify/Iconify';
import { useCreateTourMutation, useGetTourLocationsQuery, useGetTourQuestionsQuery } from '../Slices/constantApi';

const StyledContent = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 24px;
  //   min-height: 100vh;
  display: flex;
  margin: auto;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 600px) {
    width: 90%;
  }

  @media (min-width: 960px) {
    width: 800px;
  }
`;

export default function TourPage() {
  const { companyName } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const { data, isLoading } = useGetTourLocationsQuery(companyName);

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
    trigger,
    register,
  } = useForm();

  const minDateTime = dayjs().add(30, 'minutes');

  const { data: allQuestions } = useGetTourQuestionsQuery({ spaceId: watch('selectedLocation.id') });

  React.useEffect(() => {
    setValue('selectedLocation', data?.[0]);
  }, [data]);

  React.useEffect(() => {
    setValue('tour_datetime', minDateTime);
  }, []);

  const weekdays = watch('selectedLocation.openDays')?.length > 0 ? watch('selectedLocation.openDays') : [];

  const isWeekdayDisabled = (date) => {
    const dayOfWeek = date.day();

    const isDayOff = !weekdays?.some((day) => day.day === (dayOfWeek === 0 ? 7 : dayOfWeek));

    return isDayOff;
  };

  const [createTour, resultStats] = useCreateTourMutation();

  const [isCaptchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const onSubmit = async (data) => {
    // setLoading(true);
    const isFormValid = await trigger();
    if (isFormValid) {
      const originalDateString = data?.tour_datetime;

      const originalDate = new Date(originalDateString);

      const utcISOString = originalDate.toISOString();

      const formattedData = allQuestions?.tourQuestions.map((question) => ({
        question: question.id,
        answer: data.answers?.[question.id]?.answer || '', // Handle optional fields
      }));

      createTour({
        spaceId: data?.selectedLocation?.id,
        data: {
          name: data?.name,
          email: data?.email,
          phone_number: data?.phone_number,
          tour_datetime: utcISOString,
          response: formattedData,
        },
      });
    }
  };

  React.useEffect(() => {
    if (resultStats?.isSuccess) {
      // const currentUrl = location.pathname;
      const newUrl = `/booked?created_at=${dayjs(watch(`tour_datetime`)).unix()}`;
      navigate(newUrl);
    } else if (resultStats?.isError) {
      setLoading(false);
      setType(false);
      setOpen(true);
      setMessage(resultStats?.error.data.message || resultStats?.error.data.error || resultStats?.error.data[0]);
    }
  }, [resultStats]);

  return (
    <>
      <Helmet>
        <title> Reserve your tour at {companyName}</title>
      </Helmet>

      <Container sx={{ paddingX: '0px', paddingBottom: '25px' }}>
        <Box sx={{ height: '25px' }} />
        <StyledContent>
          {isLoading ? (
            <Box>Loading...</Box>
          ) : data?.length > 0 ? (
            <>
              <Typography variant="h5" sx={{ display: 'flex', alignItems: 'center', mb: 5 }}>
                <Iconify icon="simple-line-icons:calender" sx={{ mr: 1 }} />
                Request a tour
              </Typography>

              <Typography variant="body1" fontWeight={'bold'} mb={3}>
                Personal details
              </Typography>

              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: 'Name is required' }}
                    render={({ field }) => (
                      <TextField
                        label="Name"
                        fullWidth
                        {...field}
                        error={Boolean(errors.name)}
                        helperText={errors.name ? errors.name.message : ''}
                        onBlur={() => trigger('name')} // Trigger validation onBlur
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    rules={{
                      required: 'Email is required',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                        message: 'Invalid email address format',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        label="Email address"
                        fullWidth
                        {...field}
                        error={Boolean(errors.email)}
                        helperText={errors.email ? errors.email.message : ''}
                        onBlur={() => trigger('email')} // Trigger validation onBlur
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={6}>
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Please enter only numeric digits.',
                      },
                      minLength: {
                        value: 11,
                        message: 'Phone number must have at least 11 characters',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Phone Number (optional)"
                        {...field}
                        error={Boolean(errors.phone_number)}
                        helperText={errors.phone_number ? errors.phone_number.message : ''}
                        onBlur={() => trigger('phone_number')} // Trigger validation onBlur
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Typography variant="body1" fontWeight={'bold'} mt={1}>
                    Tour date and time
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  {watch('selectedLocation') && (
                    <Typography variant="body2" color={'GrayText'} fontWeight={'bold'} mt={-1} mb={1}>
                      We would love to show you {watch('selectedLocation.name')}. Please let us know when you are
                      available and we will do our best to receive you on that date and time.
                    </Typography>
                  )}
                </Grid>

                {watch('selectedLocation') ? (
                  <Grid item xs={12}>
                    <Controller
                      name="selectedLocation"
                      control={control}
                      rules={{ required: 'Location is required' }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          options={data}
                          value={watch('selectedLocation')}
                          onChange={(event, newValue) => {
                            const selectedLocationId = newValue || data?.[0];
                            setValue('selectedLocation', selectedLocationId);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Location"
                              fullWidth
                              error={!!errors.selectedLocation}
                              helperText={errors.selectedLocation ? errors.selectedLocation.message : ''}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={12}>
                    No valid url
                  </Grid>
                )}

                {allQuestions?.tourQuestions?.length > 0 &&
                  allQuestions?.tourQuestions?.map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Controller
                        name={`answers[${item.id}].answer`}
                        control={control}
                        rules={{ required: item.isRequired ? 'This question is required' : '' }}
                        render={({ field }) => (
                          <TextField
                            fullWidth
                            label={`${item.question}`}
                            type={item.questionType === 1 ? 'text' : 'number'}
                            placeholder="Enter answer"
                            variant="outlined"
                            {...field}
                            error={Boolean(errors.answers?.[item.id]?.answer)}
                            helperText={errors.answers?.[item.id]?.answer?.message || ''}
                            onBlur={() => trigger(`answers[${item.id}].answer`)}
                          />
                        )}
                      />
                    </Grid>
                  ))}

                <Grid item xs={12}>
                  {watch('selectedLocation') && (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Controller
                        name={`tour_datetime`}
                        control={control}
                        defaultValue={null}
                        rules={{ required: 'Tour Date and Time is required' }}
                        render={({ field, fieldState }) => (
                          <>
                            <DateTimePicker
                              {...field}
                              shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                              sx={{ width: '100%' }}
                              renderInput={(props) => (
                                <TextField
                                  {...props}
                                  label="Tour Date and Time"
                                  error={!!fieldState.error}
                                  helperText={fieldState.error ? fieldState.error.message : ''}
                                />
                              )}
                              disablePast
                            />
                          </>
                        )}
                      />
                    </LocalizationProvider>
                  )}
                </Grid>

                <Grid item xs={12}>
                  <ReCAPTCHA sitekey="6Let1QEpAAAAAG7ivDvE1dZIjA_7GMfBgpoFw0kw" onChange={handleCaptchaChange} />
                </Grid>

                <Grid item xs={12}>
                  <LoadingButton
                    disabled={!isCaptchaVerified || data?.length <= 0 || !data}
                    loading={loading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {data?.length <= 0 || !data ? "This location doesn't exist" : 'Request a tour'}
                  </LoadingButton>
                </Grid>
              </Grid>
            </>
          ) : (
            <Box>Not a Valid Url</Box>
          )}

          <CustomAlert type={type ? 'success' : 'error'} message={message} open={open} setOpen={setOpen} />
        </StyledContent>
      </Container>
    </>
  );
}
