import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CardHeader,
  Chip,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Switch,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { LoadingButton } from '@mui/lab';
import { formatDate } from '../utils/displayDateTime';
import { CustomAlert } from '../utils/Alert';
import {
  useCreateTicketReplyMutation,
  useDeleteTikcetReplyMutation,
  useGetAllTicketRepliesQuery,
  useGetCategoriesQuery,
  useGetPackagesQuery,
  useGetSpecficTicketQuery,
  useUpdateTicketMutation,
  useUpdateTikcetReplyMutation,
} from '../Slices/spaceApi';
import Loader from '../components/Loader/Loader';
import Iconify from '../components/iconify';
import { TimeAgo } from '../utils/TimeAgoFunction';

const TicketViewPage = () => {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const { id } = useParams();
  // const [dailog, setDailog] = useState(false);
  const { data: categories, isLoading: categoriesLoading } = useGetCategoriesQuery();

  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const { data, isLoading, refetch } = useGetSpecficTicketQuery({ spaceId: spaceId || currentSpaceId, ticketId: id });
  const checkOldUser = packages?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  useEffect(() => {
    refetch();
  }, [id]);
  const [text, setText] = useState('');
  const [load, setLoad] = useState(false);
  const [type, setType] = useState(false);

  const [checked, setChecked] = useState(data?.status === 5);

  useEffect(() => {
    setChecked(data?.status === 5);
  }, [data]);

  const [editLoad, setEditLoad] = useState(false);

  const [open, setOpen] = useState(false);
  const [messageAdd, setMessageAdd] = useState('');
  const [editReply, setEditReply] = useState('');
  const [checkIndex, setCheckIndex] = useState('');

  const { data: ticketReplies, isLoading: repliesLoading } = useGetAllTicketRepliesQuery({
    spaceId: spaceId || currentSpaceId,
    ticketId: id,
  });

  const [createReply, resultStats] = useCreateTicketReplyMutation();
  const [deleteReply, resultStats1] = useDeleteTikcetReplyMutation();
  const [updateReply, resultStats2] = useUpdateTikcetReplyMutation();
  const [updateTicket, resultStats3] = useUpdateTicketMutation();

  const handleChangeSwitch = () => {
    if (!checked) {
      updateTicket({ spaceId: spaceId || currentSpaceId, data: { status: 5 }, ticketId: id });
    } else {
      updateTicket({ spaceId: spaceId || currentSpaceId, data: { status: 1 }, ticketId: id });
    }
    setChecked((prev) => !prev);
  };

  const [popoverOpen, setPopoverOpen] = useState(null); // Initialize with null
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopoverOpen = (event, idx) => {
    setPopoverAnchorEl(event.currentTarget);
    setPopoverOpen(idx);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setPopoverOpen(null);
  };

  const handleChange = (value) => {
    setText(value);
  };

  const handleEditChange = (value) => {
    setEditReply(value);
  };

  const postReplyComment = () => {
    setLoad(true);
    createReply({
      spaceId: spaceId || currentSpaceId,
      ticketId: id,
      data: {
        message: text,
      },
    });
  };

  useEffect(() => {
    if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setLoad(false);
      setType(false);
    }
    if (resultStats?.isSuccess) {
      setText('');
      setOpen(true);
      setType(true);
      setMessageAdd('Reply added successfully.');
      updateTicket({ spaceId: spaceId || currentSpaceId, data: { status: 2 }, ticketId: id });
      setLoad(false);
    }
  }, [resultStats]);

  const handleDeleteReply = (replyId) => {
    deleteReply({ spaceId: spaceId || currentSpaceId, ticketId: id, replyId });
  };

  useEffect(() => {
    if (resultStats1?.isError) {
      const errorMessage = Object.values(resultStats1?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }

    if (resultStats1?.isSuccess) {
      handlePopoverClose();
      setOpen(true);
      setType(true);
      setMessageAdd('Reply deleted successfully.');
    }
  }, [resultStats1]);

  useEffect(() => {
    if (resultStats3?.isError) {
      const errorMessage = Object.values(resultStats3?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }
  }, [resultStats3]);

  const handleEditReply = (item) => {
    handlePopoverClose();
    setEditReply(item.message);
    setCheckIndex(item.id);
  };

  const postEditReply = (replyId) => {
    setEditLoad(true);
    updateReply({
      spaceId: spaceId || currentSpaceId,
      ticketId: id,
      replyId,
      data: {
        message: editReply,
      },
    });
  };

  useEffect(() => {
    if (resultStats2?.isError) {
      const errorMessage = Object.values(resultStats2?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setEditLoad(false);
      setType(false);
    }

    if (resultStats2?.isSuccess) {
      setEditReply('');
      setMessageAdd('Reply updated successfully.');
      setEditLoad(false);
      setOpen(true);
      setType(true);
    }
  }, [resultStats2]);

  if (pacakgeLoading || isLoading || categoriesLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Helmet>
        <title> View Invoice | {id} </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Ticket #{id} - {data?.purpose}
        </Typography>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4.5}>
            <Typography variant="h6">Ticket Information</Typography>
            <Card sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}>
              <Stack>
                <Stack padding={'16px 16px'} gap={'16px'}>
                  <Box>
                    <Typography variant="subtitle2" fontSize="14px">
                      Creator
                    </Typography>
                    <Typography variant="body2" mt={0.5} display={'flex '} alignItems={'center'}>
                      <Typography fontWeight={'500'}>{data?.creator.name}</Typography>
                      <Chip label="Owner" sx={{ ml: 1 }} />
                    </Typography>
                  </Box>
                </Stack>
                <Divider />
                <Stack padding={'16px 16px'} gap={'16px'}>
                  <Box>
                    <Typography variant="subtitle2" fontSize="14px">
                      Category
                    </Typography>
                    <Typography variant="body2" mt={0.5} display={'flex '} alignItems={'center'}>
                      {categories?.find((category) => category.children.some((child) => child.id === data.category))
                        ?.name || 'N/A'}
                    </Typography>
                  </Box>
                </Stack>
                <Divider />
                <Stack padding={'16px 16px'} gap={'16px'}>
                  <Box>
                    <Typography variant="subtitle2" fontSize="14px">
                      Submitted
                    </Typography>
                    <Typography variant="body2" mt={0.5}>
                      {formatDate(data?.submit_date)}
                    </Typography>
                  </Box>
                </Stack>
                <Divider />
                <Stack padding={'16px 16px'} gap={'16px'}>
                  <Box display="flex" justifyContent={'space-between'} alignItems={'flex-end'}>
                    <Box>
                      <Typography variant="subtitle2" fontSize="14px">
                        Status{' '}
                      </Typography>
                      <Typography variant="body2" mt={0.5}>
                        {data?.status === 2 && data?.replies?.length > 0
                          ? 'Awaiting for the response'
                          : data?.status === 3 && data?.replies?.length > 0
                          ? 'Respond'
                          : data?.status === 4
                          ? 'On Hold'
                          : data?.status === 5
                          ? 'Closed'
                          : 'Open'}
                      </Typography>
                    </Box>

                    <Box>
                      <FormControlLabel
                        control={
                          <Switch checked={checked} disabled={resultStats3?.isLoading} onChange={handleChangeSwitch} />
                        }
                        label="Want to Close Ticket?"
                      />
                    </Box>
                  </Box>
                </Stack>
              </Stack>
            </Card>
          </Grid>
          <Grid item xs={12} md={7.5} sx={{ mt: { md: '30px' } }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<Iconify icon="ooui:expand" sx={{ width: '12px', height: '12px' }} />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box display={'flex'} alignItems={'center'}>
                  <IconButton color="secondary" sx={{ mr: 1 }}>
                    <Iconify icon={'eva:edit-fill'} />
                  </IconButton>

                  <Typography>Reply</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <ReactQuill
                  theme="snow" // Other themes are available
                  value={text}
                  onChange={handleChange}
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, 4, 5, 6, false] }],
                      ['bold', 'italic', 'underline', 'strike'],
                      ['link'],
                      [{ list: 'ordered' }, { list: 'bullet' }],
                      [{ align: [] }],
                    ],
                  }}
                />

                <LoadingButton
                  variant="contained"
                  sx={{ float: 'right', my: 3 }}
                  loading={load}
                  onClick={postReplyComment}
                >
                  Add Reply
                </LoadingButton>
              </AccordionDetails>
            </Accordion>

            <Card sx={{ display: 'flex', flexDirection: 'column', mt: 3 }}>
              {repliesLoading ? (
                <Box padding={'24px 24px'}>Loading...</Box>
              ) : ticketReplies?.messages?.length > 0 ? (
                ticketReplies?.messages?.map((item, idx) => (
                  <>
                    <CardHeader
                      avatar={<Avatar src={data?.creator.image} />}
                      title={
                        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                          <div>
                            {item.owner ? item.owner.name : data?.creator.name}
                            <Chip
                              sx={{ marginLeft: '10px', fontWeight: 'normal', fontSize: '13px' }}
                              size="small"
                              label={<TimeAgo date={item.created_at} />}
                            />
                          </div>
                          {item.owner === null && (
                            <Box display={'flex'} flexDirection={'column'} alignItems={'flex-end'}>
                              <IconButton
                                color="secondary"
                                aria-label="more"
                                aria-controls={`popover-${idx}`}
                                aria-haspopup="true"
                                onClick={(e) => handlePopoverOpen(e, idx)}
                              >
                                <Iconify icon="entypo:dots-three-horizontal" />
                              </IconButton>
                            </Box>
                          )}
                        </Stack>
                      }
                    />
                    <Box
                      sx={{ padding: '10px 24px', overflow: 'auto', wordWrap: 'break-word' }}
                      dangerouslySetInnerHTML={{ __html: item.message }}
                    />

                    {editReply && checkIndex === item.id && (
                      <Box sx={{ padding: '10px 24px' }}>
                        <ReactQuill
                          theme="snow" // Other themes are available
                          value={editReply}
                          onChange={handleEditChange}
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              ['bold', 'italic', 'underline', 'strike'],
                              ['link'],
                              [{ list: 'ordered' }, { list: 'bullet' }],
                              [{ align: [] }],
                            ],
                          }}
                        />

                        <LoadingButton
                          variant="contained"
                          sx={{ float: 'right', my: 3 }}
                          loading={editLoad}
                          onClick={() => postEditReply(item.id)}
                        >
                          Update Reply
                        </LoadingButton>
                      </Box>
                    )}

                    <Divider />

                    <Popover
                      id={`popover-${idx}`}
                      open={popoverOpen === idx}
                      anchorEl={popoverAnchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      PaperProps={{
                        sx: {
                          p: 1,
                          width: 140,
                          '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                          },
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleEditReply(item)}>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        Edit
                      </MenuItem>
                      <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteReply(item.id)}>
                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                        Delete
                      </MenuItem>
                    </Popover>
                  </>
                ))
              ) : (
                <Box padding={'24px 24px'}>No Reply Yet.</Box>
              )}
            </Card>
          </Grid>
        </Grid>
      </Container>
      {<CustomAlert type={type ? 'success' : 'error'} message={messageAdd} open={open} setOpen={setOpen} />}
    </div>
  );
};

export default TicketViewPage;
