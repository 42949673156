import { Box, Checkbox, Divider, FormControlLabel, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useCheckHotDeskAvailibilityMutation, useGetDiscountsQuery } from '../../Slices/spaceApi';
import { getNextOpenDate } from '../../utils/nextOpenDay';

const EnquiryProductForm = ({
  control,
  product,
  trigger,
  errors,
  publicRoom,
  register,
  setValue,
  weekdaysData,
  hotDesk,
  hotDeskDate,
  duration,
  categories,
  products,
  watch,
  currency,
  globalTaxRateObj,
  currentGlobalTaxRateObj,
  locationId,
}) => {
  const { data, isLoading, isError, isSuccess, error, isFetching, refetch } = useGetDiscountsQuery(
    {
      spaceId: locationId,
      code: watch('discountCode'),
    },
    {
      skip: !locationId || !watch('discountCode'),
    }
  );

  useEffect(() => {
    if (watch('discountCode')?.length > 0 && locationId) {
      refetch();
    }
  }, [watch('discountCode')]);

  useEffect(() => {
    if (isError) {
      setValue('activeCode', '');
    } else if (isSuccess && data?.code === watch('discountCode') && data?.validOnProducts && new Date(data.validTill)) {
      setValue('activeCode', watch('discountCode'));
    } else {
      setValue('activeCode', '');
    }
  }, [isFetching]);

  const { openDays, currentOpenDays } = useSelector((item) => item.spaceReducer);

  const currentDate = dayjs();

  const minDate = currentDate; // You can set a minimum date here

  const validity = duration?.spaceDurations?.find((duration, idx) => duration.id === product?.duration)?.day;

  const maxDate = null; // You can set a maximum date here

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date);
    setValue(`startDate`, formattedDate);
  };

  const weekdays = publicRoom ? weekdaysData : openDays?.length > 0 ? openDays : JSON.parse(currentOpenDays);

  useEffect(() => {
    if (products?.products?.length === 0)
      if (hotDesk && hotDeskDate) {
        const formattedDate = dayjs(hotDeskDate);
        setValue(`startDate`, formattedDate);
      } else {
        setValue(
          `startDate`,
          getNextOpenDate(publicRoom ? weekdays : openDays, publicRoom ? weekdays : currentOpenDays)
        );
      }
  }, []);

  const isWeekdayDisabled = (date) => {
    const dayOfWeek = date.day();

    // Check if the day is not marked as "open" in the weekdays array
    const isDayOff = !weekdays?.some((day) => day.day === (dayOfWeek === 0 ? 7 : dayOfWeek));

    return isDayOff;
  };

  let totalTax = 0;

  const totalPrice =
    watch(`quantity_${product.id}`) > 0 ? watch(`quantity_${product.id}`) * product.price : product.price;

  const price =
    watch(`quantity_${product.id}`) > 0
      ? (watch(`quantity_${product.id}`) * product.price).toFixed(2)
      : product.price.toFixed(2);
  // product.quantity > 0 ? product.quantity * product.price : product.price;

  if (product.taxRateObj && !product.taxRateObj.setOverallTax && !product?.inclusiveTax) {
    totalTax += (price * (product.taxRateObj.serviceCharges + product.taxRateObj.rentalFee)) / 100;
  } else if (product.taxRateObj && product.taxRateObj.setOverallTax) {
    totalTax += (price * product.taxRateObj.serviceCharges) / 100;
  } else if (
    !product?.taxRateObj &&
    (globalTaxRateObj?.setOverallTax ||
      (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax)) &&
    !product?.inclusiveTax
  ) {
    totalTax +=
      (price *
        (globalTaxRateObj?.serviceCharges ||
          (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges))) /
      100;
  } else if (
    !product?.taxRateObj &&
    (!globalTaxRateObj?.setOverallTax ||
      !(currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax)) &&
    !product?.inclusiveTax
  ) {
    totalTax +=
      (price *
        ((globalTaxRateObj?.serviceCharges ||
          (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) +
          (globalTaxRateObj?.rentalFee ||
            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)))) /
      100;
  }

  useEffect(() => {
    const checkHotDesk =
      (products?.products?.some((product) =>
        categories?.some((category) => category.id === product.category && category.name === 'Hot Desks')
      ) &&
        products?.products?.some((item) => item?.benefits?.dayPass?.quantity > 0)) ||
      (product.id && categories?.some((item) => item.id === product?.category && item.name === 'Hot Desks'));

    if (!checkHotDesk && !publicRoom) {
      setValue('hotDesk', false);
    }
  }, []);

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <Controller
            name={`startDate`}
            control={control}
            rules={{ required: 'Start Date is required' }}
            render={({ field }) => (
              <DatePicker
                sx={{ width: '100%' }}
                {...field}
                label="Select Start Date"
                disabled={hotDeskDate || products?.products?.length >= 1}
                // minDate={publicRoom && hotDeskDate ? dayjs(hotDeskDate).subtract(validity) : minDate}
                minDate={publicRoom && hotDeskDate ? dayjs(hotDeskDate).subtract(validity, 'days') : minDate}
                disablePast
                maxDate={publicRoom && hotDeskDate ? dayjs(hotDeskDate) : maxDate}
                shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                format="YYYY-MM-DD"
                onChange={(date) => {
                  handleDateChange(date);
                }}
                error={!!errors.startDate}
                helperText={errors.startDate && errors.startDate.message}
              />
            )}
          />
        </DemoContainer>
      </LocalizationProvider>

      {product?.manageStock && (
        <Controller
          name={`quantity_${product.id}`}
          control={control}
          rules={{
            required: 'Quantity is required',
            pattern: {
              value: /^[0-9]+$/,
              message: 'Please enter only numeric digits.',
            },

            validate: (value) => {
              const enteredQuantity = parseInt(value, 10);
              // eslint-disable-next-line no-restricted-globals
              if (isNaN(enteredQuantity) || enteredQuantity <= 0) {
                return 'Quantity must be greater than 1.';
              }
              if (product.manageStock) {
                const maxQuantity = product.quantity;
                if (enteredQuantity > maxQuantity) {
                  return `Quantity must be less than or equal to product capacity, which is ${maxQuantity}.`;
                }
              }
              return true;
            },
          }}
          render={({ field }) => (
            <TextField
              fullWidth
              label="Quantity"
              {...field}
              error={Boolean(errors[`quantity_${product.id}`])}
              helperText={errors[`quantity_${product.id}`] ? errors[`quantity_${product.id}`].message : ''}
              onBlur={() => trigger(`quantity_${product.id}`)} // Trigger validation onBlur
            />
          )}
        />
      )}

      {!publicRoom &&
        product?.benefits?.dayPass?.quantity > 0 &&
        categories?.some((item) => item.id === product?.category && item.name === 'Hot Desks') && (
          <FormControlLabel
            control={
              <Controller
                name={'hotDesk'}
                control={control}
                render={({ field: props }) => (
                  <Checkbox
                    {...props}
                    checked={props.value}
                    // disabled={products?.products?.length >= 1}
                    onChange={(e) => props.onChange(e.target.checked)}
                  />
                )}
              />
            }
            label={'Book hot desk on this date'}
          />
        )}

      {publicRoom && hotDesk && (
        <Box>
          <Controller
            name={`discountCode`}
            control={control}
            render={({ field }) => <TextField fullWidth label="Coupon Code (optional)" {...field} />}
          />

          {watch('discountCode') && (
            <Box mt={0.5}>
              {isLoading ? (
                <Typography>Loading..</Typography>
              ) : isError ? (
                <Typography sx={{ color: 'error.main' }}>{error?.data?.error || error?.data?.message}</Typography>
              ) : (
                <>
                  {data?.validOnProducts && new Date(data.validTill) > new Date() ? (
                    <Typography />
                  ) : new Date(data.validTill) < new Date() ? (
                    <Typography sx={{ color: 'error.main' }}>Discount has expired</Typography>
                  ) : (
                    <Typography sx={{ color: 'error.main' }}>
                      This coupon does not offer a discount for this product
                    </Typography>
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      )}

      {publicRoom && hotDesk && (
        <Box>
          <Divider />

          <Stack
            justifyContent={'space-between'}
            mt={2}
            gap={2}
            px={1}
            flexDirection={'row'}
            alignItems={'flex-end'}
            flexWrap={'wrap'}
          >
            <Stack>
              <Typography variant="subtitle1">{'Qty'}</Typography>

              <Typography>{watch(`quantity_${product.id}`) > 0 ? watch(`quantity_${product.id}`) : 1}x</Typography>
            </Stack>

            <Stack>
              <Typography variant="subtitle1">{'Price'}</Typography>

              <Typography>
                {product.quantity !== 0 ? `${product.price} ${currency}` : `${product.price?.toFixed(2)} ${currency}`}
              </Typography>
            </Stack>

            {product?.inclusiveTax ? (
              <Stack textAlign={'center'} sx={{ display: 'none' }}>
                <Typography>No Tax</Typography>
              </Stack>
            ) : product?.taxRateObj ? (
              <>
                {product?.taxRateObj?.setOverallTax ? (
                  <Stack textAlign={'center'} sx={{ display: product?.taxRateObj?.serviceCharges <= 0 ? 'none' : '' }}>
                    <Typography variant="subtitle1">Service</Typography>

                    <Typography>{product?.taxRateObj?.serviceCharges}</Typography>
                  </Stack>
                ) : (
                  <>
                    <Stack textAlign={'center'} sx={{ display: product?.taxRateObj?.rentalFee <= 0 ? 'none' : '' }}>
                      <Typography variant="subtitle1">Rent Fee</Typography>

                      <Typography>{product?.taxRateObj?.rentalFee} %</Typography>
                    </Stack>

                    <Stack
                      textAlign={'center'}
                      sx={{ display: product?.taxRateObj?.serviceCharges <= 0 ? 'none' : '' }}
                    >
                      <Typography variant="subtitle1">Service</Typography>

                      <Typography>{product?.taxRateObj?.serviceCharges}%</Typography>
                    </Stack>
                  </>
                )}
              </>
            ) : (
              <>
                {globalTaxRateObj?.setOverallTax ||
                (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax) ? (
                  <Stack
                    sx={{
                      display:
                        (globalTaxRateObj?.serviceCharges ||
                          (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) <= 0
                          ? 'none'
                          : '',
                    }}
                  >
                    <Typography variant="subtitle1">Service</Typography>
                    <Typography>
                      {globalTaxRateObj?.serviceCharges ||
                        (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)}
                    </Typography>
                  </Stack>
                ) : (
                  <>
                    <Stack
                      sx={{
                        display:
                          (globalTaxRateObj?.rentalFee ||
                            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)) <= 0
                            ? 'none'
                            : '',
                      }}
                    >
                      <Typography variant="subtitle1">Rent Fee</Typography>
                      <Typography>
                        {globalTaxRateObj?.rentalFee ||
                          (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)}{' '}
                        %
                      </Typography>
                    </Stack>
                    <Stack
                      sx={{
                        display:
                          (globalTaxRateObj?.serviceCharges ||
                            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) <= 0
                            ? 'none'
                            : '',
                      }}
                    >
                      <Typography variant="subtitle1">Service</Typography>
                      <Typography>
                        {globalTaxRateObj?.serviceCharges ||
                          (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)}
                        %
                      </Typography>
                    </Stack>
                  </>
                )}
              </>
            )}
          </Stack>

          <Stack alignItems={'flex-end'} mt={3} px={1}>
            <Stack columnGap={1}>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography marginRight={2}>Subtotal:</Typography>

                <Typography fontWeight={'600'}>
                  {totalPrice.toFixed(2)} {currency}
                </Typography>
              </Stack>

              <Stack
                flexDirection={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{ display: totalTax <= 0 ? 'none' : '' }}
              >
                <Typography marginRight={2}>Total Tax:</Typography>

                <Typography fontWeight={'600'}>
                  {totalTax.toFixed(2)} {currency}
                </Typography>
              </Stack>

              {isSuccess && data?.validOnProducts && new Date(data.validTill) > new Date() && (
                <Stack
                  flexDirection={'row'}
                  justifyContent={'space-between'}
                  alignItems={'center'}
                  sx={{ display: data?.amount <= 0 ? 'none' : '' }}
                >
                  <Typography marginRight={2}>Discount:</Typography>

                  {isSuccess && data?.validOnProducts && new Date(data.validTill) > new Date() ? (
                    <Typography fontWeight={'600'}>
                      {data?.fixedAmount ? `${data?.amount} ${currency}` : `${data?.amount} %`}
                    </Typography>
                  ) : (
                    <Typography fontWeight={'600'}>0</Typography>
                  )}
                </Stack>
              )}

              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography marginRight={2}>Total:</Typography>

                <Typography fontWeight={'600'}>
                  {isSuccess && data?.validOnProducts && new Date(data.validTill) > new Date()
                    ? data?.fixedAmount
                      ? `${Math.max((data?.amount > totalPrice ? 0 : totalPrice - data?.amount) + totalTax, 0).toFixed(
                          2
                        )} ${currency}`
                      : `${Math.max(totalPrice - (totalPrice * data?.amount) / 100 + totalTax, 0).toFixed(
                          2
                        )} ${currency}`
                    : `${(totalPrice + totalTax).toFixed(2)} ${currency}`}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Box>
      )}

      {publicRoom && <Divider />}

      {publicRoom && (
        <>
          <Grid item xs={12}>
            <Typography variant="subtitle1" mb={2}>
              Please Enter your details. We shall be sending you an email to verify and create a pass code.
            </Typography>

            <TextField
              label="Name"
              variant="outlined"
              fullWidth
              {...register('name', {
                required: 'Name is required',
              })}
              error={!!errors.name}
              helperText={errors.name?.message}
              onBlur={() => trigger('name')}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: 'Invalid email address',
                },
              })}
              error={!!errors.email}
              helperText={errors.email?.message}
              onBlur={() => trigger('email')}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="phone_number"
              control={control}
              rules={{
                required: 'Phone Number is required',
                pattern: {
                  value: /^[0-9]+$/,
                  message: 'Please enter only numeric digits.',
                },
                minLength: {
                  value: 11,
                  message: 'Phone number must have at least 11 characters',
                },
              }}
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Phone Number"
                  {...field}
                  error={Boolean(errors.phone_number)}
                  helperText={errors.phone_number ? errors.phone_number.message : ''}
                  onBlur={() => trigger('phone_number')} // Trigger validation onBlur
                />
              )}
            />
          </Grid>
        </>
      )}
    </>
  );
};
export default EnquiryProductForm;
