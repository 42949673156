import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useNavigate } from 'react-router-dom';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useDispatch } from 'react-redux';
import { DELETECHECK } from '../Slices/spaceSlice';

const Alert = React.forwardRef((props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />);

export function CustomAlert({ type, open, message, setOpen, autoHideDuration = 5000 }) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    >
      <Alert onClose={handleClose} severity={type} sx={{ width: '100%', color: 'white' }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export function DeleteComponent({ openCheck, handleCloseCheck, name, deleted }) {
  const handleConfirmDelete = () => {
    deleted();
    handleCloseCheck();
  };

  return (
    <div>
      <Dialog
        open={openCheck}
        onClose={handleCloseCheck}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Confirmation ${name === 'booking' ? 'cancel' : 'delete'}`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {name === 'booking' ? 'cancel' : 'delete'} this {name}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseCheck}>Cancel</Button>
          <Button onClick={handleConfirmDelete} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export function ConfirmComponent({ openCheck }) {
  const navigate = useNavigate();

  const handleConfirmDelete = () => {
    navigate('/user/account');
  };

  return (
    <div>
      <Dialog open={openCheck} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{'Confirmation Information'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Feature access paused until invoice is paid
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDelete} autoFocus>
            Okay
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
