import PropTypes from 'prop-types';
import { set, sub } from 'date-fns';
import { noCase } from 'change-case';
import { faker } from '@faker-js/faker';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListItemText,
  ListSubheader,
  ListItemButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  useDeleteNotificationMutation,
  useGetNotificationsQuery,
  useUpdateNotificationMutation,
} from '../../../Slices/spaceApi';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { CustomAlert, DeleteComponent } from '../../../utils/Alert';

export default function NotificationsPopover() {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [beforeThatNotifications, setBeforeThatNotifications] = useState([]);
  const [newNotifications, setNewNotifications] = useState([]);
  const { data, isLoading } = useGetNotificationsQuery({ spaceId: spaceId || currentSpaceId });
  const [updateNotification, resultStats] = useUpdateNotificationMutation();
  const [totalUnRead, setTotalUnRead] = useState();

  useEffect(() => {
    if (!isLoading && data?.length > 0) {
      setNewNotifications(data?.filter((notification) => !notification.isExpired));
      setBeforeThatNotifications(data?.filter((notification) => notification.isExpired));
    }

    const totalUnRead = data?.filter((item) => !item.read).length;
    setTotalUnRead(totalUnRead);
  }, [data]);

  // const totalUnRead = newNotifications?.filter((item) => item.read === false).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
    const totalUnRead = data?.filter((item) => !item.read);

    totalUnRead?.map((item) => {
      const notificationId = item?.id;
      if (notificationId) {
        updateNotification({
          spaceId: spaceId || currentSpaceId,
          notificationId,
          data: { read: true },
        });
      }
      return null; // You must return something from the map function
    });
  };

  const handleClose = () => {
    setOpen(null);
  };

  return (
    <>
      <Box
        sx={{
          transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:hover': {
            transform: 'scale(1.05)',
          },
        }}
      >
        <IconButton color="secondary" onClick={handleOpen} sx={{ width: 40, height: 40 }}>
          <Badge badgeContent={totalUnRead} color="error">
            <Iconify icon="solar:bell-bing-bold-duotone" />
          </Badge>
        </IconButton>
      </Box>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            {/* <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography> */}
          </Box>

          {/* {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )} */}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          {newNotifications.length > 0 ? (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  New
                </ListSubheader>
              }
            >
              {newNotifications.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>
          ) : (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  New
                </ListSubheader>
              }
            >
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                No Notfication yet
              </ListSubheader>
            </List>
          )}

          {beforeThatNotifications.length > 0 && (
            <List
              disablePadding
              subheader={
                <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                  Before that
                </ListSubheader>
              }
            >
              {beforeThatNotifications.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>
          )}
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

function NotificationItem({ notification }) {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [message, setMessage] = useState(false);
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);
  const [isDelete, setIsDelete] = useState(false);
  const [openCheck, setOpenCheck] = useState(false);
  const [selectDelete, setSelectDelete] = useState(false);
  const navigate = useNavigate();

  const [deleteNotification, resultStats] = useDeleteNotificationMutation();

  const handleDeleteNotification = (id) => {
    setSelectDelete(id);
    setOpenCheck(true);
  };

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  useEffect(() => {
    if (resultStats?.isSuccess) {
      setType(true);
      setOpen(true);
      setMessage('Notification deleted successfully');
    } else if (resultStats?.isError) {
      const errorMessage = resultStats?.error?.data.message;
      setMessage(errorMessage);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  let avatar = null;
  if (notification.type === 'Booking') {
    // Add dummy avatar for booking notifications
    avatar = <Avatar src={`/assets/images/covers/cover_${Math.floor(Math.random() * 25) + 1}.jpg`} />;
  } else if (notification.type === 'welcome') {
    // Add dummy avatar for welcome notifications
    avatar = <Avatar src={`/assets/images/avatars/avatar_${Math.floor(Math.random() * 24) + 1}.jpg`} />;
  }

  const handleDeleteButtonClick = (e, notificationId) => {
    setIsDelete(true);
    handleDeleteNotification(notificationId);
  };

  return (
    <ListItemButton
      onClick={() => {
        setIsDelete(false);
        if (notification.type === 'Booking' && !isDelete) {
          navigate('/bookings');
        } else if (notification.type === 'Invoice Reminder' && !isDelete) {
          navigate('/');
        } else if (notification.type === 'Payment Overdue' && !isDelete) {
          navigate('/');
        }
      }}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <ListItemText
        primary={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {notification.type?.[0].toUpperCase() + notification.type.slice(1)}
            <IconButton
            color='secondary'

              onClick={(e) => {
                e.stopPropagation();
                handleDeleteButtonClick(e, notification.id);
              }}
            >
              <Iconify icon={'eva:trash-2-outline'} sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          </Box>
        }
        secondary={
          <>
            <Typography variant="body2">{notification.message}</Typography>

            <Typography
              variant="caption"
              sx={{
                mt: 0.5,
                display: 'flex',
                alignItems: 'center',
                color: 'text.disabled',
              }}
            >
              <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />

              {/* Material-UI Icon */}
              {new Date(notification.created_at).toLocaleString()}
            </Typography>
          </>
        }
      />

      <CustomAlert
        type={type ? 'success' : 'error'}
        message={message}
        open={open}
        autoHideDuration={5000}
        setOpen={setOpen}
      />

      {selectDelete && (
        <DeleteComponent
          handleCloseCheck={handleCloseCheck}
          openCheck={openCheck}
          name={'notification'}
          deleted={() => deleteNotification({ spaceId: spaceId || currentSpaceId, notificationId: selectDelete })}
        />
      )}
    </ListItemButton>
  );
}
