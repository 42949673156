import { Box, Container, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { formatDate } from '../utils/displayDateTime';

const ThanksPage = () => {
  const location = useLocation();
  const [meetRoom, setMeetRoom] = useState('');

  useEffect(() => {
    // Parse the tabValue from the query parameters and set the initial value accordingly
    const searchParams = new URLSearchParams(location.search);
    // eslint-disable-next-line camelcase
    const created_at = searchParams.get('created_at');
    setMeetRoom(created_at);
  }, [location.search]);

  const StyledContent = styled.div`
    background-color: white;
    border-radius: 12px;
    padding: 24px;
    //   min-height: 100vh;
    display: flex;
    margin: auto;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 600px) {
      width: 90%;
    }

    @media (min-width: 960px) {
      width: 800px;
    }
  `;

  return (
    <Container>
      <Box sx={{ height: '25px' }} />
      <StyledContent>
        <Stack justifyContent={'center'}>
          <Typography variant="h5" sx={{ textAlign: 'center', mb: 1 }}>
            Thank You for reserving in our space
          </Typography>

          <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
            We are waiting for you at&nbsp;
            {formatDate(dayjs.unix(meetRoom).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))}
          </Typography>
        </Stack>
      </StyledContent>
    </Container>
  );
};

export default ThanksPage;
