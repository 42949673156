/* eslint-disable camelcase */
import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Container,
  Select,
  MenuItem,
  Chip,
  Popover,
  Button,
  useMediaQuery,
  Link,
  Stack,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatDate, formatDateNotTime } from '../utils/displayDateTime';
import Loader from '../components/Loader/Loader';
import { useGetPackagesQuery, useUseGetDurationQuery } from '../Slices/spaceApi';

const PackagesPage = () => {
  const { spaceId, currentSpaceId, billingOption, currentBillingOption, name, currentName } = useSelector(
    (item) => item.spaceReducer
  );

  const { data: customDuration, isLoading: customeLoading } = useUseGetDurationQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const isXsScreen = useMediaQuery('(max-width:600px)'); // Check for extra small devices

  const { data, isLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });
  const [expiryFilter, setExpiryFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const [anchorEl, setAnchorEl] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);

  const handlePopoverOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setHoveredRow(row);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setHoveredRow(null);
  };

  if (isLoading) {
    return <Loader />;
  }

  const filteredRows = data?.assignedPackages.filter((row) => {
    const isMeetingPackage = row.meeting_packages.length > 0;
    const isRegularPackage = row.packages.length > 0;

    // Check if both meeting packages and regular packages are present, or apply the filter based on the selected type
    const showMeetingPackage = isMeetingPackage && (!typeFilter || typeFilter === 'meeting');
    const showRegularPackage = isRegularPackage && (!typeFilter || typeFilter === 'regular');

    // Display the row if it matches the selected filters
    return (
      (expiryFilter === '' || row.expiry_duration === expiryFilter) &&
      (statusFilter === '' ||
        (statusFilter === 'active'
          ? row.status === 1
          : statusFilter === 'pending'
          ? row.status === 2
          : row.status === 3)) &&
      (showMeetingPackage || showRegularPackage)
    );
  });


  return (
    <>
      <Helmet>
        <title> Packages | {name || currentName} </title>
      </Helmet>

      <Container>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 5 }}>
          <Typography variant="h4">Packages</Typography>
        </Box>

        <TableContainer component={Paper}>
          <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
            <Typography mr={1}>Filters</Typography>
            <Select
              value={expiryFilter}
              onChange={(e) => setExpiryFilter(e.target.value)}
              displayEmpty
              sx={{ marginRight: 2 }}
            >
              <MenuItem value="">All Expiry</MenuItem>
              {/* <MenuItem value="1">Daily</MenuItem>
              <MenuItem value="2">Weekly</MenuItem>
              <MenuItem value="3">Monthly</MenuItem> */}
              {customDuration?.spaceDurations?.map((item, idx) => (
                <MenuItem value={item?.id}>{item?.spaceDuration}</MenuItem>
              ))}
            </Select>
            <Select
              value={typeFilter}
              onChange={(e) => setTypeFilter(e.target.value)}
              displayEmpty
              sx={{ marginRight: 2 }}
            >
              <MenuItem value="">All Types</MenuItem>
              <MenuItem value="meeting">Meeting Packages</MenuItem>
              <MenuItem value="regular">Space Packages</MenuItem>
            </Select>
            <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)} displayEmpty>
              <MenuItem value="">All Status</MenuItem>
              <MenuItem value="active">Paid</MenuItem>
              <MenuItem value="pending">Unpaid</MenuItem>
              <MenuItem value="expired">Expired</MenuItem>
            </Select>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Recurring</TableCell>
                <TableCell>Invoice Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRows?.length > 0 ? (
                filteredRows?.map((row) => (
                  <TableRow
                    key={row.id}
                    hover
                    sx={{ cursor: row?.remaining_hours && (row?.status === 1 || row?.status === 2) ? 'pointer' : '' }}
                    onClick={(e) => handlePopoverOpen(e, row)}
                  >
                    <TableCell>
                      {row.meeting_packages.length > 0 || row.packages.length > 0 ? (
                        <>
                          {row.meeting_packages.map((item, index) => (
                            <React.Fragment key={item.id}>
                              {item.package?.name}
                              {index < row.meeting_packages.length - 1 && <br />}
                            </React.Fragment>
                          ))}
                          {row.meeting_packages.length > 0 && row.packages.length > 0 && <br />}
                          {row.packages.map((item, index) => (
                            <React.Fragment key={item.id}>
                              {item.package?.name}
                              {index < row.packages.length - 1 && <br />}
                            </React.Fragment>
                          ))}
                        </>
                      ) : (
                        'No Package Available'
                      )}
                    </TableCell>
                    <TableCell>
                      <Stack>
                        <Box>{formatDateNotTime(row.start_date)}</Box>
                        <Typography variant="subtitle2">{dayjs(row.start_date).format('hh:mm A')}</Typography>
                      </Stack>
                    </TableCell>

                    <TableCell>
                      <Stack>
                        <Box>{formatDateNotTime(row.renew_date)}</Box>
                        <Typography variant="subtitle2">{dayjs(row.renew_date).format('hh:mm A')}</Typography>
                      </Stack>
                    </TableCell>

                    <TableCell>
                      {customDuration?.spaceDurations.find((item) => item.id === row.expiry_duration)?.spaceDuration}
                    </TableCell>

                    <TableCell>
                      <Chip
                        label={`${row.status === 1 ? 'Paid' : row.status === 2 ? 'Unpaid' : 'Expired'}`}
                        color={row.status === 1 ? 'success' : row.status === 2 ? 'info' : 'error'}
                      />
                    </TableCell>

                    <TableCell>
                      <Chip
                        label={`${row.isRecurring ? 'Yes' : 'No'}`}
                        color={row.isRecurring ? 'success' : 'secondary'}
                      />
                    </TableCell>

                    <TableCell>
                      <Link
                        component={RouterLink}
                        color={'secondary'}
                        to={`/user/profile?tabValue=1&assignPackageId=${row.id}`}
                      >
                        Click here
                      </Link>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <Typography p={2}>No Package Available</Typography>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Popover
          open={Boolean(anchorEl) && (hoveredRow?.status === 1 || hoveredRow?.status === 2)}
          anchorEl={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: isXsScreen ? 'left' : 'center',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
        >
          {hoveredRow && (
            <Box p={2} sx={{ width: '250px' }}>
              {hoveredRow?.remaining_hours.map((meetingRoomData, index) => (
                <Box key={index}>
                  {meetingRoomData.meetingRoom.map((room, roomIndex) => (
                    <div key={roomIndex}>
                      <Typography variant="body1">{room.title}</Typography>
                    </div>
                  ))}
                  <Typography variant="body2">
                    {(+billingOption || +currentBillingOption) === 1 ? 'Hours' : 'Credits'}:&nbsp;
                    {meetingRoomData.hours}
                  </Typography>
                  <br />
                </Box>
              ))}
              <Button variant="contained" color="primary" onClick={handlePopoverClose}>
                Close
              </Button>
            </Box>
          )}
        </Popover>
      </Container>
    </>
  );
};

export default PackagesPage;
