import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useRoutes } from 'react-router-dom';
import { useEffect } from 'react';
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
import ForgotPswPage from './pages/ForgotPswPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import AccountPage from './pages/AccountPage';
import ProfilePage from './pages/ProfilePage';
import AccessPage from './pages/AccessPage';
import DashboardAppPage from './pages/DashboardAppPage';
import BookingPage from './pages/BookingPage';
import CommunitiesPage from './pages/CommunitiesPage';
import BookingEnquiryPage from './pages/BookingEnquiryPage';
import VisitorPage from './pages/VisitorPage';
import GetCookie from './utils/getCookie';
import RegisterVisitorPage from './pages/RegisterVisitorPage';
import AddVisitPage from './pages/AddVisitPage';
import TicketsPage from './pages/TicketsPage';
import AddTicketPage from './pages/AddTicketPage';
import PartnersPage from './pages/PartnersPage';
import PackagesPage from './pages/PackagesPage';
import CommunitySearchPage from './pages/CommunitySearchPage';
import TourPage from './pages/TourPage';
import TicketViewPage from './pages/TicketViewPage';
import PublicBookingEnquiryPage from './pages/PublicBookingEnquiryPage';
import ThanksPage from './pages/ThanksPage';
import CustomeProfilePage from './pages/CustomeProfilePage';
import InvoiceViewPage from './pages/InvoiceViewPage';
import ProductsPage from './pages/ProductsPage';
import PurchasePage from './pages/PurchasePage';
import EmployeePage from './pages/EmployeePage';
import SinglePurchasePage from './pages/SinglePurchasePage';

export default function Router() {
  const { currentCredentials, credentials } = useSelector((item) => item.spaceReducer);
  const Dispatch = useDispatch();
  const RequireAuth = ({ children }) => (GetCookie('token') ? children : <Navigate to="/login" />);

  const { hostname } = window.location;

  // console.log(hostname);

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;

  axios.defaults.headers.common.Credentials = credentials || currentCredentials;

  const routes = useRoutes([
    {
      path: '/',
      element: (
        <RequireAuth>
          <DashboardLayout />
        </RequireAuth>
      ),
      children: [
        {
          path: '/',
          element: <DashboardAppPage />,
        },
        {
          path: '/bookings',
          element: <BookingPage />,
        },
        {
          path: '/community-team',
          element: <CommunitiesPage />,
        },
        {
          path: '/visitors',
          element: <VisitorPage />,
        },
        {
          path: '/partners',
          element: <PartnersPage />,
        },
        { path: '/packages', element: <PackagesPage /> },
        { path: '/products', element: <ProductsPage /> },

        { path: '/user/profile', element: <ProfilePage /> },
        { path: '/profile/:id', element: <CustomeProfilePage /> },
        { path: '/support/tickets', element: <TicketsPage /> },
        { path: '/ticket/:id', element: <TicketViewPage /> },
        { path: '/support/add-ticket', element: <AddTicketPage /> },
        { path: '/user/account', element: <AccountPage /> },
        { path: '/invoice/:id', element: <InvoiceViewPage /> },
        {
          path: '/employee',
          element: <EmployeePage />,
        },
        {
          path: '/bookingEnquiry',
          element: <BookingEnquiryPage />,
        },
        {
          path: '/visitor/register',
          element: <RegisterVisitorPage />,
        },
        {
          path: '/visitor/visit-add',
          element: <AddVisitPage />,
        },
        {
          path: '/community-search',
          element: <CommunitySearchPage />,
        },
        { path: '/purchases', element: <PurchasePage /> },
        { path: '/purchase/:id', element: <SinglePurchasePage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgotPsw',
      element: <ForgotPswPage />,
    },
    {
      path: '/setupPassword/:token/',
      element: <AccessPage />,
    },
    { path: '/:companyName/tour', element: <TourPage /> },
    { path: '/:companyName/booking', element: <PublicBookingEnquiryPage /> },
    { path: '/booked', element: <ThanksPage /> },
    {
      element: <SimpleLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
