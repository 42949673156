import PropTypes from 'prop-types';
// @mui
import {
  Box,
  Radio,
  Stack,
  Button,
  Drawer,
  Divider,
  IconButton,
  Typography,
  Avatar,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
// components
import { useCallback, useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Iconify from '../iconify';
import Scrollbar from '../scrollbar';
import { timestampToDateTime } from '../../utils/timeStamptoDateTime';
import { calculateDuration } from '../../utils/getDuration';
import { CustomAlert } from '../../utils/Alert';
import VisitForm from './VisitForm';
import { useUpdateVisitMutation, useUpdateVisitorMutation } from '../../Slices/spaceApi';
import VisitorForm from './VisitorForm';
import { removeFieldsWithId } from '../../utils/removeIdFromFieldNames';
import { maxFileSize } from '../../utils/maxFileSize';

export default function ShopFilterSidebar({
  openFilter,
  onCloseFilter,
  editStates,
  setEditStates,
  name,
  companyName,
  image,
  email,
  // eslint-disable-next-line camelcase
  phone_number,
  id,
}) {
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageAdd, setMessageAdd] = useState('');
  const [hovered, setHovered] = useState(false);
  const [typeImage, setTypeImage] = useState('');
  const [openImage, setOpenImage] = useState(false);

  const [updateVisitor, resultStats] = useUpdateVisitorMutation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue,
  } = useForm();

  const onSubmit = (data) => {
    const newData = removeFieldsWithId(data, id);

    const convertFormData = (formData) => {
      const result = {
        name: formData.name,
        companyName: formData.companyName,
        email: formData.email,
        phone_number: formData.phone_number,
      };

      if (formData.image) {
        result.image = formData.image;
      }

      return result;
    };

    const convertedData = convertFormData(newData);

    updateVisitor({ spaceId: spaceId || currentSpaceId, data: convertedData, visitorId: id });
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setMessageAdd('Visitor updated successfully.');
      setType(true);
      setLoading(false);
      setOpen(true);
      onCloseFilter();
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setLoading(false);
      setOpen(true);
      setType(false);
    }
  }, [resultStats]);

  useEffect(() => {
    // Define an object with dynamic field names and corresponding values
    const fieldData = {
      [`name_${id}`]: name,
      [`companyName_${id}`]: companyName,
      // eslint-disable-next-line camelcase
      [`email_${id}`]: email,
      // eslint-disable-next-line camelcase
      [`phone_number_${id}`]: phone_number,
      [`image_${id}`]: image,
    };

    // Loop through the keys in fieldData and set values using setValue
    Object.keys(fieldData).forEach((fieldName) => {
      setValue(fieldName, fieldData[fieldName]);
    });
  }, [id]);

  return (
    <>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={onCloseFilter}
        PaperProps={{
          sx: { width: { xs: '98%', sm: '420px' }, border: 'none', overflow: 'hidden' },
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 1, py: 2 }}>
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            Update Visitor
          </Typography>
          <IconButton onClick={onCloseFilter} color='secondary'>
            <Iconify icon="eva:close-fill" />
          </IconButton>
        </Stack>
        <Divider />
        <Scrollbar>
          <VisitorForm
            errors={errors}
            id={id}
            loading={loading}
            register={register}
            trigger={trigger}
            watch={watch}
            control={control}
            isEdit
          />
        </Scrollbar>

        <Box sx={{ boxShadow: (theme) => theme.customShadows.dropdown, p: 2 }}>
          <Box>
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{ float: 'right' }}
              onClick={handleSubmit(onSubmit)}
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                Update Visitor
              </Stack>
            </LoadingButton>
          </Box>
        </Box>
      </Drawer>

      {<CustomAlert type={type ? 'success' : 'error'} message={messageAdd} open={open} setOpen={setOpen} />}
    </>
  );
}
