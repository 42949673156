/* eslint-disable camelcase */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DateTimePicker } from '@mui/lab';

// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
// import { AdapterDateFns } from '@mui/lab/AdapterDateFns';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import Loader from '../Loader/Loader';

import { useGetBookingsQuery, useGetPackagesQuery, useGetSlotsMutation } from '../../Slices/spaceApi';
import { formatTime } from '../../utils/formatTime';
import { getTimestamps } from '../../utils/convertDateTimeToTimeStamp';
import { CustomAlert } from '../../utils/Alert';
import Iconify from '../iconify';

function EnquiryForm({
  register,
  errors,
  watch,
  control,
  uniqueId,
  setValue,
  type,
  meetingRoomId,
  startDateTime,
  isEdit,
  start,
  end,
  publicRoom,
  weekdays: weekdaysData,
  trigger,
  hotDeskDate,
}) {
  const {
    spaceId,
    currentSpaceId,
    openDays,
    currentOpenDays,
    currentBookingConfiguration,
    bookingConfiguration,
    slotSize,
    currentSlotSize,
  } = useSelector((item) => item.spaceReducer);

  const { data, isLoading } = useGetPackagesQuery(
    { spaceId: spaceId || currentSpaceId },
    {
      skip: publicRoom,
    }
  );

  const { data: bookingData } = useGetBookingsQuery(
    {
      spaceId: spaceId || currentSpaceId,
      type: 1,
    },
    {
      skip: publicRoom,
    }
  );
  const formattedBookings = bookingData?.bookingss?.map((booking) => {
    const startTime = dayjs?.unix(booking.startTime);
    const endTime = dayjs?.unix(booking.endTime);

    // Calculate the duration in hours and minutes
    const durationHours = endTime.diff(startTime, 'hour');
    const durationMinutes = endTime.diff(startTime, 'minute') % 60;

    // Convert to the desired format for slot
    const slot = durationHours * 2 + (durationMinutes === 30 ? 1 : 0);

    // Format the start date
    const startDate = startTime.format('YYYY-MM-DD'); // You can adjust the format as needed

    // Return the formatted object
    return { startDate, slot };
  });
  const [typeAlert, setTypeAlert] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const [getSlots, resultStats] = useGetSlotsMutation();

  const dateRanges = [];

  data?.assignedPackages?.forEach((obj) => {
    const { status, renew_date, remaining_hours } = obj;

    // Check if the status is 1
    if (status === 1) {
      // Loop through each remaining_hours item
      remaining_hours.forEach((hours) => {
        // Loop through each meetingRoom
        hours.meetingRoom.forEach((room) => {
          const roomId = room.id;
          const roomData = { minDate: dayjs(), maxDate: dayjs(renew_date).subtract(1, 'day'), room_id: roomId };
          dateRanges.push(roomData);
        });
      });
    }
  });

  const filteredDateRanges = {};

  dateRanges.forEach((roomData) => {
    const { minDate, maxDate, room_id } = roomData;

    if (!filteredDateRanges[room_id] || maxDate > filteredDateRanges[room_id].maxDate) {
      filteredDateRanges[room_id] = { minDate, maxDate, room_id };
    }
  });

  const resultArray = Object.values(filteredDateRanges);

  // useEffect(() => {
  //   if (watch(`startDate_${uniqueId}`) && type !== 2) {
  //     getSlots({
  //       spaceId: publicRoom ? locationId : spaceId || currentSpaceId,
  //       data: { meetingRoom: meetingRoomId, date: watch(`startDate_${uniqueId}`).format('YYYY-MM-DD') },
  //     });
  //   }
  // }, [watch(`startDate_${uniqueId}`)]);

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [prevSlots, setPrevSlots] = useState([]);
  const [mergedSlots, setmergedSlots] = useState([]);

  const selectedDate = watch(`startDate_${uniqueId}`)?.format('YYYY-MM-DD');
  const selectedSlot = parseInt(bookingConfiguration || currentBookingConfiguration, 10);

  const mergedBookings = {};

  // Iterate through the bookings array
  formattedBookings?.forEach((booking) => {
    const { startDate, slot } = booking;

    // Check if the startDate is already in the mergedBookings
    if (mergedBookings[startDate]) {
      // If yes, add the slot value to the existing one
      mergedBookings[startDate].slot += slot;
    } else {
      // If no, create a new entry
      mergedBookings[startDate] = { startDate, slot };
    }
  });

  // Convert the mergedBookings object back to an array
  const mergedBookingsArray = Object.values(mergedBookings);

  const isSlotNotAvailable = mergedBookingsArray?.some(
    (item) => item.startDate === selectedDate && item.slot === selectedSlot
  );

  const isSlot = mergedBookingsArray?.find((item) => item.startDate === selectedDate);

  const handleSlotSelect = (slot) => {
    // if (
    //   isEdit &&
    //   parseInt(bookingConfiguration || currentBookingConfiguration, 10) === parseInt(selectedSlots?.length, 10)
    // ) {
    //   setTypeAlert(true);
    //   setOpen(true);
    //   setMessage(
    //     `No. of booked slots exceeded limit is only ${bookingConfiguration || currentBookingConfiguration} per day ${
    //       isSlot?.slot && !isEdit ? ` and you have already booked ${isSlot?.slot}.` : '.'
    //     }`
    //   );

    //   setSelectedSlots([slot]);
    //   return;
    // }

    // if (
    //   !isEdit &&
    //   parseInt(bookingConfiguration || currentBookingConfiguration, 10) ===
    //     parseInt(isSlot?.slot || 0, 10) + parseInt(selectedSlots?.length, 10)
    // ) {
    //   setTypeAlert(true);
    //   setOpen(true);
    //   setMessage(
    //     `No. of booked slots exceeded limit is only ${bookingConfiguration || currentBookingConfiguration} per day ${
    //       isSlot?.slot ? ` and you have already booked ${isSlot?.slot}.` : '.'
    //     }`
    //   );

    //   setSelectedSlots([slot]);
    //   return;
    // }

    const lastSelectedSlot = selectedSlots[selectedSlots.length - 1];

    // Check if the selected slot is consecutive to any existing selected slot
    const isConsecutive = selectedSlots?.some(
      (existingSlot) => existingSlot.end === slot.start || existingSlot.start === slot.end
    );

    if (isConsecutive) {
      setSelectedSlots([...selectedSlots, slot]);
    } else if (lastSelectedSlot) {
      // Show an alert if the selected slot is not consecutive
      setTypeAlert(true);
      setOpen(true);
      setMessage('Users can only select consecutive time slots.');

      // Reset selection and start a new consecutive selection
      setSelectedSlots([slot]);
    } else {
      // Initial selection, no need to show the alert
      setSelectedSlots([slot]);
    }
  };

  useEffect(() => {
    if (selectedSlots?.length > 0) {
      setValue(`slot_${uniqueId}`, selectedSlots);

      // Sort the selectedSlots array based on the start time
      const sortedSlots = selectedSlots.slice().sort((a, b) => (a.start > b.start ? 1 : -1));

      // Calculate timestamps for merged consecutive slots
      const mergedStart = sortedSlots[0]?.start;
      const mergedEnd = sortedSlots[sortedSlots.length - 1]?.end;

      const { startTimestamp, endTimestamp } = getTimestamps(
        watch(`startDate_${uniqueId}`).format('YYYY-MM-DD'),
        mergedStart,
        mergedEnd
      );

      // Set the calculated timestamps to the form values
      setValue(`startTimestamp_${uniqueId}`, startTimestamp);
      setValue(`endTimestamp_${uniqueId}`, endTimestamp);
    }
  }, [selectedSlots]);

  const [areDatesSame, setAreDatesSame] = useState(false);

  useEffect(() => {
    if (watch(`slot_${uniqueId}`)?.length > 0) {
      setPrevSlots(watch(`slot_${uniqueId}`));
      setSelectedSlots(watch(`slot_${uniqueId}`));
    }
  }, [uniqueId]);

  useEffect(() => {
    const date1 = new Date(startDateTime * 1000); // Convert seconds to milliseconds
    const date2String = watch(`startDate_${uniqueId}`)?.format('YYYY-MM-DD');
    const date2 = new Date(date2String); // Convert the date string to a Date object

    const check =
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate();

    setAreDatesSame(check);
  }, [watch(`startDate_${uniqueId}`)]);

  useEffect(() => {
    if (Array.isArray(resultStats?.data)) {
      const mergedSlots = areDatesSame
        ? [...prevSlots, ...resultStats?.data?.map((slot) => ({ ...slot }))]
        : resultStats?.data;

      const sortedTimeIntervals = mergedSlots.slice().sort((a, b) => {
        // Convert the time strings to Date objects for comparison
        const timeA = new Date(`1970-01-01T${a.start}`);
        const timeB = new Date(`1970-01-01T${b.start}`);

        return timeA - timeB;
      });

      setmergedSlots(sortedTimeIntervals);
    }
  }, [resultStats?.data]);

  const weekdays = publicRoom ? weekdaysData : openDays?.length > 0 ? openDays : JSON.parse(currentOpenDays);

  const isWeekdayDisabled = (date) => {
    const dayOfWeek = date.day();

    // Check if the day is not marked as "open" in the weekdays array
    const isDayOff = !weekdays?.some((day) => day.day === (dayOfWeek === 0 ? 7 : dayOfWeek));

    return isDayOff;
  };

  const currentDate = dayjs();

  const minDate = currentDate; // You can set a minimum date here

  const maxDate = null; // You can set a maximum date here

  const handleDateChange = (date) => {
    const formattedDate = dayjs(date);
    setValue(`startDate_${uniqueId}`, formattedDate);

    const currentEndTime = watch(`endTime_${uniqueId}`);
    const adjustedEndTime = formattedDate.add(slotSize || currentSlotSize, 'minute');

    // if (currentEndTime && dayjs(currentEndTime).isBefore(formattedDate)) {
    setValue(`endTime_${uniqueId}`, adjustedEndTime);
    // }
  };

  // const handleDateChange = (date) => {
  //   const formattedDate = dayjs(date);
  //   setValue(`startDate_${uniqueId}`, formattedDate);
  //   // setSelectedSlots([]);

  //   if (areDatesSame) {
  //     setValue(`slot_${uniqueId}`, ''); // Set initial start date
  //     setSelectedSlots([]);
  //   } else {
  //     setSelectedSlots([]);
  //     setValue(`slot_${uniqueId}`, '');
  //   }
  // };

  const handleEndDateChange = (date) => {
    const formattedDate = dayjs(date);
    setValue(`endTime_${uniqueId}`, formattedDate);
    setValue(`endTimestamp_${uniqueId}`, formattedDate?.unix());

    const currentStartDate = watch(`startDate_${uniqueId}`);
    const adjustedStartDate = formattedDate.subtract(slotSize || currentSlotSize, 'minute');

    if (currentStartDate && dayjs(currentStartDate).isAfter(formattedDate)) {
      setValue(`startDate_${uniqueId}`, adjustedStartDate);
    }
  };

  useEffect(() => {
    if (start) {
      const formattedStartDate = dayjs(start);

      const formattedEndDate = dayjs(start).add(slotSize || currentSlotSize, 'minute');

      const startTimeStamp = watch(`startDate_${uniqueId}`)?.unix();
      const endTimeStamp = watch(`endTime_${uniqueId}`)?.unix();
      setValue(`startTimestamp_${uniqueId}`, startTimeStamp);
      setValue(`endTimestamp_${uniqueId}`, endTimeStamp);

      setValue(`endTime_${uniqueId}`, formattedEndDate);
      setValue(`startDate_${uniqueId}`, formattedStartDate);
    }
  }, [start, end]);

  useEffect(() => {
    if (type === 2) {
      setValue(`startDate_${uniqueId}`, dayjs(hotDeskDate));
    }
  }, [uniqueId]);

  useEffect(() => {
    setValue(`visitors_${uniqueId}`, [{ name: '' }]);
  }, []);

  // useEffect(() => {
  //   if (type !== 2) {
  //     setValue(`startDate_${uniqueId}`, dayjs(hotDeskDate));
  //   }
  // }, [start,end]);

  const handleAddVisitor = () => {
    const visitors = watch(`visitors_${uniqueId}`);
    setValue(`visitors_${uniqueId}`, [...visitors, { name: '' }]);
  };

  const handleRemoveVisitor = (idx) => {
    const visitors = watch(`visitors_${uniqueId}`).filter((_, index) => index !== idx);
    setValue(`visitors_${uniqueId}`, visitors);
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setValue(`expand_${panel}`, isExpanded);
  };

  console.log(watch(`startDate_${uniqueId}`), watch(`endTime_${uniqueId}`), 'yayy');

  return (
    <Box>
      <Grid container columnSpacing={2} rowSpacing={2}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {publicRoom && (
              <>
                <Grid item xs={12}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    {...register('name', {
                      required: 'Name is required',
                    })}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    onBlur={() => trigger('name')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    variant="outlined"
                    fullWidth
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: 'Invalid email address',
                      },
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    onBlur={() => trigger('email')}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Controller
                    name="phone_number"
                    control={control}
                    rules={{
                      required: 'Phone Number is required',
                      pattern: {
                        value: /^[0-9]+$/,
                        message: 'Please enter only numeric digits.',
                      },
                      minLength: {
                        value: 11,
                        message: 'Phone number must have at least 11 characters',
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label="Phone Number"
                        {...field}
                        error={Boolean(errors.phone_number)}
                        helperText={errors.phone_number ? errors.phone_number.message : ''}
                        onBlur={() => trigger('phone_number')} // Trigger validation onBlur
                      />
                    )}
                  />
                </Grid>
              </>
            )}

            {/* <Grid item xs={12}>
              {watch(`startDate_${uniqueId}`) && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <Controller
                      name={`startDate_${uniqueId}`}
                      control={control}
                      rules={{ required: 'Start Date is required' }}
                      render={({ field }) => (
                        <DatePicker
                          sx={{ width: '100%' }}
                          {...field}
                          label="Select Start Date"
                          minDate={minDate}
                          maxDate={maxDate}
                          shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                          format="YYYY-MM-DD"
                          onChange={(date) => {
                            handleDateChange(date);
                          }}
                          error={!!errors[`startDate_${uniqueId}`]}
                          helperText={errors[`startDate_${uniqueId}`] && errors[`startDate_${uniqueId}`].message}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              )}
            </Grid> */}

            <Grid item xs={12}>
              {watch(`startDate_${uniqueId}`) && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <Controller
                      name={`startDate_${uniqueId}`}
                      control={control}
                      rules={{ required: 'Start Date is required' }}
                      render={({ field }) => (
                        <DateTimePicker
                          sx={{ width: '100%' }}
                          {...field}
                          label="Select Start Date"
                          minDate={minDate}
                          maxDate={maxDate}
                          shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                          // format="YYYY-MM-DDT00:00"
                          onChange={(date) => {
                            handleDateChange(date);
                          }}
                          error={!!errors[`startDate_${uniqueId}`]}
                          helperText={errors[`startDate_${uniqueId}`] && errors[`startDate_${uniqueId}`].message}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              )}
            </Grid>

            <Grid item xs={12}>
              {type !== 2 && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['TimePicker']}>
                    <Controller
                      name={`endTime_${uniqueId}`}
                      control={control}
                      rules={{ required: 'End Time is required' }}
                      render={({ field }) => (
                        <TimePicker
                          sx={{ width: '100%' }}
                          {...field}
                          label="Select End Time"
                          // minDate={minDate}
                          // shouldDisableTime={(timeValue, clockType) => {
                          //   const endTime = dayjs(timeValue);
                          //   return endTime.isAfter(dayjs(start).endOf('day').subtract(1, 'minute'));
                          // }}
                          // maxDate={maxDate}
                          shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                          // format="YYYY-MM-DDT00:00"
                          onChange={(date) => {
                            handleEndDateChange(date);
                          }}
                          error={!!errors[`endTime_${uniqueId}`]}
                          helperText={errors[`endTime_${uniqueId}`] && errors[`endTime_${uniqueId}`].message}
                        />
                      )}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              )}
            </Grid>

            {!publicRoom && !isEdit && type === 1 && (
              <Grid item xs={12}>
                <Accordion expanded={watch(`expanded_${uniqueId}`)} onChange={handleAccordionChange(uniqueId)}>
                  <AccordionSummary
                    expandIcon={<Iconify icon="ooui:expand" sx={{ width: '12px', height: '12px' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{ backgroundColor: (theme) => theme.palette.grey[200] }}
                  >
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography>Guests</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ mt: 1, px: 0.5 }}>
                    <Stack spacing={2}>
                      {watch(`visitors_${uniqueId}`)?.map((visitor, idx) => (
                        <Stack key={idx} justifyContent="space-between" flexDirection="row" alignItems="flex-start">
                          <TextField
                            id={`name_${idx}`}
                            label={`Visitor Name ${idx + 1}`}
                            size="small"
                            value={visitor.name}
                            {...register(`visitors_${uniqueId}.${idx}.name`, {
                              required: watch(`expand_${uniqueId}`) ? `Visitor name ${idx + 1} is required` : false,
                            })}
                            error={!!errors?.[`visitors_${uniqueId}`]?.[idx]?.name}
                            helperText={errors?.[`visitors_${uniqueId}`]?.[idx]?.name?.message || ''}
                            variant="outlined"
                            fullWidth
                            onBlur={() => trigger(`visitors_${uniqueId}.${idx}.name`)}
                            onChange={(e) => {
                              const updatedVisitors = [...watch(`visitors_${uniqueId}`)];
                              updatedVisitors[idx].name = e.target.value;
                              setValue(`visitors_${uniqueId}`, updatedVisitors);
                            }}
                          />

                          {idx !== 0 && (
                            <IconButton
                              sx={{ ml: 1 }}
                              color="secondary"
                              size="small"
                              onClick={() => handleRemoveVisitor(idx)}
                            >
                              <Iconify icon="eva:close-fill" />
                            </IconButton>
                          )}

                          {idx === watch(`visitors_${uniqueId}`).length - 1 && (
                            <IconButton sx={{ ml: 1 }} color="secondary" size="small" onClick={handleAddVisitor}>
                              <Iconify icon="material-symbols:add" />
                            </IconButton>
                          )}
                        </Stack>
                      ))}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              </Grid>
            )}

            {/* {isSlotNotAvailable && !isEdit && type === 1 && (
              <Grid item xs={12}>
                You have reached maximum booking limit for today. Please contact with you administrator.
              </Grid>
            )} */}
            {/* (!isSlotNotAvailable || isEdit) */}
            {/* Foloowing Code is committed for slots */}

            {/* {watch(`startDate_${uniqueId}`) && type === 1 && (
              <Grid item xs={12}>
                {setPrevSlots && areDatesSame}
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                  {resultStats?.isLoading ? (
                    <Grid item xs={12}>
                      Loading...
                    </Grid>
                  ) : resultStats?.isError ? (
                    <Grid item xs={12}>
                      {resultStats?.error?.data.message || resultStats?.error?.data.error}
                    </Grid>
                  ) : mergedSlots?.length > 0 ? (
                    mergedSlots?.map((slot, index) => (
                      <Chip
                        key={index}
                        label={`${formatTime(slot.start)} - ${formatTime(slot.end)}`}
                        variant={
                          selectedSlots?.some((selectedSlot) => selectedSlot.start === slot.start)
                            ? 'filled'
                            : 'outlined'
                        }
                        onClick={() => handleSlotSelect(slot)}
                        {...register(`slot_${uniqueId}`, {
                          required: 'Slot is required',
                        })}
                      />
                    ))
                  ) : (
                    <Grid item xs={12}>
                      There's no time slot to book this room
                    </Grid>
                  )}

                  {!watch(`slot_${uniqueId}`) && (
                    <Typography color={'#FF4842'}>{errors?.[`slot_${uniqueId}`]?.message}</Typography>
                  )}
                </div>
              </Grid>
            )} */}
          </>
        )}
      </Grid>

      {<CustomAlert type={typeAlert ? 'warning' : 'error'} message={message} open={open} setOpen={setOpen} />}
    </Box>
  );
}

export default EnquiryForm;
