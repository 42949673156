import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import { Box, Stack, TextField } from '@mui/material';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { useGetDiscountsQuery, useGetPackagesQuery } from '../../Slices/spaceApi';
import { updateSlot } from '../../utils/parseDateTime';
import Iconify from '../iconify/Iconify';
import { timeStampCalculateDuration, timestampToDateTime } from '../../utils/timeStamptoDateTime';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export default function CustomizedDialogs({
  handleClose,
  open,
  onClick,
  loading,
  name,
  price,
  spaceType,
  capacity,
  register,
  startDate,
  endDate,
  start,
  end,
  type,
  roomId,
  publicRoom,
  weekdays,
  meetingRoomCurrency,
  errors,
  trigger,
  uniqueId,
  watch,
  setValue,
  locationId,
  currentGlobalTaxRateObj,
  globalTaxRateObj,
  inclusiveTax,
  publicBillingOption,
  publicCreditPriceInclusiveTax,
}) {
  const {
    billingOption,
    currentBillingOption,
    openDays,
    currentOpenDays,
    spaceId,
    currentSpaceId,
    currency,
    currentCurrency,
    creditPriceInclusiveTax,
    currentCreditPriceInclusiveTax,
    slotSize,
    currentSlotSize,
  } = useSelector((item) => item.spaceReducer);
  console.log(startDate?.unix(), endDate?.unix() , "check?");

  const { companyName } = useParams();

  const dayHoursArray = publicRoom ? weekdays : openDays?.length > 0 ? openDays : JSON.parse(currentOpenDays);

  const { data: packages } = useGetPackagesQuery(
    { spaceId: spaceId || currentSpaceId },
    {
      skip: publicRoom,
    }
  );

  // Mapping of numeric day values to day names
  const dayMap = {
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
    7: 'Sunday',
  };

  const dayHoursWithNames = dayHoursArray?.map((item) => ({
    day: dayMap[item.day],
    hours: item.hours,
  }));

  const dateRanges = [];

  packages?.assignedPackages?.forEach((obj) => {
    // eslint-disable-next-line camelcase
    const { status, renew_date, remaining_hours } = obj;

    // Check if the status is 1
    if (status === 1) {
      // Loop through each remaining_hours item
      // eslint-disable-next-line camelcase
      remaining_hours.forEach((hours) => {
        // Loop through each meetingRoom
        hours.meetingRoom.forEach((room) => {
          const roomId = room.id;
          const roomData = {
            minDate: dayjs(),
            maxDate: dayjs(renew_date).subtract(1, 'day'),
            room_id: roomId,
            hours: hours?.hours,
          };
          dateRanges.push(roomData);
        });
      });
    }
  });

  const filteredDateRanges = {};

  dateRanges.forEach((roomData) => {
    // eslint-disable-next-line camelcase
    const { minDate, maxDate, room_id, hours } = roomData;

    // eslint-disable-next-line camelcase
    if (!filteredDateRanges[room_id] || maxDate > filteredDateRanges[room_id].maxDate) {
      // eslint-disable-next-line camelcase
      filteredDateRanges[room_id] = { minDate, maxDate, room_id, hours };
    }
  });

  const resultArray = Object.values(filteredDateRanges);

  const { data, isLoading, isError, isSuccess, error, refetch, isFetching } = useGetDiscountsQuery(
    {
      spaceId: publicRoom ? locationId : spaceId || currentSpaceId,
      code: watch(`discount_${uniqueId}`),
    },
    {
      skip: !watch(`discount_${uniqueId}`),
    }
  );

  React.useEffect(() => {
    if (watch(`discount_${uniqueId}`)?.length > 0 && (publicRoom ? locationId : spaceId || currentSpaceId)) {
      refetch();
    }
  }, [watch(`discount_${uniqueId}`)]);

  React.useEffect(() => {
    if (isError) {
      setValue(`activeCode_${uniqueId}`, '');
    } else if (
      (companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))) &&
      isSuccess &&
      data?.validOnBookings?.includes(spaceType) &&
      new Date(data.validTill) > new Date()
    ) {
      if (publicRoom && data?.allowedToNonRegisteredMember && watch(`discount_${uniqueId}`) === data?.code) {
        setValue(`activeCode_${uniqueId}`, watch(`discount_${uniqueId}`));
      } else if (data?.allowedToRegisteredMember && !publicRoom && watch(`discount_${uniqueId}`) === data?.code) {
        setValue(`activeCode_${uniqueId}`, watch(`discount_${uniqueId}`));
      }
    } else {
      setValue(`activeCode_${uniqueId}`, '');
    }
  }, [isFetching]);

  const checkTaxFlag =
    (+billingOption || +currentBillingOption) === 1
      ? !inclusiveTax
      : publicRoom && publicBillingOption === 1
      ? !inclusiveTax
      : publicRoom && publicBillingOption === 2
      ? !publicCreditPriceInclusiveTax
      : creditPriceInclusiveTax
      ? !creditPriceInclusiveTax
      : !currentCreditPriceInclusiveTax;

  const calculateTax = () => {
    let totalTax = 0;

    if (
      globalTaxRateObj?.setOverallTax ||
      (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax)
    ) {
      // Calculate tax for service charges
      const serviceChargesTax =
        globalTaxRateObj?.serviceCharges ||
        (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges) ||
        0;
      totalTax += serviceChargesTax;
    } else {
      // Calculate tax for rental fee
      const rentalFeeTax =
        globalTaxRateObj?.rentalFee || (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee) || 0;
      totalTax += rentalFeeTax;

      // Calculate tax for service charges
      const serviceChargesTax =
        globalTaxRateObj?.serviceCharges ||
        (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges) ||
        0;
      totalTax += serviceChargesTax;
    }

    return totalTax;
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        PaperProps={{
          sx: {
            minWidth: {
              sm: '370px', // Set minimum width for sm and larger screens
              xs: '90%', // Set minimum width for xs screens
            },
          },
        }}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Booking Summary
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          color="secondary"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <Iconify icon="eva:close-fill" />
        </IconButton>
        <DialogContent dividers>
          <Typography
            component={'div'}
            gutterBottom
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            {name}
            <Box display={'flex'} alignItems={'center'}>
              <Iconify icon="tdesign:member" sx={{ width: 17, mr: 1 }} />
              <Typography>{capacity}</Typography>
            </Box>
          </Typography>

          <Typography
            component={'div'}
            gutterBottom
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant="subtitle1">Booking Starts &nbsp;</Typography>
            {type === 2
              ? startDate?.format
                ? startDate.format('YYYY-MM-DD')
                : startDate
              : timestampToDateTime(startDate?.unix())}
          </Typography>

          <Typography
            component={'div'}
            gutterBottom
            display={'flex'}
            alignItems={'center'}
            justifyContent={'space-between'}
          >
            <Typography variant="subtitle1">Duration &nbsp;</Typography>
            {type === 2
              ? startDate?.format
                ? dayHoursWithNames.find((item) => item.day === startDate.format('dddd'))?.hours
                : startDate
              : timeStampCalculateDuration(startDate?.unix(), endDate?.unix())}
            {/* start:{startDate?.unix()}
            end:{endDate?.unix()} */}
          </Typography>

          {type === 1 && (
            <Typography
              component={'div'}
              gutterBottom
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
              sx={{
                display:
                  (companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))) &&
                  updateSlot(startDate?.unix(), endDate?.unix()).length * price <= 0
                    ? 'none'
                    : 'flex',
              }}
            >
              <Typography variant="subtitle1">
                {companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))
                  ? 'Price'
                  : (+billingOption || +currentBillingOption) === 1
                  ? `Hours`
                  : `Credits`}
              </Typography>

              {companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))
                ? `${updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price}  ${
                    publicRoom ? meetingRoomCurrency : currency || currentCurrency
                  } `
                : (+billingOption || +currentBillingOption) === 1
                ? `Required  ${updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length / 2}`
                : `Required  ${
                    updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price
                  } `}
            </Typography>
          )}

          {checkTaxFlag && (
            <>
              {type === 1 && (companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))) && (
                <>
                  {globalTaxRateObj?.setOverallTax ||
                  (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax) ? (
                    <Stack
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      sx={{
                        display:
                          (globalTaxRateObj?.serviceCharges ||
                            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) <= 0
                            ? 'none'
                            : '',
                      }}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        Tax
                      </Typography>
                      <Typography gutterBottom color={'error'}>
                        {globalTaxRateObj?.serviceCharges ||
                          (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)}{' '}
                        %
                      </Typography>
                    </Stack>
                  ) : (
                    <>
                      <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        gutterBottom
                        sx={{
                          display:
                            (globalTaxRateObj?.rentalFee ||
                              (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)) <= 0
                              ? 'none'
                              : '',
                        }}
                      >
                        <Typography variant="subtitle1" gutterBottom>
                          Rent Fee
                        </Typography>
                        <Typography gutterBottom color={'error'}>
                          {globalTaxRateObj?.rentalFee ||
                            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)}
                          %
                        </Typography>
                      </Stack>
                      <Stack
                        flexDirection={'row'}
                        justifyContent={'space-between'}
                        gutterBottom
                        sx={{
                          display:
                            (globalTaxRateObj?.serviceCharges ||
                              (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) <= 0
                              ? 'none'
                              : '',
                        }}
                      >
                        <Typography variant="subtitle1" gutterBottom>
                          Service Charges
                        </Typography>
                        <Typography gutterBottom color={'error'}>
                          {globalTaxRateObj?.serviceCharges ||
                            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)}{' '}
                          %
                        </Typography>
                      </Stack>
                    </>
                  )}
                </>
              )}
            </>
          )}

          {isSuccess &&
            data?.validOnBookings?.includes(spaceType) &&
            new Date(data.validTill) > new Date() &&
            (companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))) && (
              <Typography
                component={'div'}
                gutterBottom
                display={'flex'}
                alignItems={'center'}
                justifyContent={'space-between'}
              >
                <Typography variant="subtitle1">Discount</Typography>
                {data?.fixedAmount
                  ? `${data?.amount} ${currency || currentCurrency || meetingRoomCurrency}`
                  : `${data?.amount} %`}
              </Typography>
            )}

          {(companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))) &&
          isSuccess &&
          data?.validOnBookings?.includes(spaceType) &&
          new Date(data.validTill) > new Date() ? (
            <Typography
              component={'div'}
              gutterBottom
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="subtitle1">Total Price</Typography>

              {data?.fixedAmount
                ? `${Math.max(
                    (checkTaxFlag
                      ? globalTaxRateObj?.setOverallTax ||
                        (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax)
                        ? updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length *
                          price *
                          ((globalTaxRateObj?.serviceCharges ||
                            (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) /
                            100)
                        : (updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length *
                            price *
                            ((globalTaxRateObj?.rentalFee ||
                              (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)) +
                              (globalTaxRateObj?.serviceCharges ||
                                (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)))) /
                          100
                      : 0) +
                      (data?.amount >
                      updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price
                        ? 0
                        : updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price -
                          data?.amount),
                    0
                  ).toFixed(2)} ${publicRoom ? meetingRoomCurrency : currency || currentCurrency}`
                : `${(
                    (checkTaxFlag
                      ? globalTaxRateObj?.setOverallTax ||
                        (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax)
                        ? updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price +
                          updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length *
                            price *
                            ((globalTaxRateObj?.serviceCharges ||
                              (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) /
                              100)
                        : updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price +
                          (updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length *
                            price *
                            ((globalTaxRateObj?.rentalFee ||
                              (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)) +
                              (globalTaxRateObj?.serviceCharges ||
                                (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)))) /
                            100
                      : updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price) -
                    (updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length *
                      price *
                      data?.amount) /
                      100
                  ).toFixed(2)} ${publicRoom ? meetingRoomCurrency : currency || currentCurrency}`}
            </Typography>
          ) : calculateTax() > 0 &&
            checkTaxFlag &&
            (companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))) &&
            type === 1 ? (
            <Typography
              component={'div'}
              gutterBottom
              display={'flex'}
              alignItems={'center'}
              justifyContent={'space-between'}
            >
              <Typography variant="subtitle1">Total Price</Typography>
              {(checkTaxFlag
                ? globalTaxRateObj?.setOverallTax ||
                  (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.setOverallTax)
                  ? updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length *
                    price *
                    ((globalTaxRateObj?.serviceCharges ||
                      (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)) /
                      100)
                  : (updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length *
                      price *
                      ((globalTaxRateObj?.rentalFee ||
                        (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.rentalFee)) +
                        (globalTaxRateObj?.serviceCharges ||
                          (currentGlobalTaxRateObj && JSON.parse(currentGlobalTaxRateObj)?.serviceCharges)))) /
                    100
                : 0) +
                updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price}{' '}
              {publicRoom ? meetingRoomCurrency : currency || currentCurrency}
            </Typography>
          ) : null}

          {type === 1 && (companyName || !resultArray?.find((room) => room.room_id === parseInt(roomId, 10))) && (
            <TextField
              sx={{
                display:
                  updateSlot(startDate?.unix(), endDate?.unix(), slotSize, currentSlotSize).length * price <= 0
                    ? 'none'
                    : '',
              }}
              label="Coupon Code (optional)"
              variant="filled"
              fullWidth
              {...register(`discount_${uniqueId}`)}
              error={!!errors?.[`discount_${uniqueId}`]}
              helperText={errors?.[`discount_${uniqueId}`]?.message}
              onBlur={() => trigger(`discount_${uniqueId}`)}
            />
          )}

          {watch(`discount_${uniqueId}`) && (
            <Box mt={0.5}>
              {isLoading ? (
                <Typography>Loading..</Typography>
              ) : isError ? (
                <Typography sx={{ color: 'error.main' }}>{error?.data?.error || error?.data?.message}</Typography>
              ) : (
                <>
                  {data?.validOnBookings?.includes(spaceType) && new Date(data.validTill) > new Date() ? (
                    <Typography>
                      {publicRoom
                        ? !data?.allowedToNonRegisteredMember && "This coupon code isn't for non registered members"
                        : !data?.allowedToRegisteredMember && "This coupon code isn't for  registered members"}
                    </Typography>
                  ) : new Date(data.validTill) < new Date() ? (
                    <Typography sx={{ color: 'error.main' }}>Discount has expired</Typography>
                  ) : (
                    <Typography sx={{ color: 'error.main' }}>
                      This coupon does not offer a discount for this booking
                    </Typography>
                  )}
                </>
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={loading} onClick={onClick} variant="contained" fullWidth>
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
              Confirm
            </Stack>
          </LoadingButton>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}
