import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { SPACEBILLINGOPTIONS, SPACECREDS, SPACEID, SPACEUSERID } from '../../../Slices/spaceSlice';
import SetCookie from '../../../utils/setCookie';
import Iconify from '../../../components/iconify';
import { CustomAlert } from '../../../utils/Alert';

export default function LoginForm() {
  const Dispatch = useDispatch();
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger, // Import the trigger function
  } = useForm();

  const [loading, setLoading] = useState(false);

  const [type, setType] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = useNavigate();

  const handleLoginPasswordClick = () => {
    // Navigate to the "/forgotPsw" route
    navigate('/login');
  };

  const onSubmit = async (data) => {
    setLoading(true);
    // Manually trigger validation for all fields before submitting
    const isFormValid = await trigger();

    if (isFormValid) {
      axios
        .post('/reset-password/v2/', data)
        .then((response) => {
          setType(true);
          setOpen(true);
          setMessage('Email sent successfully.');
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setType(false);
          setOpen(true);
          setMessage(error.response.data.message || error.response.data.error);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <>
      <Stack spacing={3}>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{ required: 'Email is required', pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
          render={({ field }) => (
            <TextField
              label="Email address"
              {...field}
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.message : ''}
              onKeyPress={handleKeyPress}
              onBlur={() => trigger('email')} // Trigger validation onBlur
            />
          )}
        />
      </Stack>

      <LoadingButton
        sx={{ my: 2 }}
        loading={loading}
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={handleSubmit(onSubmit)}
      >
        Send Request
      </LoadingButton>

      <Box>
        <Link
          color={'secondary'}
          variant="body1"
          underline="hover"
          // sx={{ float: 'right' }}
          component="button" // Use "button" instead of "routerLink"
          onClick={handleLoginPasswordClick}
        >
          Return to sign in?
        </Link>
        {/* <Typography
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
        >
          Return to sign in?
        </Typography> */}
      </Box>
      <CustomAlert type={type ? 'success' : 'error'} message={message} open={open} setOpen={setOpen} />
    </>
  );
}
