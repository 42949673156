import dayjs from "dayjs";

export function timestampToDateTime(timestamp) {
  const date = dayjs.unix(timestamp);

  const formattedDateTime = date.format('dddd, MMMM D, YYYY h:mm A');

  return formattedDateTime;
}

export function timestampToISODate(timestamp) {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function timeStampCalculateDuration(startTime, endTime) {
  // Helper function to convert timestamp to HH:mm format
  const formatTime = (timestamp) => {
    if (timestamp) {
      const date = new Date(timestamp * 1000);
      return date.toISOString().substr(11, 5); // Get HH:mm from ISO string
    }
    return '';
  };

  // Convert timestamps to HH:mm format
  const startFormatted = formatTime(startTime);
  const endFormatted = formatTime(endTime);

  if (!startFormatted || !endFormatted) {
    return ''; // If either start or end time is missing, return an empty string
  }

  // Helper function to convert HH:mm format to minutes since midnight
  const parseTime = (timeString) => {
    const [hour, minute] = timeString.split(':').map((num) => parseInt(num, 10));
    return hour * 60 + minute;
  };

  // Convert formatted times to minutes since midnight
  const startMinutes = parseTime(startFormatted);
  const endMinutes = parseTime(endFormatted);

  // Calculate duration in minutes
  let durationMinutes = endMinutes - startMinutes;
  if (durationMinutes < 0) {
    durationMinutes += 24 * 60; // Add 24 hours if end time is earlier than start time
  }

  // Calculate hours and remaining minutes
  const hours = Math.floor(durationMinutes / 60);
  const minutes = durationMinutes % 60;

  // Format the result
  return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`;
}
