import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  Stack,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { LoadingButton } from '@mui/lab';
import { useLocation, useParams } from 'react-router-dom';
import SetCookie from '../utils/setCookie';
import { SPACECREDS } from '../Slices/spaceSlice';
import Loader from '../components/Loader/Loader';
import { CustomAlert } from '../utils/Alert';
import {
  useGetBlocksUsersQuery,
  useGetInterestsQuery,
  useGetSkillsQuery,
  useGetUserDetailsQuery,
  useUpdateUserDetailsMutation,
} from '../Slices/spaceApi';
import Form from '../components/Account/Form';
import Iconify from '../components/iconify/Iconify';
import { maxFileSize } from '../utils/maxFileSize';

function EditPage() {
  const { currentUserId, userId, spaceId, currentSpaceId, name, currentName } = useSelector(
    (item) => item.spaceReducer
  );

  const location = useLocation();

  const Dispatch = useDispatch();

  const {
    data: blockUsersData,
    isLoading: blockUsersLoading,
    refetch,
  } = useGetBlocksUsersQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [type, setType] = useState('password');

  const [typeUser, setTypeUser] = useState('');
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [messageUser, setMessageUser] = useState('');

  const [typeImage, setTypeImage] = useState('');
  const [openImage, setOpenImage] = useState(false);

  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (blockUsersData) {
      setUsers(blockUsersData?.blocks?.[0]?.blocked?.map((user) => ({ ...user, blocked: true })));
    }
  }, [blockUsersData]);

  const handleToggleBlock = (userId) => {
    try {
      axios
        .get(`/space/${spaceId || currentSpaceId}/block/${userId}/unblock/`)
        .then((response) => {
          if (response.status === 200) {
            refetch();
          } else {
            // Handle other status codes if needed
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });

      const updatedUsers = users.map((user) => {
        if (user.userId === userId) {
          return { ...user, blocked: !user.blocked };
        }
        return user;
      });

      setUsers(updatedUsers);
    } catch (error) {
      /* empty */
    }
  };

  const [updateUserDetails, resultStats] = useUpdateUserDetailsMutation();

  const { data, isLoading } = useGetUserDetailsQuery(userId || currentUserId);

  const [skills, setSkills] = useState(null);

  const [interests, setInterests] = useState(null);
  const [imageMessage, setImageMessage] = useState('');

  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
    setValue: setVALUES,
  } = useForm();

  const {
    handleSubmit: handleSubmitSocial,
    formState: { errors: errorsSocial },
    register: registerSocial,
    trigger: triggerSocial,
    watch: watchSocial,
    control,
    setValue: setVALUESSocial,
  } = useForm();

  const {
    handleSubmit: handleSubmitPassword,
    formState: { errors: errorsPassword },
    register: registerPassword,
    trigger: triggerPassword,
    watch: watchPassword,
    setValue: setVALUESPassword,
  } = useForm();

  const handleType = () => {
    setType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const { data: interestData, isLoading: interestsLoading } = useGetInterestsQuery();
  const { data: skillsData, isLoading: skillsLoading } = useGetSkillsQuery();

  // const interestData = ['Cricket', 'Football'];
  // const skillsData = ['Python', 'Javascript'];

  useEffect(() => {
    const backendData = {
      name: data?.name,
      profileImg: data?.profileImg,
      description: data?.description,
      phoneNo: data?.phoneNo,
      address: data?.address,
      title: data?.title,
    };

    setInterests(data?.interests);
    setSkills(data?.skills);

    Object.keys(backendData).forEach((key) => {
      setVALUES(key, backendData[key]);
    });
  }, [data, isLoading]);

  useEffect(() => {
    const convertedData = {
      profileUrls:
        data?.profileUrls.length > 0
          ? data?.profileUrls?.map((item) => {
              const socialMedia = Object.keys(item)[0];
              const { url, public: isPublic } = item[socialMedia];
              return {
                url,
                public: isPublic,
                socialMedia,
              };
            })
          : [
              {
                url: '',
                public: false,
                socialMedia: 'Twitter',
              },
              {
                url: '',
                public: false,
                socialMedia: 'LinkedIn',
              },
              {
                url: '',
                public: false,
                socialMedia: 'Facebook',
              },
              {
                url: '',
                public: false,
                socialMedia: 'Instagram',
              },
            ],
    };

    Object.keys(convertedData).forEach((key) => {
      setVALUESSocial(key, convertedData[key]);
    });
  }, [data, isLoading]);

  const onSubmit = (data) => {
    if (!(data?.file instanceof File) && data?.profileImg === '') {
      return;
    }

    delete data.file;

    if (data.profileImg && data.profileImg.startsWith('https')) {
      delete data.profileImg;
    }

    updateUserDetails({ userId: userId || currentUserId, data: { ...data, skills, interests } });
  };

  const onSubmitPassword = async (data) => {
    setLoading(true);
    delete data.confirmNewPassword;
    try {
      const response = await axios.post('/change-password/', data); // Assuming you are using async/await
      setTypeUser(true);
      setLoading(false);
      setOpen(true);
      setMessageUser(response?.data?.error || 'Password changed successfully.');

      setTimeout(() => {
        axios.defaults.headers.common.Credentials = response?.data?.Credentials;

        SetCookie('creds-member', response?.data?.Credentials);
        Dispatch(SPACECREDS(response?.data?.Credentials));
      }, 1000);
    } catch (error) {
      setLoading(false);
      setTypeUser(false);
      setOpen(true);
      setMessageUser(error?.response?.data?.error || 'Failed to change.');
    }
  };

  const onSubmitSocial = (data) => {
    updateUserDetails({ userId: userId || currentUserId, data: { ...data } });
  };

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setTypeUser(true);
      setLoading(false);
      setOpen(true);
      setMessageUser('Updated successfully.');
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageUser(errorMessage);
      setLoading(false);
      setOpen(true);
      setTypeUser(false);
    }
  }, [resultStats]);

  useEffect(() => {
    const selectedFile = watch('file');

    console.warn(selectedFile);
    if (selectedFile) {
      // Check file format and size
      if (errors.file) {
        setLoading(false);
        setVALUES('file', ''); // Clear the file input
        setOpenImage(true);
        setTypeImage(false);
      } else if (
        selectedFile.type &&
        (selectedFile.type.includes('image/jpeg') ||
          selectedFile.type.includes('image/jpg') ||
          selectedFile.type.includes('image/png') ||
          selectedFile.type.includes('image/webp'))
      ) {
        const fd = new FormData();
        fd.append('image', selectedFile); // Append the selected file to the FormData
        console.warn(selectedFile, 'fd');
        axios
          .post('/uploadImage/', fd)
          .then((resp) => {
            setImageMessage('Image uploaded sucessfully.');
            setLoading(false);
            setOpenImage(true);
            setTypeImage(true);
            setVALUES('profileImg', resp.data?.[0]); // Update the 'file' field value
          })
          .catch((err) => {
            setImageMessage('Failed to upload an image.');
            setLoading(false);
            setOpenImage(false);
            setTypeImage(false);
          });
      } else {
        setImageMessage('Invalid image format. Please upload a valid JPEG, JPG, PNG, or WebP file.');
        // Invalid file type
        setVALUES('file', ''); // Clear the file input
        setOpenImage(true);
        setLoading(false);
        setTypeImage(false);
      }
    }
  }, [watch('file')]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setLoading(true);
      if (selectedFile.size > maxFileSize) {
        setImageMessage('File size exceeds the limit (3.1 MB).');
      } else {
        setVALUES(`file`, selectedFile);
      }
    }
  };

  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))({
    '& .MuiTabs-indicatorSpan': {},
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    '&.Mui-focusVisible': {},
  }));

  const [value, setValue] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const tabValue = searchParams.get('tabValue');
    setValue(+tabValue);
  }, [location.search]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [valueSecurity, setValueSecurity] = useState(0);

  const handleChangeSecurity = (event, newValue) => {
    setValueSecurity(newValue);
  };

  const urlPatterns = {
    Twitter: /^(https?:\/\/)?(www\.)?twitter\.com\/[a-zA-Z0-9_]+\/?$/,
    LinkedIn: /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/,
    Facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/[a-zA-Z0-9.-]+\/?$/,
    Instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/[a-zA-Z0-9_.]+\/?$/,
  };

  const openInput = () => {
    document.getElementById('input-photo').click(); // Trigger the file input when the image container is clicked
  };

  if (isLoading || blockUsersLoading || interestsLoading || skillsLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> Edit Profile | {name || currentName} </title>
      </Helmet>

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Account
        </Typography>

        <StyledTabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable force tabs example"
          sx={{
            mb: 5,
            '& .MuiTabs-indicator': {
              backgroundColor: (theme) => theme.palette.text.primary,
            },
            '&.Mui-focusVisible': {
              backgroundColor: '',
              outline: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary, // Active tab text color
                backgroundColor: 'transparent',

                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="solar:user-id-bold" sx={{ marginRight: '10px' }} />
                General
              </Typography>
            }
          />

          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary, // Active tab text color
                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="solar:share-bold" sx={{ marginRight: '10px' }} />
                Social links
              </Typography>
            }
          />

          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary, // Active tab text color
                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="basil:key-solid" sx={{ marginRight: '10px' }} />
                Security & Privacy
              </Typography>
            }
          />
        </StyledTabs>

        {value === 0 && (
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Card sx={{ padding: '80px 24px 40px' }}>
                <Box mb={3} sx={{ textAlign: 'center' }}>
                  <Box
                    sx={{
                      padding: '8px',
                      border: '1px dashed rgba(145, 158, 171, 0.2)',
                      borderRadius: '50%',
                      overflow: 'hidden',
                      width: '144px',
                      height: '144px',
                      cursor: 'pointer',
                      marginX: 'auto',
                    }}
                  >
                    <Box
                      sx={{
                        borderRadius: '50%',
                        overflow: 'hidden',
                        width: '100%',
                        height: '100%',
                        position: 'relative',
                      }}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      onClick={openInput}
                    >
                      <img
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                        src={
                          watch('file') instanceof File
                            ? URL.createObjectURL(watch('file'))
                            : data?.profileImg || '/assets/placeholder.svg'
                        }
                        alt="Profile"
                        onError={(e) => {
                          e.target.src = '/assets/placeholder.svg'; // Set the placeholder on error
                        }}
                      />

                      {(hovered || (watch('file') instanceof File ? false : !data?.profileImg)) && (
                        <>
                          <Box
                            style={{
                              top: '0',
                              position: 'absolute',
                              left: '0',
                              backgroundColor: 'rgb(0 0 0 / 50%)',
                              color: 'white',
                              width: '100%',
                              height: '100%',
                            }}
                          >
                            <Box
                              component={'span'}
                              sx={{
                                top: '50%',
                                position: 'absolute',
                                left: '50%',
                                transform: 'translate(-50% , -50%)',
                              }}
                            >
                              <Iconify icon="bxs:camera-plus" />
                              <Typography component={'span'} sx={{ textWrap: 'nowrap' }} variant="caption">
                                Update photo
                              </Typography>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                  <Box mt={0.5}>
                    <input
                      type="file"
                      accept=".jpeg, .jpg, .png , .webp"
                      id="input-photo"
                      style={{ display: 'none' }}
                      onChange={handleFileChange}
                    />
                    {!(watch('file') instanceof File) && data?.profileImg === '' && (
                      <Typography color="error">{'Image is required'}</Typography>
                    )}
                  </Box>
                  <Typography variant="caption" mt={3} sx={{ color: (theme) => theme.palette.text.disabled }}>
                    Allowed *.jpeg, *.jpg, *.png , *.webp
                    <br /> max size of 3.1 MB
                  </Typography>
                </Box>
              </Card>
            </Grid>

            <Grid item md={8} xs={12}>
              <Card sx={{ p: 3 }}>
                <Form
                  email={data?.email}
                  errors={errors}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  register={register}
                  trigger={trigger}
                  watch={watch}
                  about
                  loading={loading}
                  skills={skills}
                  interests={interests}
                  interestsField
                  skillsField
                  interestsData={interestData}
                  skillsData={skillsData}
                  setInterests={setInterests}
                  setSkills={setSkills}
                />
              </Card>
            </Grid>
          </Grid>
        )}

        {value === 1 && (
          <form onSubmit={handleSubmitSocial(onSubmitSocial)}>
            <Card sx={{ gap: '24px', display: 'flex', flexDirection: 'column', p: 3 }}>
              {watchSocial('profileUrls')?.map((link, index) => (
                <Grid item xs={12} key={index}>
                  <Controller
                    name={`profileUrls[${index}].url`}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={`${link.socialMedia} (optional)`}
                        variant="outlined"
                        {...registerSocial(`profileUrls[${index}].url`, {
                          pattern: {
                            value: urlPatterns[link.socialMedia],
                            message: `Invalid ${link.socialMedia} URL`,
                          },
                        })}
                        error={!!errorsSocial?.profileUrls?.[index]}
                        helperText={errorsSocial?.profileUrls?.[index]?.url?.message}
                        onBlur={() => {
                          triggerSocial(`profileUrls[${index}].url`);
                        }}
                        fullWidth
                        InputLabelProps={{
                          shrink: !!field.value,
                          style: {
                            marginLeft: field.value ? 0 : '30px',
                          },
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {/* Add the appropriate icon based on socialMedia */}
                              {link.socialMedia === 'Twitter' && <Iconify icon="bxl:twitter" color="#1DA1F2" />}
                              {link.socialMedia === 'LinkedIn' && <Iconify icon="bxl:linkedin" color="#0A66C2" />}
                              {link.socialMedia === 'Facebook' && <Iconify icon="bxl:facebook" color="#1877f2" />}
                              {link.socialMedia === 'Instagram' && <Iconify icon="bxl:instagram" color="#e02d69" />}
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <Controller
                                name={`profileUrls[${index}].public`}
                                control={control}
                                defaultValue={link.public}
                                render={({ field }) => (
                                  <FormControlLabel
                                    control={<Switch {...field} checked={field.value} />} // Set checked to the field value
                                    label="Public"
                                  />
                                )}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              ))}

              <Box>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  sx={{ float: 'right' }}
                  style={{ marginTop: '20px' }}
                  type="submit"
                >
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} /> Update social links
                  </Stack>
                </LoadingButton>
              </Box>
            </Card>
          </form>
        )}

        {value === 2 && (
          <>
            <Card sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, px: 3, pt: '4px' }}>
              <StyledTabs
                value={valueSecurity}
                onChange={handleChangeSecurity}
                sx={{
                  '& .MuiTabs-indicator': {
                    backgroundColor: (theme) => theme.palette.text.primary,
                  },
                  '&.Mui-focusVisible': {
                    backgroundColor: '',
                    outline: 'none',
                    boxShadow: 'none',
                  },
                }}
                aria-label="styled tabs example"
              >
                <StyledTab
                  sx={{
                    p: 0,
                    mr: 3,
                    '&.Mui-selected': {
                      color: (theme) => theme.palette.text.primary, // Active tab text color
                      backgroundColor: 'transparent',
                      fontWeight: 600,
                    },
                    fontWeight: 500,
                  }}
                  label={
                    <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                      <Iconify icon="basil:unlock-solid" sx={{ marginRight: '10px' }} />
                      Change Password
                    </Typography>
                  }
                />
                <StyledTab
                  sx={{
                    p: 0,
                    mr: 3,
                    '&.Mui-selected': {
                      color: (theme) => theme.palette.text.primary, // Active tab text color
                      backgroundColor: 'transparent',
                      fontWeight: 600,
                    },
                    fontWeight: 500,
                  }}
                  label={
                    <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                      <Iconify icon="solar:user-block-bold-duotone" sx={{ marginRight: '10px' }} />
                      Blocked Profiles
                    </Typography>
                  }
                />
              </StyledTabs>
            </Card>
            {valueSecurity === 0 && (
              <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
                <Card
                  sx={{
                    gap: '32px',
                    display: 'flex',
                    flexDirection: 'column',
                    p: 3,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                  }}
                >
                  <Grid item xs={12}>
                    <TextField
                      label="Old Password"
                      variant="outlined"
                      type={type}
                      fullWidth
                      {...registerPassword('oldPassword', {
                        required: 'Old Password is required',
                      })}
                      error={!!errorsPassword.oldPassword}
                      helperText={errorsPassword.oldPassword?.message}
                      onBlur={() => triggerPassword('oldPassword')}
                      InputLabelProps={{
                        shrink: !!watchPassword('oldPassword'),
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton color="secondary" onClick={handleType}>
                              <Iconify icon={type === 'password' ? 'solar:eye-bold' : 'ph:eye-closed'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="New Password"
                      variant="outlined"
                      type={type}
                      fullWidth
                      {...registerPassword('newPassword', {
                        required: 'New Password is required',
                        pattern: {
                          value: /.{6,}/, // Minimum 6 characters
                          message: 'Password must be at least 6 characters',
                        },
                      })}
                      error={!!errorsPassword.newPassword}
                      helperText={errorsPassword.newPassword?.message}
                      onBlur={() => triggerPassword('newPassword')}
                      InputLabelProps={{
                        shrink: !!watchPassword('newPassword'),
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleType} color="secondary">
                              <Iconify icon={type === 'password' ? 'solar:eye-bold' : 'ph:eye-closed'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {!errorsPassword.newPassword && (
                      <Typography display={'flex'} variant="caption" alignItems={'center'} mt={0.5} ml={2}>
                        <Iconify icon="material-symbols:info" sx={{ width: '15px', marginRight: '10px' }} />
                        Password must be minimum 6+
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      label="Confirm New Password"
                      variant="outlined"
                      type={type}
                      fullWidth
                      {...registerPassword('confirmNewPassword', {
                        required: 'Confirm New Password is required',
                        validate: (value) => value === watchPassword('newPassword') || 'Passwords do not match',
                      })}
                      error={!!errorsPassword.confirmNewPassword}
                      helperText={errorsPassword.confirmNewPassword?.message}
                      onBlur={() => triggerPassword('confirmNewPassword')}
                      InputLabelProps={{
                        shrink: !!watchPassword('confirmNewPassword'),
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={handleType} color="secondary">
                              <Iconify icon={type === 'password' ? 'solar:eye-bold' : 'ph:eye-closed'} />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>

                  <Box>
                    <LoadingButton
                      loading={loading}
                      variant="contained"
                      sx={{ float: 'right' }}
                      style={{ marginTop: '20px' }}
                      type="submit"
                    >
                      <Stack flexDirection={'row'} alignItems={'center'}>
                        <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} /> Change Password
                      </Stack>
                    </LoadingButton>
                  </Box>
                </Card>
              </form>
            )}

            {valueSecurity === 1 && (
              <form>
                <Card
                  // key={user.userId}
                  sx={{
                    p: 2,
                    display: 'flex',
                    // alignItems: 'center',
                    gap: 2,
                    borderTopLeftRadius: 0,
                    borderTopRightRadius: 0,
                    flexDirection: 'column',
                  }}
                >
                  {users?.length > 0 ? (
                    users.map((user, idx) => (
                      <Box display={'flex'} key={idx} alignItems={'center'}>
                        <Avatar src={user.image} alt={user.name} />
                        <Typography variant="body1" ml={1}>
                          {user.name}
                        </Typography>
                        <FormControlLabel
                          sx={{ ml: 1 }}
                          control={
                            <Switch
                              checked={user.blocked}
                              onChange={() => handleToggleBlock(user.userId)}
                              name={`blockSwitch-${user.userId}`}
                            />
                          }
                          label="Block"
                        />
                      </Box>
                    ))
                  ) : (
                    <Box> There's no blocked user</Box>
                  )}
                </Card>
              </form>
            )}
          </>
        )}
      </Container>

      <CustomAlert type={typeUser ? 'success' : 'error'} message={messageUser} open={open} setOpen={setOpen} />

      <CustomAlert
        type={typeImage ? 'success' : 'error'}
        message={imageMessage}
        open={openImage}
        autoHideDuration={2000}
        setOpen={setOpenImage}
      />
    </>
  );
}

export default EditPage;
