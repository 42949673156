import React from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { ProductCard } from '../components/product/ProductCard';
import { formatDate } from '../utils/displayDateTime';
import {
  useGetMeetingRoomsQuery,
  useGetPackagesQuery,
  useGetSinglePackageQuery,
  useUseGetDurationQuery,
} from '../Slices/spaceApi';
import Loader from '../components/Loader/Loader';

const SinglePurchasePage = () => {
  const { id } = useParams();

  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data, isLoading } = useGetSinglePackageQuery({ spaceId: spaceId || currentSpaceId, packageId: id });

  console.log(data, 'single');

  const { data: meetingRooms, isLoading: meetingRoomsLoading } = useGetMeetingRoomsQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const { data: duration, isLoading: durationLoading } = useUseGetDurationQuery({ spaceId: spaceId || currentSpaceId });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Purchase—{id}</title>
      </Helmet>

      <Container>
        <Box>
          <Typography variant="h4">Purchased #{id}</Typography>
          <Typography variant="subtitle1">
            <Stack>
              <Typography variant="subtitle2">
                {`${formatDate(data?.start_date)} - ${formatDate(data?.renew_date)}`}
              </Typography>

              {/* <Typography variant="subtitle2">{formatDate(data?.start_date) "-"  formatDate(data?.renew_date)}</Typography> */}
              {/* <Typography variant="subtitle2">Renew Date </Typography> */}
            </Stack>
          </Typography>
        </Box>

        <Grid container spacing={2} mt={5}>
          {data?.purchase?.items?.length > 0 ? (
            data?.purchase?.items?.map((item, idx) => (
              <ProductCard
                key={idx}
                item={{ ...item?.product, quantity: item?.quantity }}
                duration={duration}
                meetingRooms={meetingRooms}
                purchase
              />
            ))
          ) : (
            <Grid item xs={12} textAlign={'center'}>
              <Typography variant="h6" paragraph>
                There's no purchase item in this package
              </Typography>
            </Grid>
          )}
        </Grid>
      </Container>
    </>
  );
};

export default SinglePurchasePage;
