import { LoadingButton } from '@mui/lab';
import { Autocomplete, Grid, Stack, TextField } from '@mui/material';
import React from 'react';
import Iconify from '../iconify/Iconify';

function Form({
  handleSubmit,
  onSubmit,
  register,
  errors,
  trigger,
  watch,
  about,
  loading,
  skillsField,
  skills,
  interestsField,
  interests,
  email,
  setInterests,
  setSkills,
  skillsData,
  interestsData,
}) {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} md={6}>
          <TextField
            label="Name"
            variant="outlined"
            fullWidth
            {...register('name', {
              required: 'Name is required',
            })}
            error={!!errors.name}
            helperText={errors.name?.message}
            onBlur={() => trigger('name')}
            InputLabelProps={{ shrink: !!watch('name') }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            disabled
            value={email}
            // InputProps={{
            //   style: { background: 'rgba(0, 0, 0, 0.1)' }, // Adjust the background color as needed
            // }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Phone No."
            variant="outlined"
            fullWidth
            {...register('phoneNo', {
              required: 'Phone number is required',
              pattern: {
                value: /^[0-9]+$/,
                message: 'Please enter only numeric digits.',
              },
              minLength: {
                value: 11,
                message: 'Phone number must have at least 11 characters',
              },
            })}
            error={!!errors.phoneNo}
            helperText={errors.phoneNo?.message}
            onBlur={() => trigger('phoneNo')}
            InputLabelProps={{ shrink: !!watch('phoneNo') !== '' && true }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            {...register('address', {
              required: 'Address is required',
            })}
            error={!!errors.address}
            helperText={errors.address?.message}
            onBlur={() => trigger('address')}
            InputLabelProps={{ shrink: !!watch('address') !== '' && true }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            label="Title"
            variant="outlined"
            fullWidth
            {...register('title', {
              required: 'Title is required',
            })}
            error={!!errors.title}
            helperText={errors.title?.message}
            onBlur={() => trigger('title')}
            InputLabelProps={{ shrink: !!watch('title') !== '' && true }}
          />
        </Grid>

        {interestsField && interests?.length >= 1 && (
          <>
            <Grid item xs={12}>
              <Autocomplete
                value={interests}
                onChange={(event, newValue) => {
                  setInterests(newValue);
                }}
                multiple
                id="autocomplete"
                options={interestsData}
                getOptionLabel={(option) => option}
                filterOptions={(options, { inputValue }) => {
                  const filteredOptions = options.filter((option) => !interests.includes(option));

                  return filteredOptions.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));
                }}
                renderInput={(params) => (
                  <TextField {...params} variant="outlined" label="Select Interests (optional)" />
                )}
              />
            </Grid>
          </>
        )}

        {skillsField && skills?.length >= 1 && (
          <>
            <Grid item xs={12}>
              <Autocomplete
                value={skills}
                onChange={(event, newValue) => {
                  setSkills(newValue);
                }}
                multiple
                id="autocomplete"
                options={skillsData}
                filterOptions={(options, { inputValue }) => {
                  const filteredOptions = options.filter((option) => !skills.includes(option));

                  return filteredOptions.filter((option) => option.toLowerCase().includes(inputValue.toLowerCase()));
                }}
                getOptionLabel={(option) => option}
                renderInput={(params) => <TextField {...params} variant="outlined" label="Select Skills (optional)" />}
              />
            </Grid>
          </>
        )}

        {about && (
          <Grid item xs={12}>
            <TextField
              label="About"
              variant="outlined"
              fullWidth
              {...register('description', {
                required: 'About is required',
              })}
              error={!!errors.description}
              multiline
              rows={5}
              helperText={errors.description?.message}
              onBlur={() => trigger('description')}
              InputLabelProps={{ shrink: !!watch('description') !== '' && true }}
            />
          </Grid>
        )}
      </Grid>
      <LoadingButton
        loading={loading}
        variant="contained"
        sx={{ float: 'right' }}
        style={{ marginTop: '20px' }}
        onClick={handleSubmit(onSubmit)}
      >
        <Stack flexDirection={'row'} alignItems={'center'}>
          <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} /> Update Profile
        </Stack>
      </LoadingButton>
    </form>
  );
}

export default Form;
