export function sortBookingsNearCurrentDate(bookings) {
  if (!Array.isArray(bookings)) {
    return [];
  }

  // Create a copy of the products array to avoid modifying the original array
  const bookingCopy = [...bookings];

  const currentTimestamp = Math.floor(Date.now() / 1000); // Get current timestamp in seconds

  // Sort the bookings array based on the difference between the current timestamp and the booking's startTime
  bookingCopy.sort((a, b) => Math.abs(a.startTime - currentTimestamp) - Math.abs(b.startTime - currentTimestamp));

  return bookingCopy;
}
