import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Grid,
  Container,
  Typography,
  Card,
  InputAdornment,
  Input,
  TextField,
  Stack,
  Box,
  Button,
  Chip,
  CardHeader,
  Avatar,
  IconButton,
  ListItemIcon,
  ListItem,
  ListItemText,
  List,
  Popover,
  MenuItem,
  useMediaQuery,
  TableContainer,
  Paper,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Table,
  Link,
  TableFooter,
  Alert,
  Snackbar,
  CardContent,
  Dialog,
} from '@mui/material';
// components
import React, { useEffect, useRef, useState } from 'react';

import axios from 'axios';

import { LoadingButton } from '@mui/lab';
import { useSelector } from 'react-redux';
import html2pdf from 'html2pdf.js';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { sortBookingsNearCurrentDate } from '../utils/sortedBookings';
import { downloadPDF } from '../utils/downloadPdf';
import { truncateText } from '../utils/truncatetext';
import { formatDate, formatDateNotTime } from '../utils/displayDateTime';
import Loader from '../components/Loader/Loader';

import {
  useCreateCommentMutation,
  useCreatePostMutation,
  useDeleteCommentMutation,
  useDeletePostMutation,
  useGetBlocksUsersQuery,
  useGetBookingsQuery,
  useGetInvoicesQuery,
  useGetPackagesQuery,
  useGetPostsQuery,
  useGetTicketsQuery,
  useGetUserDetailsQuery,
  useUpdateCommentMutation,
} from '../Slices/spaceApi';
import { ConfirmComponent, CustomAlert, DeleteComponent } from '../utils/Alert';
import { maxFileSize } from '../utils/maxFileSize';
import Iconify from '../components/iconify';
import { TimeAgo } from '../utils/TimeAgoFunction';
import EditPost from '../components/Dashboard/EditPost';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const [selectedImage, setSelectedImage] = useState(null);

  const [postData, setPostData] = useState({
    image: '',
    text: '',
    link: '',
    title: '',
  });

  const [loading, setLoading] = useState(false);

  const [typeImage, setTypeImage] = useState('');
  const [openImage, setOpenImage] = useState(false);

  const [typePost, setTypePost] = useState('');
  const [openPost, setOpenPost] = useState(false);
  const [messagePost, setMessagePost] = useState('');
  const [showMore, setShowMore] = useState([]);
  const [showMoreComment, setShowMoreComment] = useState([]);

  const { currentUserId, userId, spaceId, currentSpaceId, name, currentName } = useSelector(
    (item) => item.spaceReducer
  );
  const navigate = useNavigate();

  const userToCheck = userId || currentUserId;

  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md')); // Checks if it's a medium or larger screen

  const { data, isLoading } = useGetUserDetailsQuery(userId || currentUserId);
  const { data: invoiceData, isLoading: invoiceLoading } = useGetInvoicesQuery({
    spaceId: spaceId || currentSpaceId,
    activePage: 1,
    page_size: 30,
  });

  // Get the current date
  const currentDate = new Date();

  // Calculate the first day of the current month
  const firstDayOfCurrentMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);

  // Filter invoices for the last month and all unpaid invoices
  const filteredInvoices = invoiceData?.invoices.filter((invoice) => {
    const issuedDate = new Date(invoice.issued_date);

    if (invoice.paid === false || invoice.paid === null || invoice.type === 3) {
      // Include all unpaid invoices
      return true;
    }
    // Include only paid invoices from the previous month
    return issuedDate >= firstDayOfCurrentMonth && issuedDate < currentDate;
  });

  const [dailog, setDailog] = useState(false);
  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = packages?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  useEffect(() => {
    if (!checkOldUser && !pacakgeLoading) {
      setDailog(true);
      navigate('/user/account');
    } else {
      setDailog(false);
    }
  }, [checkOldUser , pacakgeLoading]);

  const [createPost, resultStats] = useCreatePostMutation();
  const [createComment, resultStats1] = useCreateCommentMutation();
  const [deletePost, resultStats2] = useDeletePostMutation();
  const [deleteComment, resultStats3] = useDeleteCommentMutation();
  const [updateComment, resultStats4] = useUpdateCommentMutation();

  const [imageMessage, setImageMessage] = useState('');

  const {
    data: postsData,
    isLoading: postsLoading,
    refetch,
  } = useGetPostsQuery({ spaceId: spaceId || currentSpaceId });

  const { data: bookingSort, isLoading: bookingLoading } = useGetBookingsQuery({
    spaceId: spaceId || currentSpaceId,
    type: 'all',
  });

  const bookingData = sortBookingsNearCurrentDate(bookingSort?.bookingss);

  const { data: ticketData, isLoading: ticketsLoading } = useGetTicketsQuery({
    spaceId: spaceId || currentSpaceId,
    status: 1,
    activePage: 1,
    page_size: 30,
  });

  const {
    data: blockUsersData,
    isLoading: blockUsersLoading,
    refetch: blockRefetch,
  } = useGetBlocksUsersQuery({
    spaceId: spaceId || currentSpaceId,
  });

  const [typeAdd, setTypeAdd] = useState('');
  const [messageAdd, setMessageAdd] = useState('');
  const [openAlert, setOpenAlert] = useState(false);

  useEffect(() => {
    if (resultStats?.isLoading) {
      setLoading(true);
    } else if (resultStats?.isSuccess) {
      setTypeAdd(true);
      setLoading(false);
      setOpenAlert(true);
      setMessageAdd('Post created successfully.');
      setPostData({ text: '', image: '', link: '', title: '' });
      setSelectedImage('');
      setDialogOpen(false);
    } else if (resultStats?.isError) {
      const errorMessage = Object.values(resultStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setLoading(false);
      setOpenAlert(true);
      setTypeAdd(false);
    }
  }, [resultStats]);

  const handleLikeClick = (postId) => {
    axios
      .get(`/space/${spaceId || currentSpaceId}/post/${postId}/like/`)
      .then((response) => {
        if (response.status === 200) {
          refetch();
        } else {
          /* empty */
        }
      })
      .catch((error) => {
        // Handle errors here
      });
  };

  const blockPost = (userId) => {
    axios
      .get(`/space/${spaceId || currentSpaceId}/block/${userId}/block/`)
      .then((response) => {
        if (response.status === 200) {
          refetch();
          blockRefetch();
        } else {
          /* empty */
        }
      })
      .catch((error) => {
        // Handle errors here
        console.error('Error:', error);
      });
  };

  const handleRemoveLike = (postId) => {
    axios
      .get(`/space/${spaceId || currentSpaceId}/post/${postId}/remove-like/`)
      .then((response) => {
        if (response.status === 200) {
          refetch();
        } else {
          /* empty */
        }
      })
      .catch((error) => {});
  };

  useEffect(() => {
    if (resultStats2?.isSuccess) {
      setTypePost(true);
      setOpenPost(true);
      setMessagePost('Post deleted successfully.');
    } else if (resultStats2?.isError) {
      const errorMessage = resultStats2?.error?.data.message;
      setMessagePost(errorMessage);
      setOpenPost(true);
      setTypePost(false);
    }
  }, [resultStats2]);

  useEffect(() => {
    if (selectedImage) {
      if (
        selectedImage.type &&
        (selectedImage.type.includes('image/jpeg') ||
          selectedImage.type.includes('image/jpg') ||
          selectedImage.type.includes('image/png') ||
          selectedImage.type.includes('image/webp'))
      ) {
        const fd = new FormData();
        fd.append('image', selectedImage); // Append the selected file to the FormData
        axios
          .post('/uploadImage/', fd)
          .then((resp) => {
            setImageMessage('Image uploaded sucessfully.');
            setLoading(false);
            setOpenImage(true);
            setTypeImage(true);
            setPostData({ ...postData, image: resp.data?.[0] }); // Update the 'file' field value
          })
          .catch((err) => {
            setImageMessage('Failed to upload an image.');
            setLoading(false);
            setOpenImage(false);
            setTypeImage(false);
          });
      } else {
        setImageMessage('Invalid image format. Please upload a valid JPEG, JPG, PNG, or WebP file.');
        setSelectedImage('');
        setOpenImage(true);
        setLoading(false);
        setTypeImage(false);
      }
    }
  }, [selectedImage]);

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      setLoading(true);
      if (selectedFile.size > maxFileSize) {
        setImageMessage('File size exceeds the limit (3.1 MB).');
      } else {
        setSelectedImage(selectedFile);
      }
    }
  };

  const handlePost = () => {
    createPost({
      spaceId: spaceId || currentSpaceId,
      data: { content: postData?.text, image: postData?.image, title: postData?.title, link: postData?.link },
    });
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedPost, setSelectedPost] = useState(null);
  const [linkField, setLinkField] = useState(false);
  const [titleField, setTitleField] = useState(false);

  useEffect(() => {
    if (!linkField) {
      setPostData({ ...postData, link: '' });
    }
  }, [linkField]);

  useEffect(() => {
    if (!titleField) {
      setPostData({ ...postData, title: '' });
    }
  }, [titleField]);

  const handlePopoverOpen = (event, postId) => {
    setAnchorEl(event.currentTarget);
    setSelectedPost(postId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedPost(null); // Clear the selected post ID when closing the popover
  };

  const open = Boolean(anchorEl);

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedComment, setSelectedComment] = useState(null);

  const handlePopoverCommentOpen = (event, commentId) => {
    setAnchorEl1(event.currentTarget); // Use event.currentTarget here
    setSelectedComment(commentId); // Set the selected comment ID
  };

  const handlePopoverCommentClose = () => {
    setAnchorEl1(null);
    setSelectedComment(null); // Clear the selected comment ID when closing the popover
  };

  const [filteredPosts, setfilteredPosts] = useState([]);
  const openComment = Boolean(anchorEl1);

  const [commentTexts, setCommentTexts] = useState(Array(filteredPosts?.length).fill(''));

  const [resetIndex, setResetIndex] = useState('');

  const [editStates, setEditStates] = useState(filteredPosts?.map(() => false));
  const [openFilter, setOpenFilter] = useState(false);
  const [selectVisit, setSelectVisit] = useState();
  const [selectVisitIndex, setSelectedVisitIndex] = useState();
  const [selectedCommentEdit, setSelectedCommentEdit] = useState('');
  const [selectedCommentReply, setSelectedCommentReply] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);

  const [openCheck, setOpenCheck] = useState(false);
  const [selectDelete, setSelectDelete] = useState(false);

  const [openCheckComment, setOpenCheckComment] = useState(false);
  const [selectDeleteComment, setSelectDeleteComment] = useState(null);

  const handleOpenFilter = (item, idx) => {
    setSelectedVisitIndex(idx);
    setSelectVisit(item);
    handlePopoverClose();
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCommentTextChange = (event, index) => {
    const newCommentTexts = [...commentTexts];
    newCommentTexts[index] = event.target.value;
    setCommentTexts(newCommentTexts);
  };

  const postSubmit = (idx) => {
    setResetIndex(idx);
    createComment({
      spaceId: spaceId || currentSpaceId,
      postId: filteredPosts?.[idx]?.id,
      data: { text: commentTexts[idx] },
    });
  };

  const handleDeletePost = (post) => {
    setOpenCheck(true);
    setSelectDelete(post);
    setSelectedPost(null);
  };

  const handleDeleteComment = (comment) => {
    setOpenCheckComment(true);
    setSelectDeleteComment(comment);

    setSelectedComment(null);
  };

  const handleEditComment = (comment) => {
    setSelectedCommentEdit(comment);
    setSelectedComment(null);
  };

  const postUpdateComment = (comment) => {
    updateComment({
      spaceId: spaceId || currentSpaceId,
      postId: comment?.post,
      commentId: comment.id,
      data: { text: selectedCommentEdit?.text },
    });
  };

  const replyComment = (comment) => {
    // if (selectedCommentReply) {
    setSelectedCommentReply(comment);
    // } else {
    // setSelectedCommentReply(comment);
    // }
  };

  const postReplyComment = () => {
    createComment({
      spaceId: spaceId || currentSpaceId,
      postId: selectedCommentReply?.post,
      data: { text: selectedCommentReply?.text, parent_comment: selectedCommentReply?.id },
    });
  };

  useEffect(() => {
    if (resultStats1?.isSuccess) {
      const newCommentTexts = [...commentTexts];
      newCommentTexts[resetIndex] = '';
      setCommentTexts(newCommentTexts);
      if (selectedCommentReply) {
        setSelectedCommentReply('');
      }
    }
  }, [resultStats1]);

  function organizeComments(comments) {
    const organizedComments = [];
    const commentMap = new Map();

    // Create a map of comments using their IDs
    comments.forEach((comment) => {
      // eslint-disable-next-line camelcase
      const { id, parent_comment } = comment;
      if (!commentMap.has(id)) {
        commentMap.set(id, { ...comment, replies: [] });
      }

      // eslint-disable-next-line camelcase
      if (parent_comment !== null) {
        if (!commentMap.has(parent_comment)) {
          commentMap.set(parent_comment, { replies: [] });
        }
        commentMap.get(parent_comment).replies.push(commentMap.get(id));
      } else {
        organizedComments.push(commentMap.get(id));
      }
    });

    return organizedComments;
  }

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  const handleCloseCheckComment = () => {
    setOpenCheckComment(false);
  };

  useEffect(() => {
    if (resultStats4?.isSuccess) {
      setSelectedCommentEdit(null);
    }
  }, [resultStats4]);

  // Function to handle "Show More" button click
  const handleShowMore = (idx) => {
    setShowMore((prevShowMore) => {
      const newShowMore = [...prevShowMore];
      newShowMore[idx] = !newShowMore[idx];
      return newShowMore;
    });
  };

  // Function to handle "Show More" button click
  const handleShowMoreComment = (idx) => {
    setShowMoreComment((prevShowMoreComment) => {
      const newShowMoreComment = [...prevShowMoreComment];
      newShowMoreComment[idx] = !newShowMoreComment[idx];
      return newShowMoreComment;
    });
  };

  useEffect(() => {
    if (postsData?.posts.length >= 1) {
      const sortedPosts = [...postsData.posts].sort((a, b) => {
        // Sort by pinned posts first
        if (b.pinned && !a.pinned) {
          return 1; // Move b to a higher index
        }
        if (!b.pinned && a.pinned) {
          return -1; // Move a to a higher index
        }
        // If both are pinned or not, sort by created_at in descending order
        return new Date(b.created_at) - new Date(a.created_at);
      });

      setfilteredPosts(sortedPosts);
    }
  }, [postsData?.posts]);

  useEffect(() => {
    refetch();
  }, []);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleTitleField = () => {
    if (titleField) {
      setTitleField(false);
    } else {
      setTitleField(true);
    }
  };

  const handleLinkField = () => {
    if (linkField) {
      setLinkField(false);
    } else {
      setLinkField(true);
    }
  };

  if (isLoading || postsLoading || pacakgeLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title> Community | {name || currentName} </title>
      </Helmet>

      {/* <ConfirmComponent openCheck={dailog} /> */}

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Community
        </Typography>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6.5}>
            <Card>
              <CardHeader
                sx={{ p: 2, cursor: 'pointer' }}
                avatar={<Avatar src={data?.profileImg} alt="Avatar" />}
                subheader={
                  <Box
                    sx={{ backgroundColor: (theme) => theme.palette.action.selected }}
                    padding={'10px'}
                    borderRadius={'25px'}
                  >
                    Share what are you thinking here?
                  </Box>
                }
                onClick={handleOpenDialog}
              />
            </Card>
            <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
              <Card component={Paper}>
                <CardHeader
                  avatar={<Avatar src={data?.profileImg} alt="Avatar" />}
                  title={data?.name}
                  action={
                    <IconButton color="secondary" aria-label="close" onClick={handleCloseDialog}>
                      <Iconify icon="eva:close-fill" />
                    </IconButton>
                  }
                />
                <CardContent>
                  {titleField && (
                    <TextField
                      value={postData?.title}
                      onChange={(e) => setPostData({ ...postData, title: e.target.value })}
                      variant="outlined"
                      placeholder="Title"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}

                  {linkField && (
                    <TextField
                      value={postData?.link}
                      onChange={(e) => setPostData({ ...postData, link: e.target.value })}
                      variant="outlined"
                      placeholder="Link"
                      fullWidth
                      sx={{ mb: 2 }}
                    />
                  )}

                  <TextField
                    value={postData?.text}
                    onChange={(e) => setPostData({ ...postData, text: e.target.value })}
                    variant="outlined"
                    placeholder="Share what are you thinking here..."
                    fullWidth
                    multiline
                    rows={5}
                  />

                  <Stack flexDirection={'row'} alignItems={'center'} mt={5} justifyContent={'space-between'}>
                    <Box>
                      <Button
                        variant="outlined"
                        startIcon={<Iconify icon="material-symbols:add" />}
                        onClick={handleTitleField}
                      >
                        {titleField ? 'Remove Title' : 'Add Title'}
                      </Button>

                      <Button
                        variant="outlined"
                        startIcon={<Iconify icon="material-symbols:add" />}
                        sx={{ ml: 1 }}
                        onClick={handleLinkField}
                      >
                        {linkField ? 'Remove Link' : 'Add Link'}
                      </Button>

                      <label htmlFor="image-upload-button">
                        <Chip
                          label="Upload Image"
                          avatar={<Iconify icon="uil:image-upload" sx={{ color: 'rgb(34, 197, 94) !important' }} />}
                          sx={{
                            bgcolor: 'rgba(145, 158, 171, 0.08)',
                            cursor: 'pointer',
                            ml: 1,
                          }}
                          clickable
                          component="span"
                        />
                        <input
                          id="image-upload-button"
                          type="file"
                          accept=".jpeg, .jpg, .png , .webp"
                          style={{ display: 'none' }}
                          onChange={handleFileChange}
                        />
                      </label>
                    </Box>

                    <LoadingButton
                      loading={loading}
                      disabled={!postData?.text && !postData?.link && !postData?.title}
                      variant="contained"
                      onClick={handlePost}
                    >
                      Post
                    </LoadingButton>
                  </Stack>
                  {selectedImage && (
                    <Stack mt={2} spacing={1}>
                      <Chip
                        label={selectedImage.name}
                        onDelete={() => {
                          setSelectedImage('');
                          setPostData({ ...postData, image: '' });
                        }}
                        sx={{ bgcolor: 'rgba(145, 158, 171, 0.08)' }}
                      />
                    </Stack>
                  )}
                </CardContent>
              </Card>
            </Dialog>

            {filteredPosts?.map((post, idx) => (
              <Card key={post.id} sx={{ mt: 2, px: 1, pb: 1 }}>
                <CardHeader
                  sx={{ px: 2, pt: 2 }}
                  avatar={
                    <Box
                      sx={{
                        transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        padding: '3px',
                        border: '1px dashed rgba(145, 158, 171, 0.2)',
                        borderRadius: '50%',
                        overflow: 'hidden',
                      }}
                    >
                      <Avatar src={post.user.image} alt="Avatar" />{' '}
                    </Box>
                  }
                  title={post.user.name}
                  subheader={formatDate(post.created_at)}
                  action={
                    <>
                      {(post?.status === 3 || post?.status === 2) && (
                        <Chip
                          label={
                            post?.status === 3
                              ? 'Post pending approval'
                              : post?.status === 2
                              ? 'Post Disapproved by admin'
                              : ''
                          }
                          color={post?.status === 3 ? 'info' : post?.status === 2 ? 'error' : ''}
                        />
                      )}
                      <IconButton
                        color="secondary"
                        sx={{ '&:hover': { backgroundColor: 'transparent', cursor: 'default' } }}
                      >
                        {post?.pinned && <Iconify icon="tabler:pinned-filled" />}
                      </IconButton>
                      <IconButton
                        color="secondary"
                        aria-label="more"
                        aria-controls={`post-actions-${post.id}`}
                        aria-haspopup="true"
                        onClick={(e) => handlePopoverOpen(e, post.id)}
                      >
                        <Iconify icon={'eva:more-vertical-fill'} />
                      </IconButton>
                      <Popover
                        open={open && selectedPost === post.id}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        PaperProps={{
                          sx: {
                            p: 1,
                            width: 140,
                            '& .MuiMenuItem-root': {
                              px: 1,
                              typography: 'body2',
                              borderRadius: 0.75,
                            },
                          },
                        }}
                      >
                        <List>
                          {(+userId || +currentUserId) === (+post?.user || +post?.user?.userId) ? (
                            <>
                              <MenuItem onClick={() => handleOpenFilter(post, idx)}>
                                <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                                Edit
                              </MenuItem>
                              <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeletePost(post)}>
                                <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                Delete
                              </MenuItem>
                            </>
                          ) : (
                            <>
                              <MenuItem onClick={() => blockPost(post?.user?.userId)}>Block</MenuItem>
                            </>
                          )}
                        </List>
                      </Popover>
                    </>
                  }
                />

                {post?.title && (
                  <Box sx={{ px: 2, pt: 2 }}>
                    <Typography variant="subtitle1">{post.title}</Typography>
                  </Box>
                )}

                {post?.link && (
                  <Box sx={{ px: 2 }}>
                    <Link
                      href={post.link}
                      color={'secondary'}
                      fontSize={'14px'}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {post.link}
                    </Link>
                  </Box>
                )}

                <Box sx={{ px: 2, py: 2 }}>
                  <Typography variant="body2" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                    {post.content.length <= 250 ? (
                      <div dangerouslySetInnerHTML={{ __html: post.content }} />
                    ) : (
                      <>
                        <div dangerouslySetInnerHTML={{ __html: post.content.substring(0, 250) }} />
                        {!showMore[idx] ? (
                          '...'
                        ) : (
                          <div dangerouslySetInnerHTML={{ __html: post.content.substring(250) }} />
                        )}
                        {post.content.length > 250 && (
                          <Typography
                            variant="body2"
                            sx={{
                              color: (theme) => theme.palette.action.tertiaryColor,
                              cursor: 'pointer',
                              textDecoration: 'underline',
                              '&:hover': {
                                opacity: 0.8,
                              },
                            }}
                            display={'inline'}
                            onClick={() => handleShowMore(idx)}
                          >
                            {showMore[idx] ? 'Show Less' : 'Show More'}
                          </Typography>
                        )}
                      </>
                    )}
                  </Typography>
                </Box>

                {post.image && (
                  <Box style={{ position: 'relative', width: '100%', paddingBottom: '56.25%' }}>
                    <img
                      src={post.image || '/assets/placeholder.svg'}
                      alt=""
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                        borderRadius: '12px',
                      }}
                      onError={(e) => {
                        e.target.src = '/assets/placeholder.svg';
                      }}
                    />
                  </Box>
                )}

                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box display={'flex'} alignItems={'center'}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Box
                        sx={{
                          transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                          padding: '3px',
                          border: '1px dashed rgba(145, 158, 171, 0.2)',
                          borderRadius: '50%',
                          overflow: 'hidden',
                          mr: 0.5,
                          '&:hover': {
                            transform: 'scale(1.05)',
                          },
                        }}
                      >
                        <IconButton
                          color="secondary"
                          size="small"
                          onClick={() => {
                            const likedByUser = post?.likes?.some((item) => item.userId === +userToCheck);

                            if (likedByUser) {
                              handleRemoveLike(post?.id);
                            } else {
                              handleLikeClick(post?.id);
                            }
                          }}
                          sx={{
                            color: post?.likes?.some((item) => item.userId === +userToCheck) ? '' : 'inherit',
                          }}
                        >
                          <Iconify
                            icon={`${
                              post?.likes?.some((item) => item.userId === +userToCheck)
                                ? 'mdi:heart'
                                : 'mdi:heart-outline'
                            }`}
                          />
                        </IconButton>
                      </Box>
                      <Typography variant="body1">{post.likes.length >= 1 && post.likes.length}</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" ml={2}>
                      {post?.likes?.map((avatar, index) => (
                        <Box
                          sx={{
                            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            padding: '3px',
                            border: '1px dashed rgba(145, 158, 171, 0.2)',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            mr: 0.5,
                          }}
                        >
                          <Avatar
                            key={index}
                            alt={`User ${index + 1}`}
                            src={avatar.image}
                            sx={{ marginLeft: index > 0 ? -1 : 0 }}
                          />
                        </Box>
                      ))}
                      {post.likes.length > 3 && (
                        <Box
                          sx={{
                            transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                            padding: '3px',
                            border: '1px dashed rgba(145, 158, 171, 0.2)',
                            borderRadius: '50%',
                            overflow: 'hidden',
                            mr: 0.5,
                          }}
                        >
                          <Avatar
                            alt={`User ${post.userReactions.length}`}
                            sx={{
                              fontSize: '17px',
                              color: 'rgb(0, 120, 103)',
                              backgroundColor: 'rgb(200, 250, 214)',
                              marginLeft: -1,
                            }}
                          >
                            +{post.likes.length - 3}
                          </Avatar>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box mt={1.5} px={2}>
                  <CommentList
                    comments={organizeComments(post?.comments)}
                    selectedComment={selectedComment}
                    data={data}
                    handleDeleteComment={handleDeleteComment}
                    handleEditComment={handleEditComment}
                    postUpdateComment={postUpdateComment}
                    replyComment={replyComment}
                    postReplyComment={postReplyComment}
                    selectedCommentReply={selectedCommentReply}
                    setSelectedCommentEdit={setSelectedCommentEdit}
                    selectedCommentEdit={selectedCommentEdit}
                    setSelectedCommentReply={setSelectedCommentReply}
                    handlePopoverCommentOpen={handlePopoverCommentOpen}
                    anchorEl1={anchorEl1}
                    isMediumScreen={isMediumScreen}
                    openComment={openComment}
                    handlePopoverCommentClose={handlePopoverCommentClose}
                    idx={idx}
                    resultStats4={resultStats4}
                    resultStats1={resultStats1}
                    showMoreComment={showMoreComment}
                    handleShowMoreComment={handleShowMoreComment}
                  />
                </Box>

                <Box mb={2} px={2} display="flex" alignItems="center">
                  <Box
                    sx={{
                      display: 'inline-block',
                      transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                      padding: '3px',
                      border: '1px dashed rgba(145, 158, 171, 0.2)',
                      borderRadius: '50%',
                      mr: 2,
                    }}
                  >
                    <Avatar src={data?.profileImg} alt="Avatar" />
                  </Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Write a comment..."
                    size="small"
                    value={commentTexts[idx]}
                    onChange={(e) => handleCommentTextChange(e, idx)}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter' && !resultStats1?.isLoading) {
                        e.preventDefault(); // Prevent the default "Enter" behavior (e.g., adding a newline)
                        postSubmit(idx); // Call your submit function here
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            color="secondary"
                            aria-label="upload image"
                            size="small"
                            disabled={resultStats1?.isLoading}
                            onClick={() => postSubmit(idx)}
                          >
                            <Iconify icon="wpf:sent" />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Card>
            ))}

            {selectVisit && (
              <EditPost
                editStates={editStates}
                idx={selectVisitIndex}
                {...selectVisit}
                data={data}
                onCloseFilter={handleCloseFilter}
                setEditStates={setEditStates}
                openFilter={openFilter}
              />
            )}
          </Grid>

          <Grid item xs={12} md={5.5}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={3} sx={{ backgroundColor: 'transparent' }}>
                      Bookings
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookingLoading ? (
                    <TableRow>
                      <TableCell>Loading..</TableCell>
                    </TableRow>
                  ) : bookingData?.length <= 0 ? (
                    <TableRow>
                      <TableCell>There's no booking</TableCell>
                    </TableRow>
                  ) : (
                    bookingData?.map((booking) => (
                      <TableRow key={booking.id}>
                        <TableCell>
                          <Stack flexDirection={'row'} alignItems={'center'}>
                            <Typography variant="body2">{booking?.meetingRoom?.title}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {formatDate(dayjs.unix(booking?.startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {formatDate(dayjs.unix(booking?.endTime).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Stack alignItems={'flex-start'}>
                        <Button onClick={() => navigate('/bookings')}>View All Bookings</Button>
                        <Button
                          fullWidth
                          sx={{ mt: 1 }}
                          variant="contained"
                          onClick={() => navigate('/bookingEnquiry')}
                        >
                          Create Booking
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={3} sx={{ backgroundColor: 'transparent' }}>
                      Invoices
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {invoiceLoading ? (
                    <TableRow>
                      <TableCell>Loading..</TableCell>
                    </TableRow>
                  ) : filteredInvoices?.length <= 0 ? (
                    <TableRow>
                      <TableCell>There's no paid or unpaid invoice</TableCell>
                    </TableRow>
                  ) : (
                    filteredInvoices?.map((invoice) => (
                      <TableRow key={invoice.id}>
                        <TableCell>
                          <Stack flexDirection={'row'} alignItems={'center'}>
                            <IconButton color="secondary" sx={{ mr: 0.5 }} onClick={() => downloadPDF(invoice)}>
                              <Iconify icon="mingcute:download-3-fill" />
                            </IconButton>
                            <Typography variant="body2">{`INV—${formatDateNotTime(invoice.due_date)}`}</Typography>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">{invoice.total?.toFixed(2)}</Typography>
                        </TableCell>
                        <TableCell>
                          <Chip label={invoice.paid ? 'Paid' : 'Pending'} color={invoice.paid ? 'success' : 'info'} />
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Button onClick={() => navigate('/user/profile?tabValue=1')}>View All Invoices</Button>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>

            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center" colSpan={3} sx={{ backgroundColor: 'transparent' }}>
                      Tickets
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {ticketsLoading ? (
                    <TableRow>
                      <TableCell>Loading..</TableCell>
                    </TableRow>
                  ) : ticketData?.tickets.length <= 0 ? (
                    <TableRow>
                      <TableCell>There's no active ticket</TableCell>
                    </TableRow>
                  ) : (
                    ticketData?.tickets.map((ticket) => (
                      <TableRow key={ticket.id}>
                        <TableCell>
                          <Box display={'flex'} alignItems={'center'}>
                            <Box
                              sx={{
                                transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                padding: '3px',
                                border: '1px dashed rgba(145, 158, 171, 0.2)',
                                borderRadius: '50%',
                                overflow: 'hidden',
                              }}
                            >
                              <Avatar src={ticket.attach_photo || '/assets/placeholder.svg'} />
                            </Box>
                            <Box ml={1}>
                              <Typography>{ticket.creator.name}</Typography>
                            </Box>
                          </Box>
                        </TableCell>
                        <TableCell>
                          {ticket.submit_date ? dayjs(ticket?.submit_date).format('MMM YYYY, hh:mm A') : 'N/A'}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell colSpan={3}>
                      <Stack alignItems={'flex-start'}>
                        <Button onClick={() => navigate('/support/tickets')}>View All Tickets</Button>
                        <Button
                          fullWidth
                          sx={{ mt: 1 }}
                          variant="contained"
                          onClick={() => navigate('/support/add-ticket')}
                        >
                          Create Ticket
                        </Button>
                      </Stack>
                    </TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>

      {selectDelete?.id && (
        <DeleteComponent
          handleCloseCheck={handleCloseCheck}
          openCheck={openCheck}
          name={'post'}
          deleted={() => deletePost({ spaceId: spaceId || currentSpaceId, postId: selectDelete?.id })}
        />
      )}

      {selectDeleteComment?.id && (
        <DeleteComponent
          handleCloseCheck={handleCloseCheckComment}
          openCheck={openCheckComment}
          name={'comment'}
          deleted={() =>
            deleteComment({
              spaceId: spaceId || currentSpaceId,
              postId: selectDeleteComment.post,
              commentId: selectDeleteComment.id,
            })
          }
        />
      )}

      <CustomAlert
        type={typeImage ? 'success' : 'error'}
        message={imageMessage}
        open={openImage}
        autoHideDuration={2000}
        setOpen={setOpenImage}
      />

      <CustomAlert
        type={typePost ? 'success' : 'error'}
        message={messagePost || 'Registered successfully.'}
        open={openPost}
        setOpen={setOpenPost}
      />

      <CustomAlert type={typeAdd ? 'success' : 'error'} message={messageAdd} open={openAlert} setOpen={setOpenAlert} />
    </>
  );
}

function Comment({
  comment,
  replyComment,
  handlePopoverCommentOpen,
  handleEditComment,
  handleDeleteComment,
  selectedCommentEdit,
  selectedCommentReply,
  postUpdateComment,
  postReplyComment,
  data,
  setSelectedCommentReply,
  isMediumScreen,
  selectedComment,
  anchorEl1,
  openComment,
  handlePopoverCommentClose,
  setSelectedCommentEdit,
  resultStats4,
  resultStats1,
  handleShowMoreComment,
  showMoreComment,
  level = 0, // Add a level property to track nesting level
}) {
  const { currentUserId, userId } = useSelector((item) => item.spaceReducer);

  const width = level === 0 ? '100%' : `calc(100% - ${level * 10}px)`;

  return (
    <div>
      {selectedCommentEdit?.id === comment.id ? (
        <Box mb={2} px={2} display="flex" alignItems="center">
          <Box
            sx={{
              display: 'inline-block',
              transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              padding: '3px',
              border: '1px dashed rgba(145, 158, 171, 0.2)',
              borderRadius: '50%',
              mr: 2,
            }}
          >
            <Avatar src={data?.profileImg} alt="Avatar" />
          </Box>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Write a comment..."
            size="small"
            value={selectedCommentEdit?.text}
            onChange={(e) => setSelectedCommentEdit({ ...selectedCommentEdit, text: e.target.value })}
            onKeyPress={(e) => {
              if (e.key === 'Enter' && !resultStats4?.isLoading) {
                e.preventDefault();
                postUpdateComment(comment);
              }
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    color="secondary"
                    aria-label="upload image"
                    disabled={resultStats4?.isLoading}
                    size="small"
                    onClick={() => postUpdateComment(comment)}
                  >
                    <Iconify icon="wpf:sent" />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ) : (
        <>
          <Stack flexDirection="row" key={comment.id} alignItems="center" justifyContent="space-between">
            <ListItem
              alignItems="flex-start"
              sx={{
                width,
                marginLeft: comment.parent_comment ? 'auto' : '',
                bgcolor: comment.parent_comment ? 'lightgray' : 'rgba(145, 158, 171, 0.08)',
                mb: 2,
                borderRadius: '12px',
                cursor: 'pointer',
                overflow: 'hidden',
                position: 'relative',
                '&:hover .additional-content': {
                  display: 'inline-flex',
                },
              }}
            >
              <ListItemIcon>
                <Box
                  sx={{
                    transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    padding: '3px',
                    border: '1px dashed rgba(145, 158, 171, 0.2)',
                    borderRadius: '50%',
                    overflow: 'hidden',
                  }}
                >
                  <Avatar src={comment.user.image} alt="Avatar" />
                </Box>
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography variant="subtitle1" color="text.primary">
                    {comment.user.name}
                  </Typography>
                }
                secondary={
                  <>
                    <Typography variant="body2" sx={{ wordWrap: 'break-word', whiteSpace: 'pre-line' }}>
                      {comment?.text?.length <= 250 ? (
                        comment?.text
                      ) : (
                        <>
                          <div dangerouslySetInnerHTML={{ __html: comment?.text.substring(0, 250) }} />
                          {!showMoreComment[comment?.id] ? (
                            '...'
                          ) : (
                            <div dangerouslySetInnerHTML={{ __html: comment?.text.substring(250) }} />
                          )}
                          {comment?.text?.length > 250 && (
                            <Typography
                              variant="body2"
                              sx={{
                                color: (theme) => theme.palette.action.active,
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                '&:hover': {
                                  opacity: 0.8,
                                },
                              }}
                              display={'inline'}
                              onClick={() => handleShowMoreComment(comment?.id)}
                            >
                              {showMoreComment[comment?.id] ? 'Show Less' : 'Show More'}
                            </Typography>
                          )}
                        </>
                      )}
                    </Typography>
                    <Typography
                      component={'span'}
                      onClick={() => replyComment(comment)}
                      variant="caption"
                      sx={{
                        color: (theme) => theme.palette.action.tertiaryColor,
                        mt: 0.5,
                        textDecoration: 'underline',
                        '&:hover': {
                          fontWeight: '600',
                        },
                      }}
                    >
                      {'Reply'}
                    </Typography>

                    <Typography
                      component={'span'}
                      ml={2}
                      variant="caption"
                      sx={{
                        color: (theme) => theme.palette.action.tertiaryColor,
                        mt: 0.5,
                      }}
                    >
                      <TimeAgo date={comment.created_at} />
                    </Typography>
                  </>
                }
              />

              {(+userId || +currentUserId) === (+comment?.user?.userId || +comment?.user) && (
                <IconButton
                  color="secondary"
                  className={`additional-content`}
                  // aria-controls={`post-actions-${comment.id}`}
                  onClick={(e) => handlePopoverCommentOpen(e, comment.id)}
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    right: '15px',
                    transform: 'translateY(-50%)',
                    display: isMediumScreen ? 'none' : 'inline-flex',
                  }}
                >
                  <Iconify icon={'eva:more-vertical-fill'} />
                </IconButton>
              )}
            </ListItem>
          </Stack>

          {/* Input field for adding a reply */}
          {selectedCommentReply?.id === comment.id && (
            <Box mb={2} width={'90%'} marginLeft={'auto'} display="flex" alignItems="center">
              <Box
                sx={{
                  transition: 'transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                  padding: '3px',
                  border: '1px dashed rgba(145, 158, 171, 0.2)',
                  borderRadius: '50%',
                  mr: 2,
                }}
              >
                <Avatar src={data?.profileImg} alt="Avatar" />
              </Box>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Write a reply..."
                size="small"
                // value={selectedCommentReply?.text}
                onChange={(e) => setSelectedCommentReply({ ...selectedCommentReply, text: e.target.value })}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' && !resultStats1?.isLoading) {
                    e.preventDefault();
                    postReplyComment();
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        color="secondary"
                        aria-label="upload image"
                        size="small"
                        onClick={postReplyComment}
                        disabled={resultStats1?.isLoading}
                      >
                        <Iconify icon="wpf:sent" />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          )}

          {comment.replies.map((reply) => (
            <Comment
              key={reply.id}
              comment={reply}
              replyComment={replyComment}
              handlePopoverCommentOpen={handlePopoverCommentOpen}
              handleEditComment={handleEditComment}
              handleDeleteComment={handleDeleteComment}
              selectedCommentEdit={selectedCommentEdit}
              selectedCommentReply={selectedCommentReply}
              setSelectedCommentReply={setSelectedCommentReply}
              postUpdateComment={postUpdateComment}
              postReplyComment={postReplyComment}
              selectedComment={selectedComment}
              setSelectedCommentEdit={setSelectedCommentEdit}
              data={data}
              anchorEl1={anchorEl1}
              openComment={openComment}
              handlePopoverCommentClose={handlePopoverCommentClose}
              isMediumScreen={isMediumScreen}
              level={level + 1} // Increment the nesting level
              resultStats1={resultStats1}
              resultStats4={resultStats4}
              handleShowMoreComment={handleShowMoreComment}
              showMoreComment={showMoreComment}
            />
          ))}

          <Popover
            open={openComment && selectedComment === comment.id}
            anchorEl={anchorEl1}
            onClose={handlePopoverCommentClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              sx: {
                p: 1,
                width: 140,
                '& .MuiMenuItem-root': {
                  px: 1,
                  typography: 'body2',
                  borderRadius: 0.75,
                },
              },
            }}
          >
            <List>
              {(+userId || +currentUserId) === (+comment?.user?.userId || +comment?.user) && (
                <>
                  <MenuItem onClick={() => handleEditComment(comment)}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                  </MenuItem>
                  <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteComment(comment)}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                  </MenuItem>
                </>
              )}
            </List>
          </Popover>
        </>
      )}
    </div>
  );
}

function CommentList({ comments, ...props }) {
  return (
    <div>
      {comments?.map((comment) => (
        <div key={comment.id}>
          <Comment comment={comment} {...props} />
        </div>
      ))}
    </div>
  );
}
