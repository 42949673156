// @mui
import { styled } from '@mui/material/styles';
import { ListItemIcon, ListItemButton, ListItem } from '@mui/material';

// ----------------------------------------------------------------------

export const StyledNavItem = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  padding: '4px 8px',
  margin: '0 0 4px',
  fontWeight: 500,
}));

export const StyledListItem = styled((props) => <ListItem disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,

  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  padding: '4px 8px',
  margin: '0 0 4px',
  fontWeight: 500,
}));

export const StyledNavItemIcon = styled(ListItemIcon)({
  width: 24,
  minWidth: 'auto',
  height: 24,
  marginRight: '14px',
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
