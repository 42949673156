import { Box, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { getNextOpenDateTime } from '../../utils/getNextDayOpenDateTime';
import Iconify from '../iconify/Iconify';

const VisitorForm = ({ register, errors, trigger, loading, setValue, isEdit, id, control }) => {
  const { openDaysTime, currentOpenDaysTime } = useSelector((item) => item.spaceReducer);

  useEffect(() => {
    if (!isEdit) {
      const minDateTime = getNextOpenDateTime(openDaysTime, currentOpenDaysTime);
      setValue('expected_visit_datetime', minDateTime);
    }
  }, []);

  const weekdays = openDaysTime?.length > 0 ? openDaysTime : JSON.parse(currentOpenDaysTime);

  const isWeekdayDisabled = (date) => {
    const dayOfWeek = date.day();

    const isDayOff = !weekdays?.some((day) => day.day === (dayOfWeek === 0 ? 7 : dayOfWeek));

    return isDayOff;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={isEdit ? 12 : 12}>
        {!isEdit ? (
          <Card sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Name"
                  fullWidth
                  {...register('name', { required: 'Name is required' })}
                  error={!!errors.name}
                  helperText={errors.name ? errors.name.message : ''}
                  onBlur={() => trigger('name')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Company Name (optional)"
                  fullWidth
                  {...register('companyName')}
                  error={!!errors.companyName}
                  helperText={errors.companyName ? errors.companyName.message : ''}
                  onBlur={() => trigger('companyName')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Email (optional)"
                  fullWidth
                  {...register('email', {
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  error={!!errors.email}
                  helperText={errors.email ? errors.email.message : ''}
                  onBlur={() => trigger('email')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Phone Number (optional)"
                  fullWidth
                  {...register('phone_number', {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: 'Please enter only numeric digits.',
                    },
                    minLength: {
                      value: 11,
                      message: 'Phone number must have at least 11 characters',
                    },
                    maxLength: {
                      value: 20,
                      message: 'Phone number must have less than 20 characters',
                    },
                  })}
                  error={!!errors.phone_number}
                  helperText={errors.phone_number ? errors.phone_number.message : ''}
                  onBlur={() => trigger('phone_number')}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label="Location (optional)"
                  fullWidth
                  {...register('location')}
                  // {...register('location', { required: 'Location is required' })}
                  error={!!errors.location}
                  helperText={errors.location ? errors.location.message : ''}
                  onBlur={() => trigger('location')}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {/* <TextField
                  label="Expected Visit Date and Time"
                  fullWidth
                  type="datetime-local"
                  {...register('expected_visit_datetime', { required: 'Expected Visit Date and Time is required' })}
                  error={!!errors.expected_visit_datetime}
                  helperText={errors.expected_visit_datetime ? errors.expected_visit_datetime.message : ''}
                  onBlur={() => trigger('expected_visit_datetime')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: getMinDate(),
                  }}
                /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="expected_visit_datetime"
                    control={control}
                    defaultValue={null} // Set your initial/default date here
                    rules={{ required: 'Expected Visit Date and Time is required' }}
                    render={({ field, fieldState }) => (
                      <>
                        <DateTimePicker
                          {...field}
                          // shouldDisableTime={shouldDisableTime}
                          shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                          sx={{ width: '100%' }}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              placeholder="Expected Visit Date and Time"
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                          )}
                          disablePast
                        />
                      </>
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Purpose of Visit (optional)"
                  fullWidth
                  {...register('purpose_of_visit')}
                  error={!!errors.purpose_of_visit}
                  helperText={errors.purpose_of_visit ? errors.purpose_of_visit.message : ''}
                  onBlur={() => trigger('purpose_of_visit')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label="Invited By (optional)"
                  fullWidth
                  {...register('person_visiting')}
                  error={!!errors.person_visiting}
                  helperText={errors.person_visiting ? errors.person_visiting.message : ''}
                  onBlur={() => trigger('person_visiting')}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  sx={{ float: 'right' }}
                  style={{ marginTop: '20px' }}
                  type="submit"
                >
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                    Register
                  </Stack>
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <Box sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Name"
                  fullWidth
                  {...register(`name_${id}`, { required: 'Name is required' })}
                  error={!!errors[`name_${id}`]}
                  helperText={errors[`name_${id}`] ? errors[`name_${id}`].message : ''}
                  onBlur={() => trigger(`name_${id}`)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Company Name (optional)"
                  fullWidth
                  {...register(`companyName_${id}`)}
                  error={!!errors[`companyName_${id}`]}
                  helperText={errors[`companyName_${id}`] ? errors[`companyName_${id}`].message : ''}
                  onBlur={() => trigger(`companyName_${id}`)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email (optional)"
                  fullWidth
                  {...register(`email_${id}`, {
                    // required: 'Email is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'Invalid email address',
                    },
                  })}
                  error={!!errors[`email_${id}`]}
                  helperText={errors[`email_${id}`] ? errors[`email_${id}`].message : ''}
                  onBlur={() => trigger(`email_${id}`)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Phone Number (optional)"
                  fullWidth
                  {...register(`phone_number_${id}`, {
                    pattern: {
                      value: /^[0-9]+$/,
                      message: 'Please enter only numeric digits.',
                    },
                    minLength: {
                      value: 11,
                      message: 'Phone number must have at least 11 characters',
                    },
                    maxLength: {
                      value: 20,
                      message: 'Phone number must have less than 20 characters',
                    },
                  })}
                  error={!!errors[`phone_number_${id}`]}
                  helperText={errors[`phone_number_${id}`] ? errors[`phone_number_${id}`].message : ''}
                  onBlur={() => trigger(`phone_number_${id}`)}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default VisitorForm;
