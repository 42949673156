import {
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Container,
  Grid,
  Link,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
// import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
// import resourceDayGridPlugin from "@fullcalendar/resource-daygrid";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import styled from '@emotion/styled';
import uuid from 'react-uuid';

import { processArray } from '../utils/processArray';
import { formatDateNotTime } from '../utils/displayDateTime';
import BookingCheck from '../components/Booking/BookingCheck';

import {
  useGetBookedTimingsQuery,
  useGetBookingsQuery,
  useGetMeetingRoomsQuery,
  useGetPackagesQuery,
  useViewHotDeskQuery,
} from '../Slices/spaceApi';
import EnquiryCheck from '../components/Booking/EnquiryCheck';
import Iconify from '../components/iconify';
import Loader from '../components/Loader/Loader';

const StyledTabs = styled((props) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
  '& .MuiTabs-indicatorSpan': {},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  minWidth: 'auto',
  '&.Mui-focusVisible': {},
}));

function BookingEnquiryPage() {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'));
  const navigate = useNavigate();

  const [value, setValue] = useState(0);
  const [week, setWeek] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    spaceId,
    currentSpaceId,
    currentCurrency,
    currency,
    currentOpenDays,
    openDaysTime,
    openDays,
    billingOption,
    currentBillingOption,
    currentBookingConfiguration,
    bookingConfiguration,
    slotSize,
    currentSlotSize,
  } = useSelector((item) => item.spaceReducer);

  console.log(slotSize, currentSlotSize, 'check?');
  console.log(bookingConfiguration, currentBookingConfiguration);

  const {
    data: hotDesksAvailablity,
    isLoading: hotdeskLoading,
    isFetching,
  } = useViewHotDeskQuery({
    spaceId: spaceId || currentSpaceId,
    week,
  });

  const { data: FlexBookings, FlexBookingsLoading } = useGetBookingsQuery({
    spaceId: spaceId || currentSpaceId,
    type: 3,
  });
  const { data: ResourceBookings, ResourceBookingsLoading } = useGetBookingsQuery({
    spaceId: spaceId || currentSpaceId,
    type: 1,
  });
  const [editStates, setEditStates] = useState([false, false]);
  const [flexEvents, setFlexEvents] = useState([]);
  const [resourceEvents, setResourceEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      const formattedEvents = ResourceBookings?.bookingss?.map((event) => ({
        id: event?.id,
        title: event.meetingRoom?.title,
        start: new Date(event?.startTime * 1000),
        end: new Date(event?.endTime * 1000),
        capacity: event?.capacity,
        paidByCard: event.paidByCard,
        price: event?.meetingRoom?.price,
        member: event?.member?.id,
        userName: event?.member?.name,
        meetingRoom: event?.meetingRoom?.id,
        resourceId: event?.meetingRoom?.id,
        room: event,
        cancel: event?.cancel,
        approved: event?.approved,
      }));

      setResourceEvents(formattedEvents || []);
    };
    fetchEvents();
  }, [ResourceBookings]);

  useEffect(() => {
    const fetchEvents = async () => {
      const formattedEvents = FlexBookings?.bookingss?.map((event) => ({
        id: event?.id,
        title: event.meetingRoom?.title,
        start: new Date(event?.startTime * 1000),
        end: new Date(event?.endTime * 1000),
        capacity: event?.capacity,
        paidByCard: event.paidByCard,
        price: event?.meetingRoom?.price,
        member: event?.member?.id,
        userName: event?.member?.name,
        meetingRoom: event?.meetingRoom?.id,
        resourceId: 0,
        room: event,
        cancel: event?.cancel,
        approved: event?.approved,
      }));

      setFlexEvents(formattedEvents || []);
    };
    fetchEvents();
  }, [FlexBookings]);
  const eventContent = (eventInfo) => {
    const start = new Date(eventInfo.event.start).toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    const end = new Date(eventInfo.event.end).toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    });
    const timeRange = `${start} - ${end}`;

    const color = eventInfo.event.id === '-1' ? '#378006' : '#ff0000';

    return (
      <Box
        className="fc-event-dot event-container"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography variant="body1" className="fc-event-title event-title" color="blue">
          {eventInfo?.event?.id === '-1' ? 'Not Available' : eventInfo?.event?.title}
        </Typography>
        <Typography variant="body1" className="fc-event-time event-time" color="blue">
          {timeRange}
        </Typography>
        {eventInfo?.event?.id === '-1' ? (
          ''
        ) : (
          <Box sx={{ alignSelf: 'center' }}>
            <Badge
              badgeContent={
                eventInfo.event.extendedProps.approved === 1
                  ? 'Approved'
                  : eventInfo.event.extendedProps.approved === 2
                  ? 'Not Approved'
                  : 'Pending'
              }
              color={
                eventInfo.event.extendedProps.approved === 1
                  ? 'success'
                  : eventInfo.event.extendedProps.approved === 2
                  ? 'error'
                  : 'primary'
              }
              sx={{ maxWidth: '100px' }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const currentDate = new Date();
  const offsetInDays = (week - 1) * 30; // Calculate the offset in days based on the week
  currentDate.setDate(currentDate.getDate() + offsetInDays); // Set the currentDate based on the offset

  function formatDate(date) {
    // eslint-disable-next-line no-restricted-globals
    if (!(date instanceof Date && !isNaN(date))) {
      return 'Invalid Date';
    }

    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }

  const [selectBookingEnquiry, setselectBookingEnquiry] = useState();
  const [selectEditBooking, setselectEditBooking] = useState();

  const handleType = ({ hotDeskDate }) => {
    setType({ title: 'Book Hotdesk', isButton: true, value: 2 });
    setOpenFilter(true);
    setselectBookingEnquiry({ room: { title: 'Hot Desk', capacity: 1 }, id: Date.now(), hotDeskDate });
  };

  const handleEventClick = (clickInfo) => {
    if (dayjs(resultCount?.maxDate).unix() >= dayjs(clickInfo.event.startStr).unix()) {
      if (clickInfo.event.extendedProps.available) {
        handleType({ hotDeskDate: clickInfo.event.startStr });
      }
    }
  };

  const location = useLocation();

  // const { data: spaceTypesData, isLoading: spaceLoading } = useGetSpaceTypesQuery();

  const [type, setType] = useState({ title: 'Meeting Rooms', value: 1 });

  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const { data, isLoading } = useGetMeetingRoomsQuery({ spaceId: spaceId || currentSpaceId });

  console.log(data, 'meetingRooms');

  // const { data: getSpaceTypes, isLoading: spaceTypesLoading } = useGetSpaceTypesQuery();

  const [locations, setLocations] = useState([]);
  const [spaceTypes, setSpaceTypes] = useState();
  const [selectedSpaceType, setSelectedSpaceType] = useState();
  const [selectedSpace, setSelectedSpace] = useState();
  const [meetRoom, setMeetRoom] = useState('');

  const [businessHours, setBusinessHours] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const uniqueSpaces = Array?.from(new Set(data.map((item) => item.space.id))).map((id) => {
        const spaceData = data?.find((item) => item.space.id === id);
        return {
          id: spaceData?.space?.id,
          name: spaceData?.space?.name,
          flexDesk: spaceData?.space?.flexDesk,
          spaceTimings: spaceData?.space?.openDays,
        };
      });

      setLocations(uniqueSpaces);
      setSelectedSpace(uniqueSpaces?.[0]);
    }
  }, [data]);

  useEffect(() => {
    if (data?.length > 4) {
      const filteredData = data?.filter((item) => item?.spaceType !== null && item?.spaceType !== 19);

      const uniqueSpaces = Array.from(new Set(filteredData?.map((item) => item?.spaceTypeObj?.id))).map((id) => {
        const spaceData = filteredData.find((item) => item.spaceTypeObj?.id === id);

        return { id: spaceData.spaceTypeObj?.id, name: spaceData.spaceTypeObj?.name };
      });

      setSpaceTypes(uniqueSpaces);

      setSelectedSpaceType(uniqueSpaces?.[0]);
    }
  }, [data]);

  const {
    data: BookedTimings,
    isLoading: bookingsLoading,
    // isFetching: availabilityFetching,
  } = useGetBookedTimingsQuery(
    {
      spaceId: selectedSpace?.id,
      // week,
    },
    {
      skip: !selectedSpace?.id,
    }
  );

  const [bookedTimingsEvents, setBookedTimingsEvents] = useState([]);
  useEffect(() => {
    const fetchEvents = async () => {
      const formattedEvents = BookedTimings?.map((event, index) => ({
        id: -1,
        // title: event.meetingRoom?.title,
        start: new Date(event?.startTime * 1000),
        end: new Date(event?.endTime * 1000),
        // capacity: event?.capacity,
        // paidByCard: event.paidByCard,
        // price: event?.meetingRoom?.price,
        // member: event?.member?.id,
        // userName: event?.member?.name,
        // meetingRoom: event?.meetingRoom?.id,
        resourceId: event?.meetingRoom?.id,
        color: '#f7d775',
        // display: 'inverse-background',

        // cancel: event?.cancel,
        // approved: event?.approved,
      }));

      setBookedTimingsEvents(formattedEvents || []);
    };
    fetchEvents();
  }, [BookedTimings]);

  useEffect(() => {
    // Parse the tabValue from the query parameters and set the initial value accordingly
    const searchParams = new URLSearchParams(location.search);
    // eslint-disable-next-line camelcase
    const created_at = searchParams.get('created_at');
    setMeetRoom(created_at);
  }, [location.search]);

  const handleEnquiry = (event) => {
    console.log(event, 'yay?');
    // setOpenFilter(true);
    // setselectBookingEnquiry({ room: event, id: event.id });
    // console.log('EVENT IS', event.event.id);

    if (event.event.id === '-1') {
      return;
    }

    const startDate = new Date(event?.event?.start);
    const endDate = new Date(event?.event?.end);

    const utcStartDate = new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, 16);
    const utcEndDate = new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000).toISOString().slice(0, 16);
    if (event?.resource?.id !== '0') {
      setType({ title: 'Meeting Roomsdd', value: 1 });
      setselectEditBooking(event.event.extendedProps.room);
    } else {
      setType({ title: 'Flexible Desk', value: 3 });
      setselectBookingEnquiry({
        start: event?.start,
        end: event?.end,
        room: {
          id: -1,
          title: 'Flexible Desk',
          capacity: 1,
        },
        id: -1,
      });
    }
    setOpenFilter(true);
  };
  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':');
    return parseInt(hours, 10) * 60 + parseInt(minutes, 10);
  };

  const formatSlotTime = (date) => {
    const hours = `0${date.getHours()}`.slice(-2);
    const minutes = `0${date.getMinutes()}`.slice(-2);
    const seconds = `0${date.getSeconds()}`.slice(-2);
    return `${hours}:${minutes}:${seconds}`;
  };
  const convertToTimeString = (date) => {
    const timeString = date.toLocaleTimeString('en-US', { hour12: false });
    return timeString;
  };

  const allowDateSelection = (event) => {
    const selectedStart = event.start;
    const selectedEnd = event.end;

    // console.log(event.start , "check?")
    const businessHoursForDay = businessHours?.find((hours) => hours.daysOfWeek.includes(selectedStart.getDay()));
    if (businessHoursForDay) {
      const businessStartTime = timeToMinutes(businessHoursForDay.startTime);

      const businessEndTime = timeToMinutes(businessHoursForDay.endTime);

      const selectedStartTime = timeToMinutes(formatSlotTime(selectedStart));
      const selectedEndTime = timeToMinutes(formatSlotTime(selectedEnd));
      const formattedTimes = {
        start: convertToTimeString(selectedStart),
        end: convertToTimeString(selectedEnd),
      };

      const isRangeValid = selectedStartTime >= businessStartTime && selectedEndTime <= businessEndTime;

      if (isRangeValid || (Number.isNaN(businessStartTime) && Number.isNaN(businessEndTime))) {
        if (event?.resource?.id !== '0') {
          setType({ title: 'Meeting Roomsdd', value: 1 });
          setselectBookingEnquiry({
            start: event?.start,
            end: event?.end,
            room: {
              id: event.resource.id,
              title: event.resource.title,
              bookable: event?.resource?.extendedProps?.bookable,
              capacity: event?.resource?.extendedProps?.capacity,
              imageUrls: event?.resource?.extendedProps?.imageUrls,
              imageUrls_50: event?.resource?.extendedProps?.imageUrls_50,
              inclusiveTax: event?.resource?.extendedProps?.inclusiveTax,
              price: event?.resource?.extendedProps?.price,
              space: event?.resource?.extendedProps?.space,
              spaceType: event?.resource?.extendedProps?.spaceType,
              spaceTypeObj: event?.resource?.extendedProps?.spaceTypeObj,
            },
            id: event.resource.id,
          });
        } else {
          setType({ title: 'Flexible Desk', value: 3 });
          setselectBookingEnquiry({
            start: event?.start,
            end: event?.end,
            room: {
              id: -1,
              title: 'Flexible Desk',
              capacity: 1,
            },
            id: -1,
          });
        }
        setOpenFilter(true);
        return true;
      }

      return false;
    }

    return false;

    // if (event?.resource?.id !== '0') {
    //   setType({ title: 'Meeting Roomsdd', value: 1 });
    //   setselectBookingEnquiry({
    //     start: event?.start,
    //     end: event?.end,
    //     room: {
    //       id: event?.resource?.id,
    //       title: event?.resource?.title,
    //       bookable: event?.resource?.extendedProps?.bookable,
    //       capacity: event?.resource?.extendedProps?.capacity,
    //       imageUrls: event?.resource?.extendedProps?.imageUrls,
    //       imageUrls_50: event?.resource?.extendedProps?.imageUrls_50,
    //       inclusiveTax: event?.resource?.extendedProps?.inclusiveTax,
    //       price: event?.resource?.extendedProps?.price,
    //       space: event?.resource?.extendedProps?.space,
    //       spaceType: event?.resource?.extendedProps?.spaceType,
    //       spaceTypeObj: event?.resource?.extendedProps?.spaceTypeObj,
    //     },
    //     id: event.resource.id,
    //   });
    // } else {
    //   setType({ title: 'Flexible Desk', value: 3 });
    //   setselectBookingEnquiry({
    //     start: event?.start,
    //     end: event?.end,
    //     room: {
    //       id: -1,
    //       title: 'Flexible Desk',
    //       capacity: 1,
    //     },
    //     id: -1,
    //   });
    // }
    // setOpenFilter(true);
  };

  const [openFilter, setOpenFilter] = useState(false);

  const handleCloseFilter = () => {
    setOpenFilter(false);
    setselectEditBooking(null);
  };
  const [filteredData, setFilteredData] = useState([]);

  const [bookingResources, setBookingResources] = useState(filteredData);

  useEffect(() => {
    let extractedResources = [];

    if (selectedSpace?.flexDesk) {
      if (Array.isArray(filteredData)) {
        extractedResources?.push({
          id: 0,
          title: 'Flex Desk',
          image:
            'https://dsfun9g7xo5tj.cloudfront.net/media/catalog/product/cache/f0e7f6617464f055bec783f793af0254/f/l/flex-55_1.jpg',
        });

        extractedResources = extractedResources?.concat(filteredData);
      } else {
        extractedResources?.push({
          id: 0,
          title: 'Flex Desk',
          image:
            'https://dsfun9g7xo5tj.cloudfront.net/media/catalog/product/cache/f0e7f6617464f055bec783f793af0254/f/l/flex-55_1.jpg',
        });
      }
      setBookingResources(extractedResources);
    } else {
      setBookingResources(filteredData);
    }
    // let extractedResources = [];

    // if (selectedSpace?.flexDesk) {
    //   // Check if filteredData is an array and push Flex Desk resource
    //   if (Array.isArray(filteredData)) {
    //     extractedResources.push({
    //       id: 0,
    //       title: 'Flex Desk',
    //       image:
    //         'https://dsfun9g7xo5tj.cloudfront.net/media/catalog/product/cache/f0e7f6617464f055bec783f793af0254/f/l/flex-55_1.jpg',
    //     });

    //     // Concatenate filteredData to extractedResources
    //     extractedResources = extractedResources.concat(filteredData);
    //   } else {
    //     // If filteredData is not an array, push Flex Desk resource only
    //     extractedResources.push({
    //       id: 0,
    //       title: 'Flex Desk',
    //       image:
    //         'https://dsfun9g7xo5tj.cloudfront.net/media/catalog/product/cache/f0e7f6617464f055bec783f793af0254/f/l/flex-55_1.jpg',
    //     });
    //   }
    //   setBookingResources(extractedResources);
    // } else if(Array.isArray(selectedSpace)) {
    //   // Check if selectedSpace is an array and setBookingResources accordingly

    //     setBookingResources(selectedSpace);
    //  } else {
    //     setBookingResources([selectedSpace]);
    //   }

    const businessHours = selectedSpace?.spaceTimings?.map((day) => {
      const startTime = new Date(day.start_time * 1000);
      const endTime = new Date(day.end_time * 1000);

      // const formatTime = (date) => {
      //   const hours = ('0' + date.getHours()).slice(-2);
      //   const minutes = ('0' + date.getMinutes()).slice(-2);
      //   return `${hours}:${minutes}`;
      // };
      const formatTime = (date) => {
        const hours = `0${date.getHours()}`.slice(-2);
        const minutes = `0${date.getMinutes()}`.slice(-2);
        return `${hours}:${minutes}`;
      };
      // Check if both start and end times are '00:00'
      const isFullDay = formatTime(startTime) === '00:00' && formatTime(endTime) === '00:00';

      return {
        daysOfWeek: [day.day], // Map the "day" value to the corresponding day of the week
        startTime: isFullDay ? '' : formatTime(startTime),
        endTime: isFullDay ? '' : formatTime(endTime),
      };
    });
    setBusinessHours(businessHours);
  }, [filteredData, selectedSpace]);

  const dateRanges = [];
  const countRanges = [];
  const flexibleRanges = [];

  packages?.assignedPackages?.forEach((obj) => {
    // eslint-disable-next-line camelcase
    const { status, renew_date, remaining_hours, purchase } = obj;

    // Check if the status is 1
    if (status === 1) {
      if (purchase && purchase?.dayPassCount >= 1) {
        const purchaseData = {
          minDate: dayjs(),
          maxDate: dayjs(renew_date).subtract(1, 'day'),
          dayPass: purchase?.dayPassCount,
        };
        countRanges.push(purchaseData);
      }

      if (purchase && purchase?.flexibleHoursCount >= 1) {
        const purchaseData = {
          minDate: dayjs(),
          maxDate: dayjs(renew_date).subtract(1, 'day'),
          dayPass: purchase?.flexibleHoursCount,
        };
        flexibleRanges.push(purchaseData);
      }
      // Loop through each remaining_hours item
      // eslint-disable-next-line camelcase
      remaining_hours.forEach((hours) => {
        // Loop through each meetingRoom
        hours.meetingRoom.forEach((room) => {
          const roomId = room.id;
          const roomData = {
            minDate: dayjs(),
            maxDate: dayjs(renew_date).subtract(1, 'day'),
            room_id: roomId,
            hours: hours?.hours,
          };
          dateRanges.push(roomData);
        });
      });
    }
  });

  const filteredDateRanges = {};

  dateRanges.forEach((roomData) => {
    // eslint-disable-next-line camelcase
    const { minDate, maxDate, room_id, hours } = roomData;

    // eslint-disable-next-line camelcase
    if (!filteredDateRanges[room_id] || maxDate > filteredDateRanges[room_id].maxDate) {
      // eslint-disable-next-line camelcase
      filteredDateRanges[room_id] = { minDate, maxDate, room_id, hours };
    }
  });

  const resultCount = processArray(countRanges);
  const resultFlexbile = processArray(flexibleRanges);

  // const resultArray = Object.values(filteredDateRanges);

  useEffect(() => {
    if (selectedSpace || selectedSpaceType) {
      const filteredRooms = data?.filter((item) => {
        const spaceCondition = !selectedSpace || item.space.id === selectedSpace.id;
        const spaceTypeCondition = !selectedSpaceType || item?.spaceTypeObj?.id === selectedSpaceType?.id;

        return spaceCondition && spaceTypeCondition;
      });
      setFilteredData(filteredRooms);
    } else {
      setFilteredData(data);
    }
  }, [data, selectedSpace, selectedSpaceType]);

  const [isFlexDeskAvailable, setIsFlexDeskAvailable] = useState(false);
  useEffect(() => {
    // Filter the data array based on the selected space ID
    const filteredData = data?.filter((item) => item.space.id === selectedSpace?.id);

    // Check if any filtered item has a spaceType of 20
    const hasFlexDesk = filteredData?.some((item) => item.spaceType === 20);

    // Update the state based on the result
    setIsFlexDeskAvailable(hasFlexDesk);
  }, [selectedSpace, data]);
  const totalRemainingDayPasses = packages?.assignedPackages
    ?.filter((item) => item.purchase && item.status === 1) // Ensure purchase exists and has status 1
    .reduce((total, item) => total + item.purchase.dayPassCount, 0); // Sum up dayPassCount

  const nextWeek = () => {
    setWeek(week + 1);
  };

  const prevWeek = () => {
    setWeek(week - 1);
  };

  const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());

  const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 30);

  const events = Array.from({ length: 30 }, (_, i) => {
    const date = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + +i);

    const availabilityObj = hotDesksAvailablity?.find((item) => item.date === formatDate(date));

    const title = availabilityObj
      ? availabilityObj.available && dayjs(resultCount?.maxDate).unix() >= dayjs(date).unix()
        ? 'Book'
        : !availabilityObj.available && dayjs(resultCount?.maxDate).unix() >= dayjs(date).unix()
        ? 'Occupied'
        : `No Pass for ${formatDateNotTime(dayjs(date).format(''))}`
      : 'Occupied';
    return {
      title,
      start: date,
      // allDay: true,
      available: availabilityObj?.available || false,
      count: availabilityObj?.count || 1,
    };
  });

  const totalRemainingFlexibleHours = packages?.assignedPackages
    ?.filter((item) => item.purchase && item.status === 1) // Ensure purchase exists and has status 1
    .reduce((total, item) => total + item.purchase.flexibleHoursCount, 0); // Sum up dayPassCount

  if (isLoading || pacakgeLoading) {
    return <Loader />;
  }

  const handleResourceLabelContent = (arg) => {
    const imageSrc = arg?.resource?.extendedProps?.imageUrls || arg?.resource?.extendedProps?.image;
    if (imageSrc) {
      return (
        <div
          style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px', width: '100%' }}
        >
          <Avatar
            src={imageSrc}
            alt={arg.resource.title}
            sx={{ width: '100%', height: 'auto', maxWidth: '160px', maxHeight: '160px', borderRadius: 1 }}
            // sx={{ width: '100%', height: 'auto', maxWidth: '210px', borderRadius: 1 }}
          />

          <Typography variant="body1" align="center" sx={{ marginTop: 1, width: '100%', wordBreak: 'break-word' }}>
            {arg.resource.title}
          </Typography>
          <Stack flexDirection={'row'} alignItems={'center'} mt={1}  flexWrap={'wrap'}>
            <Iconify icon="heroicons:users" sx={{ mr: 0.5 }} />
            {arg.resource.id === '0' ? 1 : arg.resource.extendedProps.capacity}
          </Stack>
          {/* <Box>
              <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} mb={2}>
                <Typography variant="h6">{arg.resource.title}</Typography>

                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Iconify icon="heroicons:users" sx={{ mr: 0.5 }} />
                  {arg.resource.extendedProps.capacity}
                </Stack>
              </Stack>

              <Typography variant="subtitle2">
                                {!resultArray?.find((room) => room.room_id === parseInt(item.id, 10)) ? (
                                  <Typography variant="subtitle2">
                                    {`Price ${parseInt(item?.price, 10).toFixed(2)}  ${currency || currentCurrency}`}
                                  </Typography>
                                ) : +billingOption === 1 || +currentBillingOption === 1 ? (
                                  `Remaining hours included package ${
                                    resultArray.find((packages) => packages.room_id === item.id)?.hours
                                  } till ${formatDateNotTime(
                                    dayjs(resultArray.find((packages) => packages.room_id === item.id)?.maxDate).format(
                                      'YYYY-MM-DDTHH:mm:ss.SSSZ'
                                    )
                                  )}`
                                ) : (
                                  `Remaining credits included package ${
                                    resultArray.find((packages) => packages.room_id === item.id)?.hours
                                  } till ${formatDateNotTime(
                                    dayjs(resultArray.find((packages) => packages.room_id === item.id)?.maxDate).format(
                                      'YYYY-MM-DDTHH:mm:ss.SSSZ'
                                    )
                                  )}`
                                )}
                              </Typography>
            </Box> */}
        </div>
      );
    }
    return <Typography variant="body1">{arg.resource.title}</Typography>;
  };

  const getEventColor = (eventInfo) => {
    alert('Event infor in COLOR IS', eventInfo);
    // Your logic to determine the color based on event ID
    if (eventInfo.event.id === '-1') {
      return '#ff0000';
    }
    // Default color for other events
    return '#ccc';
  };

  return (
    <>
      <Helmet>
        <title> Booking </title>
      </Helmet>

      <Container>
        <Box />

        <StyledTabs
          value={value}
          onChange={handleChange}
          sx={{
            mb: 5,
            '& .MuiTabs-indicator': {
              backgroundColor: (theme) => theme.palette.text.primary,
            },
            '&.Mui-focusVisible': {
              backgroundColor: '',
              outline: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary, // Active tab text color
                backgroundColor: 'transparent',

                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="iconoir:house-rooms" sx={{ marginRight: '10px' }} />
                Resources
              </Typography>
            }
          />

          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary,
                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            // disabled={totalRemainingDayPasses === 0}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="mingcute:desk-line" sx={{ marginRight: '10px' }} />
                Hot Desks
              </Typography>
            }
          />
        </StyledTabs>

        {!meetRoom ? (
          <>
            {value === 0 ? (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    {locations?.length > 0 && (
                      <Autocomplete
                        options={locations}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={selectedSpace}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedSpace(newValue);
                          } else {
                            setSelectedSpace(locations?.[0]);
                          }
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Space" />}
                      />
                    )}
                  </Grid>

                  <Grid item xs={6}>
                    {spaceTypes?.length > 0 && (
                      <Autocomplete
                        options={spaceTypes}
                        getOptionLabel={(option) => option.name}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={selectedSpaceType}
                        onChange={(event, newValue) => {
                          setSelectedSpaceType(newValue);
                        }}
                        renderInput={(params) => <TextField {...params} label="Select Space Type" />}
                      />
                    )}
                  </Grid>
                </Grid>

                <Grid item xs={12} className="resource_calendar" mt={3}>
                  {!FlexBookingsLoading && flexEvents && (
                    <FullCalendar
                      schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, resourceTimeGridPlugin]}
                      resources={bookingResources}
                      initialView="resourceTimeGridDay"
                      width="1500px"
                      // events={flexEvents?.filter((event) => !event.cancel)}
                      events={flexEvents?.concat(resourceEvents)?.concat(bookedTimingsEvents)}
                      eventContent={eventContent}
                      headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'resourceTimeGridDay,listWeek',
                      }}
                      views={{
                        resourceTimeGridDay: {
                          buttonText: 'Day',
                        },
                        listWeek: {
                          buttonText: 'List',
                        },
                      }}
                      // customButtons={customButtons}
                      resourceLabelContent={handleResourceLabelContent}
                      selectable
                      // eventDrop={handleEventDropResize}
                      // eventResize={handleEventDropResize}
                      eventClick={handleEnquiry}
                      editable
                      aspectRatio={1.5}
                      height="auto"
                      // datesSet={handleViewRender}
                      // editable={false}
                      slotLabelInterval={{ hours: 0.5 }}
                      slotDuration="00:30:00"
                      contentHeight="auto"
                      select={(selectInfo) => allowDateSelection(selectInfo)}
                      slotLabelContent={(slotInfo) => (
                        <div
                          style={{
                            height: '80px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          {slotInfo.text}
                        </div>
                      )}
                      businessHours={businessHours ?? []}
                    />
                  )}
                </Grid>
              </>
            ) : (
              <Grid container spacing={2}>
                {isFetching ? (
                  <Grid item xs={12}>
                    <Typography paragraph>Loading...</Typography>
                  </Grid>
                ) : totalRemainingDayPasses > 0 ? (
                  <>
                    <Grid item xs={12} className="hotdesk_calendar">
                      <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGrid"
                        headerToolbar={false}
                        events={events}
                        height={'auto'} // Set height to 100% for responsiveness
                        initialDate={currentDate}
                        validRange={{ start: currentDate, end: endDate }}
                        visibleRange={{ start: currentDate, end: endDate }}
                        eventClick={handleEventClick}
                        eventContent={(eventInfo) =>
                          eventInfo.event.extendedProps.available && (
                            <Chip
                              label={eventInfo.event.title}
                              color={eventInfo.event.extendedProps.available ? 'success' : 'error'}
                              sx={{
                                width: '100%',
                                cursor: eventInfo.event.extendedProps.available ? 'pointer' : 'default',
                              }}
                            />
                          )
                        }
                        dayHeaderContent={(arg) => (
                          <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                            {arg.date.toLocaleDateString('en-US', { weekday: 'short' })}
                          </div>
                        )}
                        dayCellContent={(arg) => {
                          const availabilityObj = hotDesksAvailablity?.find(
                            (item) => item.date === formatDate(arg.date)
                          );
                          const isAvailable = availabilityObj ? availabilityObj.available : false;
                          const count = availabilityObj?.count;
                          return (
                            <Box
                              sx={{
                                display: 'flex',
                                mt: 2,
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%',
                              }}
                            >
                              <Box>{arg.date.toLocaleDateString('en-US', { day: 'numeric', month: 'short' })}</Box>
                              <Typography color="primary">{`${count || ''} ${
                                count === 1 ? 'seat' : count === 0 ? 'No seat' : 'seats'
                              }  ${isMediumScreen && count > 0 ? 'Available' : ''}`}</Typography>
                            </Box>
                          );
                        }}
                        dayCellClassNames={['day-cell']}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Stack flexDirection={'row'} justifyContent={'space-between'}>
                        <Link
                          color={'secondary'}
                          onClick={() => (week === 1 ? undefined : prevWeek())}
                          underline="hover"
                          sx={{
                            opacity: week === 1 ? 0.7 : 1,
                            alignItems: 'center',
                            display: 'flex',
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          <Iconify icon="material-symbols:chevron-left" />
                          Prev Month
                        </Link>

                        <Link
                          color={'secondary'}
                          onClick={nextWeek}
                          underline="hover"
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            '&:hover': {
                              cursor: 'pointer',
                            },
                          }}
                        >
                          Next Month
                          <Iconify icon="material-symbols:chevron-right" />
                        </Link>
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" paragraph>
                      You don't have any available day pass yet please purchase{' '}
                      <Typography
                        sx={{
                          color: (theme) => theme.palette.action.tertiaryColor,
                          display: 'inline-block',
                          cursor: 'pointer',
                        }}
                        onClick={() => navigate('/products?hotDesk=true')}
                      >
                        package
                      </Typography>{' '}
                      to get day passes.
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        ) : (
          <Stack justifyContent={'center'}>
            <Typography variant="h5" sx={{ textAlign: 'center', mb: 1 }}>
              Thank You for reserving in our space
            </Typography>

            <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
              We are waiting for you at&nbsp;
              {formatDate(dayjs.unix(meetRoom).format('YYYY-MM-DDTHH:mm:ss.SSSSSS'))}
            </Typography>
          </Stack>
        )}

        {selectBookingEnquiry && (
          <EnquiryCheck
            openFilter={openFilter}
            onCloseFilter={handleCloseFilter}
            {...selectBookingEnquiry}
            type={type?.value}
            publicRoom={false}
          />
        )}

        {selectEditBooking && (
          <BookingCheck
            openFilter={openFilter}
            onCloseFilter={handleCloseFilter}
            name={selectEditBooking?.meetingRoom?.title || 'Hot Desk'}
            meetingRoomImage={selectEditBooking?.meetingRoomImage}
            price={selectEditBooking?.meetingRoom?.price}
            meetingRoomCapacity={selectEditBooking?.capacity}
            type={type?.value}
            startDateTime={selectEditBooking?.startTime}
            endDateTime={selectEditBooking?.endTime}
            facilities={selectEditBooking?.facilities}
            description={selectEditBooking?.description}
            id={selectEditBooking?.id}
            meetingRoomId={selectEditBooking?.meetingRoom?.id}
            inclusiveTax={selectEditBooking?.meetingRoom?.inclusiveTax}
            location={selectEditBooking?.location}
            editStates={editStates}
            idx={selectEditBooking?.id}
            setEditStates={setEditStates}
            spaceType={selectEditBooking?.meetingRoom?.spaceType}
          />
        )}
      </Container>
    </>
  );
}

export default BookingEnquiryPage;
