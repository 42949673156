import React, { useEffect, useRef, useState } from 'react';
import {
  Container,
  Card,
  Grid,
  Typography,
  Stack,
  Box,
  Avatar,
  Chip,
  Divider,
  IconButton,
  Popover,
  MenuItem,
  Button,
  CardMedia,
  CardContent,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

import Carousel from 'react-material-ui-carousel';
import Iconify from '../components/iconify';
import { formatDate } from '../utils/displayDateTime';
import Loader from '../components/Loader/Loader';
import { useGetOfferQuery, useGetPackagesQuery, useGetPartnersQuery } from '../Slices/spaceApi';
import { ConfirmComponent } from '../utils/Alert';

function CommunitiesPage() {
  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);

  const { data, isLoading } = useGetPartnersQuery({ spaceId: spaceId || currentSpaceId });

  const [activeIndex, setActiveIndex] = useState(0);
  const [activeParnter, setActivePartner] = useState(data?.partners?.[0]);

  useEffect(() => {
    setActivePartner(data?.partners?.[0]);
  }, [data]);

  const handleCarouselChange = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    const check = data?.partners[activeIndex];
    setActivePartner(check);
  }, [activeIndex]);

  const {
    data: offerData,
    isLoading: offersLoading,
    refetch,
  } = useGetOfferQuery({
    spaceId: spaceId || currentSpaceId,
    partnerId: activeParnter?.id,
  });

  useEffect(() => {
    refetch();
  }, [activeParnter]);

  const [dailog, setDailog] = useState(false);
  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = packages?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  const navigate = useNavigate();

  useEffect(() => {
    if (!checkOldUser && !pacakgeLoading) {
      setDailog(true);
      navigate('/user/account');
    } else {
      setDailog(false);
    }
  }, [checkOldUser, pacakgeLoading]);

  if (isLoading || pacakgeLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Partners | {name || currentName}</title>
      </Helmet>

      {/* <ConfirmComponent openCheck={dailog} /> */}

      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Partners & Offers
        </Typography>

        <Grid container spacing={2}>
          <Grid item xs={12}>
            {data?.partners?.length > 0 ? (
              <Carousel autoPlay={false} index={activeIndex} onChange={handleCarouselChange}>
                {data?.partners?.map((item, index) => (
                  <>
                    <Card sx={{ minHeight: '480px' }}>
                      <PartnerCarouselItem key={index} partner={item} />
                    </Card>
                  </>
                ))}
              </Carousel>
            ) : (
              <Typography variant="h6" paragraph>
                There's no Partners
              </Typography>
            )}
          </Grid>
        </Grid>

        {offersLoading ? (
          <Typography variant="h4" sx={{ my: 5 }}>
            Loading...
          </Typography>
        ) : (
          <>
            <Typography variant="h4" sx={{ my: 5 }}>
              All Offers for {activeParnter?.name}
            </Typography>

            <Grid container spacing={2}>
              {offerData?.offers?.length > 0 ? (
                offerData?.offers?.map((item, index) => (
                  <Grid item xs={12} md={4} key={index}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                      <Stack>
                        <Stack padding={'24px 24px 16px'} gap={'16px'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <Avatar src={item.image} />
                          </Box>
                          <Stack gap={2}>
                            <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                              {item.description}
                            </Typography>

                            {item?.discount_percentage && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="ic:baseline-discount" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Discount
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.discount_percentage}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.start_date && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="solar:calendar-bold" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Start Date
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {formatDate(item.start_date)}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.end_date && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="solar:calendar-bold" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    End Date
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {formatDate(item.end_date)}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant="h6" paragraph>
                    There's no Offers
                  </Typography>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Container>
    </>
  );
}

export default CommunitiesPage;

const PartnerCarouselItem = ({ partner }) => (
  <CardContent>
    <Stack flexDirection={'row'} justifyContent={'center'} alignItems={'center'}>
      <Avatar alt={partner.name} src={partner.image} sx={{ width: '120px', height: '120px' }} />
    </Stack>

    <Box
      sx={{
        rowGap: '12px',
        display: 'flex',
        flexDirection: 'column',
        padding: '24px',
        fontSize: '0.75rem',
      }}
    >
      <Typography>{partner.name}</Typography>
      <Typography variant="subtitle2">{partner.description}</Typography>
      <Chip label={partner?.category.name} />

      {partner.contact_name && (
        <Stack flexDirection={'row'}>
          <Iconify icon="wpf:name" sx={{ mr: 2, width: '20px' }} />
          <Stack>
            <Typography variant="subtitle2" fontSize={'0.875rem'}>
              Contact Name
            </Typography>

            <Typography fontWeight={'500'} fontSize={'0.875rem'}>
              {partner.contact_name}
            </Typography>
          </Stack>
        </Stack>
      )}

      {partner.contact_email && (
        <Stack flexDirection={'row'}>
          <Iconify icon="fluent:mail-24-filled" sx={{ mr: 2, width: '20px' }} />
          <Stack>
            <Typography variant="subtitle2" fontSize={'0.875rem'}>
              Contact Email
            </Typography>

            <Typography fontWeight={'500'} fontSize={'0.875rem'}>
              {partner.contact_email}
            </Typography>
          </Stack>
        </Stack>
      )}

      {partner.website && (
        <Stack flexDirection={'row'}>
          <Iconify icon="fluent-mdl2:website" sx={{ mr: 2, width: '20px' }} />
          <Stack>
            <Typography variant="subtitle2" fontSize={'0.875rem'}>
              Website
            </Typography>

            <Typography fontWeight={'500'} fontSize={'0.875rem'}>
              {partner.website}
            </Typography>
          </Stack>
        </Stack>
      )}
    </Box>
  </CardContent>
);
