export function processArray(arr) {
  if (arr?.length === 0) {
    return null; // Return null if the array is empty
  }

  let { minDate } = arr[0];
  let { maxDate } = arr[0];
  let dayPassTotal = 0;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].dayPass <= 0) {
      // eslint-disable-next-line no-continue
      continue; // Skip objects with dayPass <= 0
    }

    // Update minDate if necessary
    if (arr[i].minDate < minDate) {
      minDate = arr[i].minDate;
    }
    // Update maxDate if necessary
    if (arr[i].maxDate !== null && (maxDate === null || arr[i].maxDate > maxDate)) {
      maxDate = arr[i].maxDate;
    }
    // Add to dayPassTotal
    dayPassTotal += arr[i].dayPass;
  }

  // Return the result as an object
  return {
    minDate,
    maxDate,
    dayPassTotal,
  };
}
