import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { Box, Collapse, List, ListItemText, Stack, Typography } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
//
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { formatDateNotTime } from '../../utils/displayDateTime';
import { processArray } from '../../utils/processArray';
import { StyledListItem, StyledNavItem, StyledNavItemIcon } from './styles';
import { useGetPackagesQuery } from '../../Slices/spaceApi';
import { CustomAlert } from '../../utils/Alert';
import Iconify from '../iconify/Iconify';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other} mb={2}>
      <List className="yoshi">
        {data.map((item, idx) => (
          <NavItem key={idx} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

function NavItem({ item }) {
  const { spaceId, currentSpaceId, billingOption, currentBillingOption, userType, currentUserType } = useSelector(
    (item) => item.spaceReducer
  );

  const { data, isLoading, isFetching } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = data?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  const [open, setOpen] = useState(false);

  const [expanded, setExpanded] = useState(true);

  const location = useLocation();

  // const [collapseStates, setCollapseStates] = useState(() =>
  //   item
  //     ?.slice(1)
  //     ?.map((item, index) => (item?.child ? location?.pathname.split('/')[1] === item?.matcher?.toLowerCase() : false))
  // );

  const [collapseStates, setCollapseStates] = useState(() =>
    item?.map((item, index) => {
      if (index === 0) {
        return ['/bookings', '/visitors', '/bookingEnquiry'].includes(location.pathname);
      }
      if (index === 1) {
        return ['/', '/community-search', '/community-team', '/partners'].includes(location.pathname);
      }
      if (index === 2) {
        return ['/products', '/packages', '/employee'].includes(location.pathname);
      }
      return false;
    })
  );

  // Update collapseStates when the location changes
  useEffect(() => {
    setCollapseStates((prevState) =>
      item?.map((item, index) => {
        if (index === 0) {
          return ['/bookings', '/visitors', '/bookingEnquiry'].includes(location.pathname);
        }
        if (index === 1) {
          return ['/', '/community-search', '/community-team', '/partners'].includes(location.pathname);
        }
        if (index === 2) {
          return ['/products', '/packages', '/employee'].includes(location.pathname);
        }
        return prevState[index];
      })
    );
  }, [location.pathname]);

  const countRanges = [];

  data?.assignedPackages?.forEach((obj) => {
    // eslint-disable-next-line camelcase
    const { status, renew_date, remaining_hours, purchase } = obj;

    // Check if the status is 1
    if (status === 1) {
      if (purchase && purchase?.dayPassCount >= 1) {
        const purchaseData = {
          minDate: dayjs(),
          maxDate: dayjs(renew_date).subtract(1, 'day'),
          dayPass: purchase?.dayPassCount,
        };
        countRanges.push(purchaseData);
      }
    }
  });

  const flexbileRanges = [];

  data?.assignedPackages?.forEach((obj) => {
    // eslint-disable-next-line camelcase
    const { status, renew_date, purchase } = obj;

    // Check if the status is 1
    if (status === 1) {
      if (purchase && purchase?.flexibleHoursCount >= 1) {
        const purchaseData = {
          minDate: dayjs(),
          maxDate: dayjs(renew_date).subtract(1, 'day'),
          dayPass: purchase?.flexibleHoursCount,
        };
        flexbileRanges.push(purchaseData);
      }
    }
  });

  const resultCount = processArray(countRanges);
  const resultFlexbile = processArray(flexbileRanges);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleExpand1Click = (index) => {
    const newCollapseStates = [...collapseStates];
    newCollapseStates[index] = !newCollapseStates[index];
    setCollapseStates(newCollapseStates);
  };

  const totalRemainingHours = data?.assignedPackages
    ?.filter((item) => item.status === 1)
    .reduce((total, item) => {
      const hours = item.remaining_hours.reduce((sum, room) => sum + room.hours, 0);
      return total + hours;
    }, 0);

  const totalRemainingDayPasses = data?.assignedPackages
    .filter((item) => item.purchase && item.status === 1) // Ensure purchase exists and has status 1
    .reduce((total, item) => total + item.purchase.dayPassCount, 0); // Sum up dayPassCount

  const totalRemainingFlexibleHours = data?.assignedPackages
    .filter((item) => item.purchase && item.status === 1) // Ensure purchase exists and has status 1
    .reduce((total, item) => total + item.purchase.flexibleHoursCount, 0); // Sum up dayPassCount

  return (
    <>
      {Array.isArray(item) && (
        <Box>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            {item?.slice(1).map((itemNext, itemIndex) =>
              itemNext.child ? (
                <Box key={itemIndex}>
                  <StyledListItem
                    sx={{
                      cursor: 'pointer',
                      backgroundColor: (theme) => (collapseStates[itemIndex] ? 'rgb(145 158 171 / 10%)' : ''),
                      fontWeight: collapseStates[itemIndex] ? '600' : '',
                      '&:hover': {
                        bgcolor: 'rgb(145 158 171 / 15%)',
                      },
                    }}
                    onClick={() => handleExpand1Click(itemIndex)}
                  >
                    <StyledNavItemIcon>{itemNext.icon && itemNext.icon}</StyledNavItemIcon>
                    <ListItemText disableTypography primary={itemNext.title} />

                    {!checkOldUser && itemNext.title === 'Visitor Enquiry' ? (
                      <Iconify icon="solar:lock-outline" sx={{ width: '16px' }} />
                    ) : collapseStates[itemIndex] ? (
                      <Iconify icon="system-uicons:chevron-down" />
                    ) : (
                      <Iconify icon="system-uicons:chevron-right" />
                    )}
                  </StyledListItem>

                  <Collapse in={collapseStates[itemIndex]} timeout="auto" unmountOnExit>
                    {itemNext?.title === 'Bookings and Reservations' && !isFetching && totalRemainingHours > 0 && (
                      <Stack
                        flexDirection="row"
                        mt={1}
                        justifyContent="space-between"
                        sx={{ padding: '0px 0px 0px 0px' }}
                      >
                        <Box>
                          {+billingOption === 1 || +currentBillingOption === 1
                            ? 'Available Hours :'
                            : 'Available Credits : '}
                        </Box>
                        <Box>{totalRemainingHours?.toFixed(2)}</Box>
                      </Stack>
                    )}

                    {itemNext?.title === 'Bookings and Reservations' && !isFetching && totalRemainingDayPasses > 0 && (
                      <Stack>
                        <Stack
                          flexDirection="row"
                          justifyContent="space-between"
                          sx={{ padding: '0px 0px 0px 0px' }}
                          mt={1}
                        >
                          <Box>Available Day Passes :</Box>
                          <Box>{totalRemainingDayPasses?.toFixed(2)}</Box>
                        </Stack>

                        <Box sx={{ padding: '0px 0px 0px 0px' }}>
                          <Typography variant="subtitle2" fontSize={'12px'}>
                            (Valid till {formatDateNotTime(resultCount?.maxDate)})
                          </Typography>
                        </Box>
                      </Stack>
                    )}

                    {itemNext?.title === 'Bookings and Reservations' &&
                      !isFetching &&
                      totalRemainingFlexibleHours > 0 && (
                        <Stack>
                          <Stack
                            flexDirection="row"
                            justifyContent="space-between"
                            sx={{ padding: '0px 0px 0px 0px' }}
                            mt={1}
                          >
                            <Box>Available Flexible Hours :</Box>
                            <Box>{totalRemainingFlexibleHours?.toFixed(2)}</Box>
                          </Stack>

                          <Box sx={{ padding: '0px 0px 16px 0px' }}>
                            <Typography variant="subtitle2" fontSize={'12px'}>
                              (Valid till {formatDateNotTime(resultFlexbile?.maxDate)})
                            </Typography>
                          </Box>
                        </Stack>
                      )}

                    {itemNext?.child?.map((item, idx) => (
                      <StyledNavItem
                        key={idx}
                        component={RouterLink}
                        to={item.path}
                        sx={{
                          display:
                            ((userType === 'company' || currentUserType === 'company') && item.path === '/employee') ||
                            ((userType !== 'company' || currentUserType !== 'company') && item.path !== '/employee')
                              ? 'flex'
                              : 'none',
                          '&.active': {
                            color: 'action.active',
                            bgcolor: 'action.selected',
                            fontWeight: '600',
                          },
                          '&:hover': {
                            bgcolor: (theme) =>
                              location?.pathname === item.path ? theme.palette.action.activeHover : '',
                          },
                          padding: '4px 11px 4px 18px;',
                          height: '36px',
                        }}
                      >
                        <Box marginRight={'16px'}>
                          <Typography
                            sx={{
                              width: '4px',
                              height: '4px',
                              borderRadius: '50%',
                              backgroundColor: (theme) => theme.palette.action.active,
                              transition: 'transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                              transform: 'scale(2)',
                            }}
                          />
                        </Box>

                        <ListItemText disableTypography primary={item.title} />
                        {item.info && item.info}

                        {!checkOldUser &&
                          (item.title === 'Community' ||
                            item.title === 'Visitors' ||
                            item.title === 'Community Team' ||
                            item.title === 'Community Search' ||
                            item.title === 'Partners & Offers') && (
                            <Iconify icon="solar:lock-outline" sx={{ width: '16px' }} />
                          )}
                      </StyledNavItem>
                    ))}
                  </Collapse>
                </Box>
              ) : (
                <StyledNavItem
                  key={itemIndex}
                  component={RouterLink}
                  to={itemNext.path}
                  sx={{
                    display:
                      ((userType === 'company' || currentUserType === 'company') && itemNext.path === '/employee') ||
                      ((userType !== 'company' || currentUserType !== 'company') && itemNext.path !== '/employee')
                        ? 'flex'
                        : 'none',
                    '&.active': {
                      color: 'action.active',
                      bgcolor: 'action.selected',
                      fontWeight: '600',
                    },
                    '&:hover': {
                      bgcolor: (theme) =>
                        location?.pathname === itemNext.path ? theme.palette.action.activeHover : '',
                    },
                    mb: 0.5,
                  }}
                >
                  <StyledNavItemIcon>{itemNext.icon && itemNext.icon}</StyledNavItemIcon>

                  <ListItemText disableTypography primary={itemNext.title} />
                  {!checkOldUser &&
                    (itemNext.title === 'Community' ||
                      itemNext.title === 'Visitors' ||
                      itemNext.title === 'Community Team' ||
                      itemNext.title === 'Community Search' ||
                      itemNext.title === 'Partners & Offers') && (
                      <Iconify icon="solar:lock-outline" sx={{ width: '16px' }} />
                    )}
                </StyledNavItem>
              )
            )}
          </Collapse>
        </Box>
      )}
    </>
  );
}
