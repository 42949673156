import { createSlice } from '@reduxjs/toolkit';
import GetCookie from '../utils/getCookie';

const Initialization = {
  currentCredentials: GetCookie('creds-member'),
  currentUserId: GetCookie('userId'),
  currentSpaceId: GetCookie('spaceId'),
  currentBillingOption: GetCookie('billing_option'),
  currentThemeColor: GetCookie('themeColor'),
  currentCurrency: GetCookie('currency'),
  currentOpenDays: GetCookie('openDays'),
  currentSpaceLogo: GetCookie('spaceLogo'),
  currentName: GetCookie('spaceName'),
  currentBookingConfiguration: GetCookie('booking_configuration'),
  currentOpenDaysTime: GetCookie('openDaysTime'),
  currentUserType: GetCookie('userType'),
  currentGlobalTaxRateObj: GetCookie('taxRateObj'),
  currentCreditPriceInclusiveTax: GetCookie('creditPriceInclusiveTax'),
  currentPublicEmail: GetCookie('email'),
  currentStripe: GetCookie('stripe'),
  currentSlotSize: GetCookie('slotSize'),
  spaceLogo: '',
  bookingConfiguration: '',
  name: '',
  credentials: '',
  userId: '',
  spaceId: '',
  billingOption: '',
  themeColor: '',
  deleteCheck: '',
  currency: '',
  openDays: [],
  openDaysTime: [],
  userType: '',
  globalTaxRateObj: '',
  creditPriceInclusiveTax: '',
  publicEmail: '',
  stripe: '',
  slotSize: '',
};

const spaceReducer = createSlice({
  name: 'space',
  initialState: Initialization,
  reducers: {
    SPACECREDS(state, actions) {
      state.credentials = actions.payload;
    },
    SPACEUSERID(state, actions) {
      state.userId = actions.payload;
    },
    SPACEID(state, actions) {
      state.spaceId = actions.payload;
    },
    SPACEBILLINGOPTIONS(state, actions) {
      state.billingOption = actions.payload;
    },
    THEMECOLOR(state, actions) {
      state.themeColor = actions.payload;
    },
    DELETECHECK(state, actions) {
      state.deleteCheck = actions.payload;
    },
    CURRENCY(state, actions) {
      state.currency = actions.payload;
    },
    OPENDAYS(state, actions) {
      state.openDays = actions.payload;
    },
    SPACELOGO(state, actions) {
      state.spaceLogo = actions.payload;
    },
    SPACENAME(state, actions) {
      state.name = actions.payload;
    },
    BOOKINGCONFIGURATION(state, actions) {
      state.bookingConfiguration = actions.payload;
    },
    OPENDAYSTIME(state, actions) {
      state.openDaysTime = actions.payload;
    },
    USERTYPE(state, actions) {
      state.userType = actions.payload;
    },
    TAXRATEOBJ(state, actions) {
      state.globalTaxRateObj = actions.payload;
    },
    CREDITPRICEINCLUSIVETAX(state, actions) {
      state.creditPriceInclusiveTax = actions.payload;
    },
    EMAIL(state, actions) {
      state.publicEmail = actions.payload;
    },
    STRIPE(state, actions) {
      state.stripe = actions.payload;
    },
    SlOTSIZE(state, actions) {
      state.slotSize = actions.payload;
    },
  },
});

export const {
  SPACECREDS,
  SPACELOGO,
  SPACEUSERID,
  SPACEID,
  BOOKINGCONFIGURATION,
  OPENDAYS,
  SPACEBILLINGOPTIONS,
  THEMECOLOR,
  DELETECHECK,
  CURRENCY,
  SPACENAME,
  OPENDAYSTIME,
  USERTYPE,
  TAXRATEOBJ,
  CREDITPRICEINCLUSIVETAX,
  EMAIL,
  STRIPE,
  SlOTSIZE,
} = spaceReducer.actions;
export default spaceReducer.reducer;
