import styled from '@emotion/styled';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { formatDate } from '../utils/displayDateTime';
import { ConfirmComponent, CustomAlert, DeleteComponent } from '../utils/Alert';

import {
  useDeleteVisitMutation,
  useDeleteVisitorMutation,
  useGetPackagesQuery,
  useGetVisitorsQuery,
  useGetVisitsQuery,
} from '../Slices/spaceApi';
import { timestampToDateTime } from '../utils/timeStamptoDateTime';
import Loader from '../components/Loader/Loader';
import { parseDateTime } from '../utils/parseDateTime';
import EditVisit from '../components/Visitors/EditVisit';
import EditVisitor from '../components/Visitors/EditVisitor';

import Iconify from '../components/iconify/Iconify';

function BookingPage() {
  const { spaceId, currentSpaceId, name, currentName } = useSelector((item) => item.spaceReducer);

  const StyledTabs = styled((props) => (
    <Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
  ))({
    '& .MuiTabs-indicatorSpan': {},
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    minWidth: 'auto',
    '&.Mui-focusVisible': {},
  }));

  const [dailog, setDailog] = useState(false);
  const [typeVisit, setTypeVisit] = useState({ title: 'Upcoming Visits', value: 1 });
  const [messageAdd, setMessageAdd] = useState('');

  const {
    data: visits,
    isLoading: visitsLoading,
    refetch,
  } = useGetVisitsQuery({
    spaceId: spaceId || currentSpaceId,
    type: typeVisit?.value,
  });

  const { data, isLoading } = useGetVisitorsQuery({ spaceId: spaceId || currentSpaceId });

  const { data: packages, isLoading: pacakgeLoading } = useGetPackagesQuery({ spaceId: spaceId || currentSpaceId });

  const checkOldUser = packages?.assignedPackages?.filter((item) => item.status === 1 || item.status === 3).length;

  const navigate = useNavigate();

  useEffect(() => {
    if (!checkOldUser && !pacakgeLoading) {
      setDailog(true);
      navigate('/user/account');
    } else {
      setDailog(false);
    }
  }, [checkOldUser, pacakgeLoading]);

  const [deleteVisitor, deleteStats] = useDeleteVisitorMutation();
  const [deleteVisit, deleteStats1] = useDeleteVisitMutation();

  const [popoverOpen, setPopoverOpen] = useState(null); // Initialize with null
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  const handlePopoverOpen = (event, idx) => {
    setPopoverAnchorEl(event.currentTarget);
    setPopoverOpen(idx);
  };

  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
    setPopoverOpen(null);
  };

  const [value, setValue] = useState(0);
  const [selectVisit, setSelectVisit] = useState();
  const [selectVisitIndex, setSelectedVisitIndex] = useState();

  const [selectVisitor, setSelectVisitor] = useState();
  const [selectVisitorIndex, setSelectedVisitorIndex] = useState();

  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [editStates, setEditStates] = useState(visits?.visits?.map(() => false));

  const [openFilter, setOpenFilter] = useState(false);

  const [editStatesVisitor, setEditStatesVisitor] = useState(data?.visitors?.map(() => false));

  const [openFilterVisitor, setOpenFilterVisitor] = useState(false);

  const [openCheck, setOpenCheck] = useState(false);

  const [selectDelete, setSelectDelete] = useState(false);

  const [openCheckVisit, setOpenCheckVisit] = useState(false);

  const [selectDeleteVisit, setSelectDeleteVisit] = useState(false);

  const handleOpenFilter = (item, idx) => {
    setSelectedVisitIndex(idx);
    setSelectVisit(item);
    handlePopoverClose();
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleOpenFilterVisitor = (item, idx) => {
    setSelectedVisitorIndex(idx);
    setSelectVisitor(item);
    handlePopoverClose();
    setOpenFilterVisitor(true);
  };

  const handleCloseFilterVisitor = () => {
    setOpenFilterVisitor(false);
  };

  const handleDeleteVisitor = (item) => {
    setSelectDelete(item);
    setOpenCheck(true);
    handlePopoverClose();
  };

  const handleDeleteVisit = (item) => {
    setSelectDeleteVisit(item);
    setOpenCheckVisit(true);
    handlePopoverClose();
  };

  useEffect(() => {
    if (deleteStats?.isSuccess) {
      handlePopoverClose();
      setType(true);
      setOpen(true);
      setMessageAdd('Visitor deleted successfully.');
    } else if (deleteStats?.isError) {
      const errorMessage = Object.values(deleteStats?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }
  }, [deleteStats]);

  useEffect(() => {
    if (deleteStats1?.isSuccess) {
      handlePopoverClose();
      setType(true);
      setOpen(true);
      setMessageAdd('Visit deleted successfully.');
    } else if (deleteStats1?.isError) {
      const errorMessage = Object.values(deleteStats1?.error?.data).find((error) => error.length > 0);
      setMessageAdd(errorMessage);
      setOpen(true);
      setType(false);
    }
  }, [deleteStats1]);

  const handleCloseCheck = () => {
    setOpenCheck(false);
  };

  const handleCloseCheckVisit = () => {
    setOpenCheckVisit(false);
  };

  const types = [
    { title: 'Upcoming Visits', value: 1 },
    { title: 'Previous Visits', value: 2 },
  ];

  const defaultProps1 = {
    options: types,
    getOptionLabel: (option) => option.title,
  };

  const handleType = (event, newValue) => {
    if (newValue) {
      setTypeVisit(newValue);
    } else {
      setTypeVisit({ title: 'Upcoming Visits', value: 1 });
    }
  };

  useEffect(() => {
    refetch();
  }, [typeVisit]);

  if ((value === 0 ? isLoading : visitsLoading) || pacakgeLoading) {
    return <Loader />;
  }
  return (
    <>
      <Helmet>
        <title> Visitors | {name || currentName} </title>
      </Helmet>

      {/* <ConfirmComponent openCheck={dailog} /> */}

      <Container>
        <Stack flexDirection={'row'} justifyContent={'space-between'}>
          <Typography variant="h4" sx={{ mb: 5 }}>
            {value === 1 ? 'Visitors' : 'Visits'}
          </Typography>

          {value === 1 ? (
            <RouterLink to="/visitor/register" style={{ textDecoration: 'none' }}>
              <Button sx={{ float: 'right' }} variant="contained">
                Create Visitor
              </Button>
            </RouterLink>
          ) : (
            <RouterLink to="/visitor/visit-add" style={{ textDecoration: 'none' }}>
              <Button sx={{ float: 'right' }} variant="contained">
                Create Visit
              </Button>
            </RouterLink>
          )}
        </Stack>

        <StyledTabs
          value={value}
          onChange={handleChange}
          sx={{
            mb: 5,
            '& .MuiTabs-indicator': {
              backgroundColor: (theme) => theme.palette.text.primary,
            },
            '&.Mui-focusVisible': {
              backgroundColor: '',
              outline: 'none',
              boxShadow: 'none',
            },
          }}
        >
          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary, // Active tab text color
                backgroundColor: 'transparent',

                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="clarity:id-badge-solid" sx={{ marginRight: '10px' }} />
                Visits
              </Typography>
            }
          />
          <StyledTab
            sx={{
              p: 0,
              mr: 3,
              '&.Mui-selected': {
                color: (theme) => theme.palette.text.primary,
                fontWeight: 600,
              },
              fontWeight: 500,
            }}
            label={
              <Typography fontSize="0.875rem" p={0} display={'flex'} alignItems={'center'}>
                <Iconify icon="material-symbols:badge-outline" sx={{ marginRight: '10px' }} />
                Visitors
              </Typography>
            }
          />
        </StyledTabs>

        {value === 1 && (
          <>
            <Grid container spacing={2}>
              {data?.visitors?.length > 0 ? (
                data?.visitors?.map((item, idx) => (
                  <Grid item xs={12} md={4} key={idx}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                      <Stack height={'100%'}>
                        <Stack padding={'24px 24px 16px'} gap={'16px'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <IconButton
                              color="secondary"
                              aria-label="more"
                              aria-controls={`popover-${idx}`}
                              aria-haspopup="true"
                              onClick={(e) => handlePopoverOpen(e, idx)}
                              sx={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </Box>

                          <Stack gap={2}>
                            {item?.name && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="wpf:name" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Name
                                  </Typography>
                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.name}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.email && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="fluent:mail-24-filled" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Email
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.email}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.phone_number && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="ic:baseline-phone" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Phone Number
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.phone_number}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.companyName && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="mdi:company" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Company
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.companyName}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}
                          </Stack>
                        </Stack>

                        <Box
                          sx={{
                            rowGap: '12px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end',
                            padding: '0px 24px 24px',
                            fontSize: '0.75rem',
                            height: '100%',
                            color: (theme) => theme.palette.text.disabled,
                          }}
                        >
                          <Box
                            display={'flex'}
                            sx={{ flexDirection: { md: 'column' }, alignItems: { xs: 'center', md: 'flex-start' } }}
                          >
                            <Button variant="contained" onClick={() => navigate(`/visitor/visit-add?id=${item.id}`)}>
                              Create Visit
                            </Button>
                          </Box>
                        </Box>
                      </Stack>
                    </Card>
                    <Popover
                      id={`popover-${idx}`}
                      open={popoverOpen === idx}
                      anchorEl={popoverAnchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      PaperProps={{
                        sx: {
                          p: 1,
                          width: 140,
                          '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                          },
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleOpenFilterVisitor(item, idx)}>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        Edit
                      </MenuItem>

                      <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteVisitor(item)}>
                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                        Delete
                      </MenuItem>
                    </Popover>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant="h6" paragraph>
                    There's no Visitors
                  </Typography>
                </Grid>
              )}

              {selectVisitor && (
                <EditVisitor
                  editStates={editStatesVisitor}
                  idx={selectVisitorIndex}
                  {...selectVisitor}
                  onCloseFilter={handleCloseFilterVisitor}
                  setEditStates={setEditStatesVisitor}
                  openFilter={openFilterVisitor}
                />
              )}
            </Grid>
          </>
        )}

        {value === 0 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  value={typeVisit}
                  onChange={handleType}
                  fullWidth
                  {...defaultProps1}
                  name="selectType"
                  id="selectType"
                  renderInput={(params) => (
                    <TextField
                      autoFocus
                      sx={{ fontWeight: 'fontWeightBold' }}
                      {...params}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                />
              </Grid>

              {visits?.visits?.length > 0 ? (
                visits?.visits?.map((item, idx) => (
                  <Grid item xs={12} md={4} key={idx}>
                    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                      <Stack>
                        <Stack padding={'24px 24px 16px'} gap={'16px'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <IconButton
                              color="secondary"
                              aria-label="more"
                              aria-controls={`popover-${idx}`}
                              aria-haspopup="true"
                              onClick={(e) => handlePopoverOpen(e, idx)}
                              sx={{
                                position: 'absolute',
                                top: '8px',
                                right: '8px',
                              }}
                            >
                              <Iconify icon={'eva:more-vertical-fill'} />
                            </IconButton>
                          </Box>
                          <Stack gap={2}>
                            {item?.visitor.name && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="wpf:name" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Name
                                  </Typography>
                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.visitor.name}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.visitor.email && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="fluent:mail-24-filled" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Email
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.visitor.email}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.visitor.phone_number && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="ic:baseline-phone" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Phone Number
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.visitor.phone_number}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.expected_visit_datetime && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="solar:calendar-bold" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Expected visit datetime
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {formatDate(item.expected_visit_datetime)}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.location && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="fluent:location-12-filled" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Location
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.location}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.person_visiting && (
                              <Stack flexDirection={'row'}>
                                <Iconify icon="bxs:user" sx={{ mr: 2, width: '20px' }} />
                                <Stack>
                                  <Typography variant="subtitle2" fontSize={'0.875rem'}>
                                    Person Visiting
                                  </Typography>

                                  <Typography fontWeight={'500'} fontSize={'0.875rem'}>
                                    {item.person_visiting}
                                  </Typography>
                                </Stack>
                              </Stack>
                            )}

                            {item?.purpose_of_visit && (
                              <Box
                                display={'flex'}
                                sx={{ flexDirection: { md: 'column' }, alignItems: { xs: 'center', md: 'flex-start' } }}
                              >
                                {item.purpose_of_visit}
                              </Box>
                            )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Card>
                    <Popover
                      id={`popover-${idx}`}
                      open={popoverOpen === idx}
                      anchorEl={popoverAnchorEl}
                      onClose={handlePopoverClose}
                      anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                      PaperProps={{
                        sx: {
                          p: 1,
                          width: 140,
                          '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                          },
                        },
                      }}
                    >
                      <MenuItem onClick={() => handleOpenFilter(item, idx)}>
                        <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                        Edit
                      </MenuItem>

                      <MenuItem sx={{ color: 'error.main' }} onClick={() => handleDeleteVisit(item)}>
                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                        Delete
                      </MenuItem>
                    </Popover>
                  </Grid>
                ))
              ) : (
                <Grid item xs={12} textAlign={'center'}>
                  <Typography variant="h6" paragraph>
                    {typeVisit?.value === 1 ? "There's no upcoming visits" : "There's not previous visits"}
                  </Typography>
                </Grid>
              )}

              {selectVisit && (
                <EditVisit
                  editStates={editStates}
                  idx={selectVisitIndex}
                  {...selectVisit}
                  data={data}
                  onCloseFilter={handleCloseFilter}
                  setEditStates={setEditStates}
                  openFilter={openFilter}
                />
              )}
            </Grid>
          </>
        )}
      </Container>

      {selectDelete?.id && (
        <DeleteComponent
          handleCloseCheck={handleCloseCheck}
          openCheck={openCheck}
          name={'visitor'}
          deleted={() => deleteVisitor({ spaceId: spaceId || currentSpaceId, visitorId: selectDelete?.id })}
        />
      )}

      {selectDeleteVisit?.id && (
        <DeleteComponent
          handleCloseCheck={handleCloseCheckVisit}
          openCheck={openCheckVisit}
          name={'visit'}
          deleted={() => deleteVisit({ spaceId: spaceId || currentSpaceId, visitId: selectDeleteVisit?.id })}
        />
      )}

      <CustomAlert
        type={type ? 'success' : 'error'}
        message={messageAdd}
        open={open}
        autoHideDuration={5000}
        setOpen={setOpen}
      />
    </>
  );
}

export default BookingPage;
