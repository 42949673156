import { Avatar, Card, CardContent, CardHeader, Chip, Container, Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Iconify from '../components/iconify/Iconify';
import { useCustomProfileQuery } from '../Slices/spaceApi';
import Loader from '../components/Loader/Loader';

const CustomeProfilePage = () => {
  const { id } = useParams();
  const { spaceId, currentSpaceId } = useSelector((item) => item.spaceReducer);

  const { data, isLoading } = useCustomProfileQuery({ spaceId: spaceId || currentSpaceId, profileId: id });

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Profile | {data?.name}</title>
      </Helmet>

      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7.5}>
            <Card>
              <CardHeader
                sx={{ p: 2 }}
                avatar={<Avatar src={data?.profileImg} alt="Avatar" />}
                subheader={data?.name && <Typography variant="h4">{data?.name}</Typography>}
              />

              <Typography>{data?.description}</Typography>

              <CardContent sx={{ pt: 1.5 }}>
                {data?.email && (
                  <Stack>
                    <Typography variant="h6">Email</Typography>
                    <Typography>{data?.email}</Typography>
                  </Stack>
                )}

                {data?.phoneNo && (
                  <Stack mt={2}>
                    <Typography variant="h6">Phone Number</Typography>
                    <Typography>{data?.phoneNo}</Typography>
                  </Stack>
                )}

                {data?.company?.name && (
                  <Stack mt={2}>
                    <Typography variant="h6">Associated company</Typography>
                    <Typography>{data?.company?.name}</Typography>
                  </Stack>
                )}

                {data?.skills?.length > 0 && (
                  <Stack mt={2}>
                    <Typography variant="h6">Skills</Typography>
                    <Stack flexDirection={'row'} gap={1} mt={0.5}>
                      {data?.skills.map((item, idx) => (
                        <Chip label={item} key={idx} />
                      ))}
                    </Stack>
                  </Stack>
                )}

                {data?.interests?.length > 0 && (
                  <Stack mt={2}>
                    <Typography variant="h6">Interests</Typography>
                    <Stack flexDirection={'row'} gap={1} mt={0.5}>
                      {data?.interests.map((item, idx) => (
                        <Chip label={item} key={idx} />
                      ))}
                    </Stack>
                  </Stack>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4.5}>
            <Card>
              <CardContent>
                <Stack gap={3}>
                  {data?.profileUrls?.length > 0 ? (
                    data?.profileUrls.map((item, idx) => (
                      <SocialMediaCard key={idx} socialMedia={item[Object.keys(item)]} platform={Object.keys(item)} />
                    ))
                  ) : (
                    <Stack>No social url provided</Stack>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default CustomeProfilePage;

const SocialMediaCard = ({ socialMedia, platform }) => (
    <>
      <Stack flexDirection={'row'} key={platform}>
        {platform?.join('') === 'Twitter' && <Iconify icon="bxl:twitter" color="#1DA1F2" sx={{ minWidth: '24px' }} />}
        {platform?.join('') === 'LinkedIn' && <Iconify icon="bxl:linkedin" color="#0A66C2" sx={{ minWidth: '24px' }} />}
        {platform?.join('') === 'Facebook' && <Iconify icon="bxl:facebook" color="#1877f2" sx={{ minWidth: '24px' }} />}
        {platform?.join('') === 'Instagram' && (
          <Iconify icon="bxl:instagram" color="#e02d69" sx={{ minWidth: '24px' }} />
        )}

        <Stack sx={{ ml: 2, flexWrap: 'wrap', width: '100%' }}>
          <Typography variant="h6" color="textPrimary">
            {platform}
          </Typography>

          <Typography variant="body2" color="textSecondary" sx={{ wordWrap: 'break-word', width: '100%' }}>
            {socialMedia?.public ? socialMedia?.url : 'No url provided'}
          </Typography>
        </Stack>
      </Stack>
    </>
  );
