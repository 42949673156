import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Card, Grid, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getNextOpenDateTime } from '../../utils/getNextDayOpenDateTime';
import Loader from '../Loader/Loader';
import Iconify from '../iconify/Iconify';

const VisitForm = ({ data, setValue, register, errors, trigger, watch, loading, isEdit, id, control }) => {
  
  const navigate = useNavigate();
  const location = useLocation();
  const { openDays, currentOpenDays, openDaysTime, currentOpenDaysTime } = useSelector((item) => item.spaceReducer);
  const [visitorId, setVisitorId] = useState('');
  const [checkLoading, setCheckLoading] = useState(true);
  const weekdays = openDays?.length > 0 ? openDays : JSON.parse(currentOpenDays);

  const isWeekdayDisabled = (date) => {
    const dayOfWeek = date.day();

    const isDayOff = !weekdays?.some((day) => day.day === (dayOfWeek === 0 ? 7 : dayOfWeek));

    return isDayOff;
  };

  useEffect(() => {
    if (!isEdit) {
      const minDateTime = getNextOpenDateTime(openDaysTime, currentOpenDaysTime);
      setValue('expected_visit_datetime', minDateTime);
    }
  }, [id]);

  useEffect(() => {
    setCheckLoading(true);
    const searchParams = new URLSearchParams(location.search);
    const visitorId = searchParams.get('id');
    setVisitorId(visitorId);
    if (visitorId) {
      setValue(
        'visitor',
        data?.visitors?.find((item) => item.id === parseInt(visitorId, 10))
      );
    }
    setCheckLoading(false);
  }, [location.search]);

  if (checkLoading) {
    return <Loader />;
  }
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {data?.visitors?.length <= 0 ? (
          <Card sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={data?.visitors || []}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value?.id}
                  onChange={(_, newValue) => {
                    setValue('visitor', newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Visitor"
                      fullWidth
                      {...register('visitor', { required: 'Visitor is required' })}
                      error={!!errors.visitor}
                      helperText={errors.visitor ? errors.visitor.message : ''}
                      onBlur={() => trigger('visitor')}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} textAlign={'center'}>
                <Typography variant="h6" paragraph>
                  There is no Existing Vistor
                </Typography>
              </Grid>

              {!watch('visitor') && (
                <Grid item xs={12}>
                  <LoadingButton
                    onClick={() => navigate('/visitor/register')}
                    loading={loading}
                    variant="contained"
                    sx={{ float: 'right' }}
                    style={{ marginTop: '20px' }}
                    type="submit"
                  >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                      Add Visitor
                    </Stack>
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Card>
        ) : !isEdit && !checkLoading ? (
          <Card sx={{ padding: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  options={data?.visitors}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(_, newValue) => {
                    setValue('visitor', newValue);
                  }}
                  defaultValue={data?.visitors?.find((item) => item.id === parseInt(visitorId, 10))}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Visitor"
                      fullWidth
                      {...register('visitor', { required: 'Visitor is required' })}
                      error={!!errors.visitor}
                      helperText={errors.visitor ? errors.visitor.message : ''}
                      onBlur={() => trigger('visitor')}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {watch('visitor') && (
                  <Typography variant="subtitle1" display={'flex'} alignItems={'center'}>
                    Name : &nbsp; <Typography variant="body2">{watch('visitor').name}</Typography>
                  </Typography>
                )}
                {watch('visitor') && (
                  <Typography variant="subtitle1" display={'flex'} alignItems={'center'}>
                    Email : &nbsp; <Typography variant="body2">{watch('visitor').email}</Typography>
                  </Typography>
                )}
              </Grid>
              {!watch('visitor') && (
                <Grid item xs={12}>
                  <LoadingButton
                    loading={loading}
                    variant="contained"
                    sx={{ float: 'right' }}
                    style={{ marginTop: '20px' }}
                    type="submit"
                  >
                    <Stack flexDirection={'row'} alignItems={'center'}>
                      <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                      Add
                    </Stack>
                  </LoadingButton>
                </Grid>
              )}
            </Grid>
          </Card>
        ) : (
          <Box sx={{ px: 3, py: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Autocomplete
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  options={data?.visitors}
                  value={watch(`visitor_${id}`)}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, newValue) => {
                    setValue(`visitor_${id}`, newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Visitor"
                      fullWidth
                      {...register(`visitor_${id}`, { required: 'Visitor is required' })}
                      error={!!errors[`visitor_${id}`]} // Use dynamic field name for error
                      helperText={errors[`visitor_${id}`] ? errors[`visitor_${id}`].message : ''} // Use dynamic field name for helper text
                      onBlur={() => trigger(`visitor_${id}`)} // Use dynamic field name for onBlur
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                {watch(`visitor_${id}`) && (
                  <Typography variant="subtitle1" display={'flex'} alignItems={'center'}>
                    Name : &nbsp; <Typography variant="body2">{watch(`visitor_${id}`).name}</Typography>
                  </Typography>
                )}
                {watch(`visitor_${id}`) && (
                  <Typography variant="subtitle1" display={'flex'} alignItems={'center'}>
                    Email : &nbsp; <Typography variant="body2">{watch(`visitor_${id}`).email}</Typography>
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        )}

        {watch('visitor') && !isEdit ? (
          <Card sx={{ padding: 3, mt: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <TextField
                  label="Location (optional)"
                  fullWidth
                  {...register('location')}
                  error={!!errors.location}
                  helperText={errors.location ? errors.location.message : ''}
                  onBlur={() => trigger('location')}
                />
              </Grid>

              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name="expected_visit_datetime"
                    control={control}
                    defaultValue={null} // Set your initial/default date here
                    rules={{ required: 'Expected Visit Date and Time is required' }}
                    render={({ field, fieldState }) => (
                      <>
                        <DateTimePicker
                          {...field}
                          shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                          sx={{ width: '100%' }}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              label="Expected Visit Date and Time"
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                          )}
                          disablePast
                        />
                      </>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <TextField
                  label="Purpose of Visit (optional)"
                  fullWidth
                  {...register('purpose_of_visit')}
                  error={!!errors.purpose_of_visit}
                  helperText={errors.purpose_of_visit ? errors.purpose_of_visit.message : ''}
                  onBlur={() => trigger('purpose_of_visit')}
                />
              </Grid>
              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <TextField
                  label="Invited By (optional)"
                  fullWidth
                  {...register('person_visiting')}
                  error={!!errors.person_visiting}
                  helperText={errors.person_visiting ? errors.person_visiting.message : ''}
                  onBlur={() => trigger('person_visiting')}
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton
                  loading={loading}
                  variant="contained"
                  sx={{ float: 'right' }}
                  style={{ marginTop: '20px' }}
                  type="submit"
                >
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} />
                    Add
                  </Stack>
                </LoadingButton>
              </Grid>
            </Grid>
          </Card>
        ) : watch(`visitor_${id}`) && isEdit ? (
          <Box sx={{ px: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Controller
                    name={`expected_visit_datetime_${id}`}
                    control={control}
                    defaultValue={null} // Set your initial/default date here
                    rules={{ required: 'Expected Visit Date and Time is required' }}
                    render={({ field, fieldState }) => (
                      <>
                        <DateTimePicker
                          {...field}
                          shouldDisableDate={(date) => isWeekdayDisabled(dayjs(date))}
                          sx={{ width: '100%' }}
                          renderInput={(props) => (
                            <TextField
                              {...props}
                              label="Expected Visit Date and Time"
                              error={!!fieldState.error}
                              helperText={fieldState.error ? fieldState.error.message : ''}
                            />
                          )}
                          disablePast
                        />
                      </>
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <TextField
                  label="Location"
                  fullWidth
                  {...register(
                    `location_${id}`
                    // { required: 'Location is required' }
                  )}
                  error={!!errors[`location_${id}`]}
                  helperText={errors[`location_${id}`] ? errors[`location_${id}`].message : ''}
                  onBlur={() => trigger(`location_${id}`)}
                />
              </Grid>

              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <TextField
                  label="Purpose of Visit"
                  fullWidth
                  {...register(`purpose_of_visit_${id}`)}
                  error={!!errors[`purpose_of_visit_${id}`]}
                  helperText={errors[`purpose_of_visit_${id}`] ? errors[`purpose_of_visit_${id}`].message : ''}
                  onBlur={() => trigger(`purpose_of_visit_${id}`)}
                />
              </Grid>
              <Grid item xs={12} md={isEdit ? 12 : 6}>
                <TextField
                  label="Invited By"
                  fullWidth
                  {...register(`person_visiting_${id}`)}
                  error={!!errors[`person_visiting_${id}`]}
                  helperText={errors[`person_visiting_${id}`] ? errors[`person_visiting_${id}`].message : ''}
                  onBlur={() => trigger(`person_visiting_${id}`)}
                />
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default VisitForm;
