import { Helmet } from 'react-helmet-async';
import { styled } from '@mui/material/styles';

import { Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import RemoveCookie from '../utils/removeCookie';
import useResponsive from '../hooks/useResponsive';

import { AccessForm } from '../sections/auth/login';

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const { token } = useParams();

  const mdUp = useResponsive('up', 'md');
  const { currentCredentials, spaceLogo, currentSpaceLogo } = useSelector((item) => item.spaceReducer);

  useEffect(() => {
    if (currentCredentials) {
      RemoveCookie('spaceId');
      RemoveCookie('token');
      RemoveCookie('creds-member');
      RemoveCookie('billing_option');
      RemoveCookie('userId');
      RemoveCookie('currency');
      RemoveCookie('themeColor');
      RemoveCookie('openDays');
      RemoveCookie('spaceName');
      RemoveCookie('booking_configuration');
      RemoveCookie('spaceLogo');

      window.location.reload();
    }
  }, []);

  return (
    <>
      <Helmet>
        <title> Access Page </title>
        <link rel="icon" href={spaceLogo || currentSpaceLogo || './assets/placeholder.svg'} />
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent sx={{ textAlign: 'center' }}>
            <Typography variant="h3" gutterBottom sx={{ mb: 5 }}>
              Set your Password
            </Typography>

            <AccessForm token={token} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
