import Iconify from '../../../components/iconify';

const navConfig = [
  [
    'Bookings and Reservations',
    {
      title: 'Bookings and Reservations',
      path: '/',
      icon: <Iconify icon="tabler:brand-booking" />,
      child: [
        { title: 'Create Booking', path: '/bookingEnquiry', icon: <Iconify icon="tabler:brand-booking" /> },
        { title: 'Reservations', path: '/bookings', icon: <Iconify icon="tabler:brand-booking" /> },
        { title: 'Visitors', path: '/visitors', icon: <Iconify icon="la:users" /> },
      ],
    },
    {
      title: 'Community Hub',
      path: '/',
      icon: <Iconify icon="fluent:people-chat-16-regular" />,
      child: [
        { title: 'Community', path: '/', icon: <Iconify icon="ri:team-line" /> },
        { title: 'Community Team', path: '/community-team', icon: <Iconify icon="ri:team-line" /> },
        { title: 'Community Search', path: '/community-search', icon: <Iconify icon="ic:sharp-person-search" /> },
        { title: 'Partners & Offers', path: '/partners', icon: <Iconify icon="mdi:partnership-outline" /> },
      ],
    },

    {
      title: 'Management Hub',
      path: '/',
      icon: <Iconify icon="material-symbols-light:finance" />,
      child: [
        { title: 'Employees', path: '/employee', icon: <Iconify icon="raphael:employee" /> },
        { title: 'Products', path: '/products', icon: <Iconify icon="carbon:product" /> },
        { title: 'Packages', path: '/packages', icon: <Iconify icon="lucide:package" /> },
      ],
    },
    // { title: 'Purchases', path: '/purchases', icon: <Iconify icon="f7:purchased-circle" /> },
  ],

  // [
  //   'Community Engagement',
  //   {
  //     title: 'Community Hub',
  //     path: '/',
  //     icon: <Iconify icon="fluent:people-chat-16-regular" />,
  //     child: [
  //       { title: 'Community', path: '/', icon: <Iconify icon="ri:team-line" /> },
  //       { title: 'Community Team', path: '/community-team', icon: <Iconify icon="ri:team-line" /> },
  //       { title: 'Community Search', path: '/community-search', icon: <Iconify icon="ic:sharp-person-search" /> },
  //       { title: 'Partners & Offers', path: '/partners', icon: <Iconify icon="mdi:partnership-outline" /> },
  //     ],
  //   },
  // ],

  // [
  //   'Management Tools',
  //   {
  //     title: 'Management Hub',
  //     path: '/',
  //     icon: <Iconify icon="material-symbols-light:finance" />,
  //     child: [
  //       { title: 'Employees', path: '/employee', icon: <Iconify icon="raphael:employee" /> },
  //       { title: 'Products', path: '/products', icon: <Iconify icon="carbon:product" /> },
  //       { title: 'Packages', path: '/packages', icon: <Iconify icon="lucide:package" /> },
  //     ],
  //   },
  // { title: 'Purchases', path: '/purchases', icon: <Iconify icon="f7:purchased-circle" /> },

  // ],

  // [
  //   'Management',
  //   {
  //     title: 'Profile',
  //     path: '/user/profile',
  //     icon: <Iconify icon="clarity:user-line" />,
  //   },
  // ],

  // [
  //   'Support',
  //   {
  //     title: 'Tickets',
  //     path: '/support/tickets',
  //     icon: <Iconify icon="arcticons:mtickets" />,
  //   },
  // ],
];

export default navConfig;
