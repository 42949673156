import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Checkbox,
  Chip,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import Iconify from '../iconify';

export const MeetingRoomAccordion = ({ meetingRoomsData, meetingRooms }) =>
  meetingRoomsData?.map((room, index) => {
    const matchingRoom = meetingRooms?.find((data) => data.id === room);

    if (matchingRoom) {
      return (
        <div key={index}>
          <Typography variant="subtitle2">{matchingRoom.title}</Typography>
        </div>
      );
    }

    return null; // Handle the case where a meeting room with the specified ID is not found
  });

export const ProductCard = ({
  item,
  handleEnquiry,
  duration,
  checkoutProduct,
  currency,
  currentCurrency,
  meetingRooms,
  purchase,
  hotDesk,
  fromPublic,
}) => {
  console.log(item, 'item');
  const [expanded, setExpanded] = useState(false);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Grid item md={4} xs={12}>
      {!hotDesk || !fromPublic ? (
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardMedia
            component="img"
            alt=""
            height="140"
            image={item.image || '/assets/placeholder.svg'}
            onError={(e) => {
              e.target.src = '/assets/placeholder.svg';
            }}
          />

          <CardContent style={{ flexGrow: 1 }}>
            <Box>
              <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography variant="h6" component={'div'}>
                  {item?.name}
                </Typography>

                {item?.manageStock && (
                  <Chip
                    label={item.quantity > 0 ? `Quantity (${item.quantity})` : 'Sold out'}
                    color={item.quantity > 0 ? 'success' : 'error'}
                  />
                )}
              </Stack>

              <Typography variant="subtitle2" mb={2} fontSize={'13px'} component={'div'}>
                {item?.description}
              </Typography>

              {item?.benefits?.meetingRoomAllowedHours?.length > 0 && (
                <Accordion
                  expanded={expanded === 'panel1'}
                  sx={{
                    minHeight: 'auto',
                    '.MuiAccordionSummary-root.Mui-expanded': {
                      minHeight: 'auto',
                    },
                  }}
                  onChange={handleAccordionChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<Iconify icon="material-symbols:expand-more" />}
                    sx={{
                      m: 0,
                      p: 0, // Setting both margin and padding to 0
                      '.MuiAccordionSummary-content.Mui-expanded': {
                        m: 0,
                        p: 0, // Setting both margin and padding to 0 for the expanded state
                      },
                    }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="subtitle1">Additional Benefits</Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ p: 0, mb: 2, display: 'flex', flexDirection: 'column' }}>
                    {item?.benefits?.meetingRoomAllowedHours.map((meetingRoomHours, index) => (
                      <Stack key={index} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box my={1}>
                          <MeetingRoomAccordion
                            meetingRoomsData={meetingRoomHours.meetingRoom}
                            meetingRooms={meetingRooms}
                          />
                        </Box>
                        <Typography variant="subtitle2" display={'flex'} flexDirection={'row'} alignItems={'center'}>
                          <Typography fontWeight={'bold'} variant="subtitle2">
                            {meetingRoomHours.hours}
                          </Typography>
                          &nbsp;{'hours'}
                        </Typography>
                      </Stack>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}

              {item?.benefits?.dayPass?.quantity > 0 && (
                <Chip
                  sx={{ width: '100%', my: 2 }}
                  color="info"
                  label={`Day Pass : ${
                    item?.benefits?.dayPass?.quantity === 1
                      ? `${item?.benefits?.dayPass?.quantity} day`
                      : `${item?.benefits?.dayPass?.quantity} days`
                  } `}
                />
              )}

              {item?.benefits?.flexibleHours?.hours > 0 && (
                <Chip
                  sx={{ width: '100%', mb: 2 }}
                  color="info"
                  label={`Flexbile Hours : ${
                    item?.benefits?.flexibleHours?.hours === 1
                      ? `${item?.benefits?.flexibleHours?.hours}`
                      : `${item?.benefits?.flexibleHours?.hours}`
                  } `}
                />
              )}

              {purchase && item?.duration && (
                <Stack>
                  <Chip
                    label={`Valid for ${
                      duration?.spaceDurations?.find((duration, idx) => duration.id === item?.duration)?.day === 1
                        ? `${
                            duration?.spaceDurations?.find((duration, idx) => duration.id === item?.duration)?.day
                          } day`
                        : `${
                            duration?.spaceDurations?.find((duration, idx) => duration.id === item?.duration)?.day
                          } days `
                    }
   `}
                  />
                </Stack>
              )}

              {!purchase && (
                <>
                  <Stack flexDirection={'row'} mb={1} columnGap={2} alignItems={'center'}>
                    <Typography variant="subtitle2">
                      {`Price ${parseInt(item?.price, 10).toFixed(2)}  ${currency || currentCurrency}`}
                    </Typography>

                    {item?.duration && (
                      <Chip
                        label={`Valid for ${
                          duration?.spaceDurations
                            ? duration.spaceDurations.find((duration) => duration.id === item?.duration)?.day === 1
                              ? `${duration.spaceDurations.find((duration) => duration.id === item.duration)?.day} day`
                              : `${duration.spaceDurations.find((duration) => duration.id === item.duration)?.day} days`
                            : '0 day'
                        }`}
                      />
                    )}
                  </Stack>

                  <FormControlLabel
                    control={
                      <Checkbox size="small" checked={checkoutProduct.some((product) => product.product === item.id)} />
                    }
                    disabled={!item?.oneOffPurchase || (item?.manageStock && item.quantity === 0)}
                    onChange={() => handleEnquiry(item)}
                    label={
                      <Typography variant="body2">
                        {item?.oneOffPurchase
                          ? hotDesk
                            ? 'Package Enquiry'
                            : 'Product Enquiry'
                          : 'Contact with admin'}
                      </Typography>
                    }
                  />
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      ) : (
        <Card style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardContent style={{ flexGrow: 1 }}>
            <Box>
              <Stack flexDirection={'row'} justifyContent={'space-between'}>
                <Typography variant="subtitle2" fontSize={'14px'} component={'div'}>
                  {/* {duration?.spaceDurations.find((duration) => duration.id === item?.duration)?.spaceDuration} */}
                  {item?.name}
                </Typography>

                {item?.manageStock && (
                  <Chip
                    label={item.quantity > 0 ? `Quantity (${item.quantity})` : 'Sold out'}
                    color={item.quantity > 0 ? 'success' : 'error'}
                  />
                )}
              </Stack>

              <Box sx={{ my: 2 }}>
                <Typography variant="subtitle1" fontSize={'18px'}>
                  {`${parseInt(item?.price, 10).toFixed(2)}  ${currency || currentCurrency}`}
                </Typography>
              </Box>

              {purchase && item?.duration && (
                <Stack>
                  <Chip
                    label={`Valid for ${
                      duration?.spaceDurations?.find((duration, idx) => duration.id === item?.duration)?.day === 1
                        ? `${
                            duration?.spaceDurations?.find((duration, idx) => duration.id === item?.duration)?.day
                          } day`
                        : `${
                            duration?.spaceDurations?.find((duration, idx) => duration.id === item?.duration)?.day
                          } days `
                    }
   `}
                  />
                </Stack>
              )}

              {item?.benefits?.meetingRoomAllowedHours?.length > 0 && (
                <Accordion
                  expanded={expanded === 'panel1'}
                  sx={{
                    minHeight: 'auto',
                    '.MuiAccordionSummary-root.Mui-expanded': {
                      minHeight: 'auto',
                    },
                  }}
                  onChange={handleAccordionChange('panel1')}
                >
                  <AccordionSummary
                    expandIcon={<Iconify icon="material-symbols:expand-more" />}
                    sx={{
                      m: 0,
                      p: 0, // Setting both margin and padding to 0
                      '.MuiAccordionSummary-content.Mui-expanded': {
                        m: 0,
                        p: 0, // Setting both margin and padding to 0 for the expanded state
                      },
                    }}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography variant="subtitle1">Additional Benefits</Typography>
                  </AccordionSummary>

                  <AccordionDetails sx={{ p: 0, mb: 2, display: 'flex', flexDirection: 'column' }}>
                    {item?.benefits?.meetingRoomAllowedHours.map((meetingRoomHours, index) => (
                      <Stack key={index} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                        <Box my={1}>
                          <MeetingRoomAccordion
                            meetingRoomsData={meetingRoomHours.meetingRoom}
                            meetingRooms={meetingRooms}
                          />
                        </Box>
                        <Typography variant="subtitle2" display={'flex'} flexDirection={'row'} alignItems={'center'}>
                          <Typography fontWeight={'bold'} variant="subtitle2">
                            {meetingRoomHours.hours}
                          </Typography>
                          &nbsp;{'hours'}
                        </Typography>
                      </Stack>
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}

              {!purchase && (
                <>
                  <Stack flexDirection={'row'} mb={1} columnGap={2} alignItems={'center'}>
                    {item?.duration && (
                      <Typography variant="body2">
                        {duration?.spaceDurations
                          ? duration.spaceDurations.find((duration) => duration.id === item?.duration)?.day === 1
                            ? `${duration.spaceDurations.find((duration) => duration.id === item.duration)?.day} day`
                            : `${duration.spaceDurations.find((duration) => duration.id === item.duration)?.day} days`
                          : '0 day'}
                      </Typography>
                    )}
                  </Stack>

                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleEnquiry(item)}
                    sx={{ mt: 2 }}
                    disabled={!item?.oneOffPurchase || (item?.manageStock && item.quantity === 0)}
                  >
                    {item?.oneOffPurchase ? (hotDesk ? 'Purchase' : 'Product Enquiry') : 'Contact with admin'}
                  </Button>
                </>
              )}
            </Box>
          </CardContent>
        </Card>
      )}
    </Grid>
  );
};
