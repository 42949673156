export function sortProductsByDuration(products, durations) {
  if (!Array.isArray(products)) {
    return [];
  }

  // Create a copy of the products array to avoid modifying the original array
  const productsCopy = [...products];

  return productsCopy.sort((a, b) => {
    const aDuration = durations.find((d) => d.id === a.duration);
    const bDuration = durations.find((d) => d.id === b.duration);

    const aDay = aDuration ? aDuration.day : Number.MAX_SAFE_INTEGER;
    const bDay = bDuration ? bDuration.day : Number.MAX_SAFE_INTEGER;

    return aDay - bDay;
  });
}
