import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, IconButton, InputAdornment, Typography, Grid, Box } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Iconify from '../../../components/iconify';
import { CustomAlert } from '../../../utils/Alert';

export default function PasswordForm({ token }) {
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    watch,
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('password');
  const [alertType, setAlertType] = useState(null);

  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');

  const handleType = () => {
    setType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };

  const handleLoginPasswordClick = () => {
    // Navigate to the "/forgotPsw" route
    navigate('/login');
  };

  const onSubmit = (data) => {
    setLoading(true);

    try {
      const response = axios.post(`/member/set-password/${token}/`, { password: data?.password });
      setOpen(true);
      setAlertType(true);
      setLoading(false);
      setMessage('Password set succesfully.');

      setTimeout(() => {
        // Handle API response here
        navigate('/login'); // Redirect to success page on successful form submission
      }, 1000);
    } catch (error) {
      setOpen(true);
      setAlertType(false);
      setMessage(error.response.data.message);
      setLoading(false);
    }
    // Simulate API call with setTimeout
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container rowSpacing={2}>
        <Grid item xs={12}>
          <TextField
            label="Password"
            variant="outlined"
            fullWidth
            type={type}
            {...register('password', {
              required: 'Password is required',
              pattern: {
                value: /.{6,}/, // Minimum 6 characters
                message: 'Password must be at least 6 characters',
              },
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
            onBlur={() => trigger('password')}
            InputLabelProps={{
              shrink: !!watch('password'),
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleType} color="secondary">
                    <Iconify icon={type === 'password' ? 'solar:eye-bold' : 'ph:eye-closed'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Confirm Password"
            variant="outlined"
            fullWidth
            type={type}
            {...register('confirmPassword', {
              required: 'Confirm Password is required',
              validate: (value) => value === watch('password') || 'Passwords do not match',
            })}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
            onBlur={() => trigger('confirmPassword')}
            InputLabelProps={{
              shrink: !!watch('confirmPassword'),
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleType} color="secondary">
                    <Iconify icon={type === 'password' ? 'solar:eye-bold' : 'ph:eye-closed'} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      <LoadingButton fullWidth sx={{ my: 2 }} loading={loading} variant="contained" type="submit">
        <Iconify icon="line-md:confirm-circle" sx={{ mr: '5px' }} /> Save
      </LoadingButton>

      <Box>
        <Typography
          sx={{
            cursor: 'pointer',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          onClick={handleLoginPasswordClick}
        >
          Return to sign in?
        </Typography>
      </Box>

      <CustomAlert type={alertType ? 'success' : 'error'} message={message} open={open} setOpen={setOpen} />
    </form>
  );
}
