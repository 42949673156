import { configureStore } from '@reduxjs/toolkit';
import spaceReducer from '../Slices/spaceSlice';
import { spaceApi } from '../Slices/spaceApi';
import { constantApis } from '../Slices/constantApi';

// import { configureStore } from '@reduxjs/toolkit';
const store = configureStore({
  reducer: {
    spaceReducer,
    [spaceApi.reducerPath]: spaceApi.reducer,
    [constantApis.reducerPath]: constantApis.reducer, // Another RTK Query API reducer
  },
  middleware: (middleware) => middleware().concat(spaceApi.middleware, constantApis.middleware),
});

export default store;
